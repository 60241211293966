import { parse } from "content-disposition-header";
import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { DownloadedFile } from "../models/downloaded-file";
import { ListResponse } from "../models/list-response";
import { NotificationAddInitializationModel } from "../models/notification-add-initialization-model";
import { Statement } from "../models/statement"

@injectable()
export class StatementApiClient extends ApiClientBase {
    public name = "StatementApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getMyStatements(page?: number, limit?: number): Promise<ListResponse<Statement>> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit
        };
        return await this._callApi('/statements/myStatements', 'GET', data) as ListResponse<Statement>;
    }

    public async getItems(page?: number, limit?: number): Promise<ListResponse<Statement>> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit
        };
        return await this._callApi('/statements/list', 'GET', data) as ListResponse<Statement>;
    }

    public async getItemPreview(id: number): Promise<Statement> {
        return await this._callApi('/statements/' + id, 'GET') as Statement;
    }

    public async getItem(id: number): Promise<Statement> {
        return await this._callApi('/statements/get/' + id, 'GET') as Statement;
    }

    public async updateItem(data: any): Promise<any> {
        return await this._callApi('/statements/update', 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    public async addItem(data: any): Promise<any> {
        return await this._callApi('/statements/add', 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    //public async sign(id: number): Promise<StatementsSigning> {
    //    return await this._callApi('/statements/' + id + "/sig", 'PUT') as StatementsSigning;
    //}

    public async sign(id: number, val?: string): Promise<Statement> {
        const data: JSON = <JSON><unknown>{
            "id": id,
            "value": val
        };
        return await this._callApi('/statements/sig', 'PUT', JSON.stringify(data)) as Statement;
    }

    public async getLocations(): Promise<NotificationAddInitializationModel> {
        return await this._callApi('/statements/locations', 'GET', null) as NotificationAddInitializationModel;
    }

    public async downloadStatement(id: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/statements/downloadStatement/' + id, 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }
}