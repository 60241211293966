import { injectable } from "inversify";
import * as Mustache from "mustache";
import * as moment from 'moment';
import { StatementApiClient } from "../ts/clients/statements-api-client";
import { Statement } from "../ts/models/statement";
import template from "./izjave-pregled-view.html";
import { TranslationService } from "../ts/translation-service";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";

@injectable()
export class IzjavePregledView {
    private _apiClient: StatementApiClient; 
    private _translationService: TranslationService;
    private _id: number;
    //private _signButton: JQuery<HTMLElement> | null = null;

    public constructor(statementApiClient: StatementApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = statementApiClient;         
        this._translationService = translationService;
        this._id = 0;
    }
    /*
     * Wait for data then render it
     */
    public async load(id: number): Promise<void> {
        this._id = id;
        try {
            var translations: any = await this._translationService.currentTranslations;
            await this._apiClient.getItemPreview(id)
                .then((item) => {
                    this._renderData(item, translations);
                    this._initForm();
                })
                .catch((ex) => {
                    $('#main').text(translations[ex.message]);
                });;
            //this._signButton = $('#main').find("[name=signStatementButton]");
            //this._signButton.on("click", (ev) => this.onSignStatementClick(ev, id));
           

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(data: Statement, translations: any): void {
        // Construct a template
        const htmlTemplate = template;

        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji,
        };

        var lang = document.documentElement.lang;
        var customFields = [];
        if (data.customFields) {            
            for (let i = 0; i < data.customFields.length; i++) {
                if (lang == "en") {
                    var customField = {
                        "id": data.customFields[i].id,
                        "label": data.customFields[i].labelEn,
                        "description": data.customFields[i].descriptionEn,
                        "required": data.customFields[i].requiredField,
                        "type": data.customFields[i].type,
                        "options": [""]
                    };
                    var optionsArray = data.customFields[i].dropDownOptionsEn.split("\n");
                    for (let j = 0; j < optionsArray.length; j++) {
                        customField.options.push(optionsArray[j]);
                    }
                    customFields.push(customField);
                }
                else {
                    var customField = {
                        "id": data.customFields[i].id,
                        "label": data.customFields[i].labelSl,
                        "description": data.customFields[i].description,
                        "required": data.customFields[i].requiredField,
                        "type": data.customFields[i].type,
                        "options": [""]
                    };
                    var optionsArray = data.customFields[i].dropDownOptionsSl.split("\n");
                    for (let j = 0; j < optionsArray.length; j++) {
                        customField.options.push(optionsArray[j]);
                    }
                    customFields.push(customField);
                }
            }            
        }
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "data": data,
            "formators": formators,
            translations: translations,
            customFields: customFields,
            english: document.documentElement.lang == "en"
        } as any;     

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }

    private _initForm(): void {

        var form = $("#statement-form");
        $.validator.unobtrusive.parse(form);

        $("#statement-form").on("submit", async (ev) => {
            ev.preventDefault();
            if (!form.valid()) {
                return;
            }
            this.loader.show();
            var customValues = "";
            $("form#statement-form :input").each(function () {
                if ($(this).prop("id") == "statementTermsCheck" || $(this).prop("id") == "signStatementButton") {
                    return;
                }
                if (!$(this).val()) {
                    return;
                }
                customValues += $(this).val() + "; ";
            });

            if (customValues) {
                customValues = customValues.slice(0, customValues.length - 2);
            }

            var translations: any = await this._translationService.currentTranslations;           
            await this._apiClient.sign(this._id, customValues)
                .then((item) => {
                    this.loader.hide();
                    if (item && item.statementsSignings && item.statementsSignings.length > 0) {
                        this._renderData(item, translations);
                        this.notyf.success(translations["StatementSubmitted"] ?? "Izjava oddana");
                    }
                    else {
                        this.notyf.error(translations["SaveError"] ?? "Napaka pri shranjevanju");
                    }
                })
                .catch((ex) => {
                    $('#main').text(translations[ex.message]);
                });
        });
    }
}