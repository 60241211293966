import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./error-loading-view.html";

@injectable()
export class ErrorLoadingView {
    private _translationService: TranslationService;

    public constructor(translationService: TranslationService) {
        this._translationService = translationService;
    }

    public async load(): Promise<void> {
        try {
            this._renderData();
        } catch (e) {
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        const viewModel = {
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }
}
