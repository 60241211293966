import { injectable } from "inversify";
import * as Mustache from "mustache";
import { OvernightApiClient } from "../ts/clients/overnights-api-client";
import template from "./prenocitve-view.html";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { TranslationService } from "../ts/translation-service";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { ModalConfirm } from "./modal-confirm";
import { Notyf } from "notyf";

@injectable()
export class PrenocitveView {
    private _apiClient: OvernightApiClient;
    private itemsPerPage = 25;
    private _translationService: TranslationService;

    public constructor(overnightApiClient: OvernightApiClient, translationService: TranslationService, private notyf: Notyf) {
        this._apiClient = overnightApiClient; 
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {

            // Try to get data
            //TODO: prebrati podatke
            //create table
            // Render new content     
            var self = this;
            var translations = self._translationService.currentTranslations;
            this._renderData();
            var rootElement = $("#main").find(".prenocitve-table-placeholder");
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: rootElement,
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    var data = await this._apiClient.getList(currentPage, this.itemsPerPage)

                    data.items.forEach(function (item) {

                        if (item && item.sex)
                            item.sex = self._translationService.currentTranslations[item.sex.charAt(0).toUpperCase() + item.sex.slice(1)];
                        });
                    return data;
                },
                onRender: () => {
                    //rootElement.find(".delete-overnight").on('click', this.deleteItem);
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);

            rootElement.on("click", "[data-action=delete]", async (ev) => {
                ev.preventDefault();
                var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
                ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.ConfirmDeleteContent,
                    confirmType: 'brisanje'
                }).then(() => {
                    self._apiClient.delete(id).then((data) => {
                        if (data && data.success) {
                            self.notyf.success(translations.OvernightDeleteSuccess);
                            tableWidget.refresh(tableWidget.currentPage, this._translationService.currentTranslations);
                        }
                        else if (data && data.message) {
                            self.notyf.error(translations[data.message]);
                        }
                        else {
                            self.notyf.error(translations.UnexpectedError);
                        }
                    })
                        .catch(() => {
                            self.notyf.error(translations.UnexpectedError);
                        });
                }).catch(() => {
                    self.notyf.error(translations.UnexpectedError);
                });
            });

            const html = `
            <div style="font-weight:500;font-size:15px;">${this._translationService.currentTranslations["OvernightTableText"]}</div><br>
`;
            $('#main').find(".empty-utility-item").html(html);

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDelete": "../img/icon-delete.svg",
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);        
    }

    private dataTableModel(): TableWidgetTableMap {
        var translations = this._translationService.currentTranslations;
        const tableMap = {
            title: this._translationService.currentTranslations["OvernightsLogged"],
            classInsert: "prenocitve-table-placeholder",
            noDataContent: translations.NoData,
            controls: '<a href="/#/prenocitve/prijava" class="add">' + translations["AddOvernight"] + '</a>',
            columns: [
                {
                    name: translations["Day"],
                    html: "{{#formators}}{{#dateFormat}}{{day}}{{/dateFormat}}{{/formators}}"
                },
                {
                    name: translations["Location"],
                    value: "locationId"
                }
                ,
                {
                    name: translations["Name"],
                    value: "guest"
                },
                {
                    name: translations["Sex1"],
                    value: "sex"
                },
                
                {
                    name: translations["IdNumber"],
                    value: "idNum"
                },                
                {
                    name: translations["DateOfBirth"],
                    html: "{{#formators}}{{#dateFormat}}{{birthdate}}{{/dateFormat}}{{/formators}}"
                },
                {
                    class: "text-center",
                    name: "&nbsp;",
                    html: `<a href='javascript:void(0)'><img src='../img/icon-delete.svg' class= 'img-fluid td-icon' data-action='delete' data-id='{{id}}' /></a>`
                }

            ],
        };
        return tableMap;
    }
}