import { injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./internet-nas-view.html";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { NasApiClient } from "../ts/clients/nas-api-client";
import { TranslationService } from "../ts/translation-service";

@injectable()
export class InternetNasView {
    private _nasApiClient: NasApiClient;
    private _translationService: TranslationService;

    private itemsPerPage = 10;

    public constructor(nasApiClient: NasApiClient, translationService: TranslationService) {

        this._nasApiClient = nasApiClient;
        this._translationService = translationService;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {

            this._renderData();
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: $("#main").find(".internet-nas-table-placeholder"),
                getData: async (currentPage) => {
                    return await this._nasApiClient.getList(currentPage, this.itemsPerPage);
                },
                onRender: () => {
                    $("#main .internet-nas-table-placeholder").tooltip({ selector: '[data-toggle=tooltip]' });
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlSoftwareAndroid": "../img/software-android.svg",
            "imageUrlSoftwareLinux": "../img/software-linux.svg",
            "imageUrlSoftwareOSX": "../img/software-osx.svg",
            "imageUrlSoftwareWindows": "../img/software-windows.svg",
            "imageUrlSoftwareIos": "../img/software-ios.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlEdit": "../img/icon-edit.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlIconInfo": "../img/icon-info.svg",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }
    private dataTableModel(): TableWidgetTableMap {
        var translations = this._translationService.currentTranslations;
        const tableMap = {
            title: "Nas",
            classInsert: "internet-nas-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["Id"],
                    value: "id"
                },
                {
                    name: translations["Name"],
                    value: "nasname"
                },
                {
                    name: translations["ShortName"],
                    value: "shortname"
                },
                {
                    name: translations["Location"],
                    value: "location.name"
                },
                {
                    name: translations["Status"],
                    //value: "isActive",
                    html: "{{#isActive}}<img src='../img/icon-green-dot-big.svg' />{{/isActive}}{{^isActive}}<img src='../img/icon-gray-dot-big.svg' />{{/isActive}}"
                }
            ]
        }
        return tableMap;
    }
}
//html: "{{#formators}}{{#dateFormat}}{{accountStopTime}}{{/dateFormat}}{{/formators}}"