import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { DatePickerWidget } from "../../../skupno/src/ts/widgets/date-picker-widget";
import { OvernightApiClient } from "../ts/clients/overnights-api-client";
import { OvernightSendData } from "../ts/models/overnight-send-data";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./prenocitve-prijava-view.html";

@injectable()
export class PrenocitvePrijavaView {
    private _apiClient: OvernightApiClient;
    private _datePicker: DatePickerWidget;
    private _translationService: TranslationService;

    public constructor(overnightApiClient: OvernightApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = overnightApiClient;
        this._datePicker = new DatePickerWidget;
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            //TODO: prebrati podatke
            var options = await this._apiClient.options();
            var data = await this._apiClient.index();
            // Render new content
            this._renderData(options, data);
            this._datePicker.load();

            require('jquery-validation-unobtrusive');
            $.validator.addMethod("required2Char", function (_value, _element: any) {
                var check = true;
                if (_value.replace(/\s+/g, ' ').trim().split(" ").length < 2) check = false;
                _value.replace(/\s+/g, ' ').trim().split(" ").forEach( function(str: string) {
                    if (str.trim().length < 2) check = false;                        
                })
                return check;               
            });
            $.validator.unobtrusive.adapters.add("required2Char", function (options: any) {
                options.rules["required2Char"] = "#";
                options.messages["required2Char"] = options.message;
            });
            $.validator.addMethod("required3Char", function (_value, _element: any) {
                var check = true;
                if (_value.length < 3) check = false;
                return check;
            });
            $.validator.unobtrusive.adapters.add("required3Char", function (options: any) {
                options.rules["required3Char"] = "#";
                options.messages["required3Char"] = options.message;
            });
            $.validator.addMethod("requiredDate", function (value, _element: any) {
                var check = false;       
                    var adata = value.split('.');
                    var gg = parseInt(adata[0], 10);
                    var mm = parseInt(adata[1], 10);
                    var aaaa = parseInt(adata[2], 10);
                    var xdata = new Date(aaaa, mm, gg);
                    if (xdata < new Date(new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()))
                        check = true;
                    else
                        check = false;
                return check;
            });
            $.validator.unobtrusive.adapters.add("requiredDate", function (options: any) {
                options.rules["requiredDate"] = "#";
                options.messages["requiredDate"] = options.message;
            });
            $.validator.addMethod("requiredDateT", function (value, _element: any) {
                var check = false;
                var adata = value.split('.');
                var gg = parseInt(adata[0], 10);
                var mm = parseInt(adata[1], 10);
                var aaaa = parseInt(adata[2], 10);
                var xdata = new Date(aaaa, mm, gg);
                if (xdata >= new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()))
                    check = true;
                else
                    check = false;
                return check;
            });
            $.validator.unobtrusive.adapters.add("requiredDateT", function (options: any) {
                options.rules["requiredDateT"] = "#";
                options.messages["requiredDateT"] = options.message;
            });
            jQuery.validator.unobtrusive.parse($(".send-form-overnight"));
            var apiClient = this._apiClient;
            var translations = this._translationService.currentTranslations;

            $('.send-form-overnight').on('submit', (e) => {
                e.preventDefault();
                if ($('.send-form-overnight').valid()) {
                    this.sendData(apiClient, translations);
                }

            });

        } catch (e) {
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(options: any, data: any): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDelete": "../img/icon-delete.svg",
            "sexOptions": this.mustacheFormatData(options.sifranti.find((o: { nazivSifranta: string; }) => o.nazivSifranta === "Sex").vrednosti),
            "DocTypes": this.mustacheFormatData(options.sifranti.find((o: { nazivSifranta: string; }) => o.nazivSifranta === "IdTyp").vrednosti),
            "roomates": data.roomates,
            translations: this._translationService.currentTranslations

        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }
    private mustacheFormatData(data: any): any {
        var mustacheFormattedData = { "values": [] } as any;

        for (var prop in data) {
            if (data.hasOwnProperty(prop)) {
                mustacheFormattedData["values"].push({
                    'key': this._translationService.currentTranslations[prop] ?? prop,
                    'value': data[prop]
                });
            }
        }
        return mustacheFormattedData;
    }


    private FormatDate(dateString: string): Date {

        var date = dateString.toString().replace(" ", "").split(".")
        const day: number = Number(date[0]) + 1;
        const month = Number(date[1]) - 1;
        const year = Number(date[2]);

        return new Date(year, month, day);

    }

    private sendData(_apiClient: any, translations: any) {
        var form = document.querySelector("form") ? document.querySelector("form") : undefined;
        if (form == null) form = undefined;
        const formData = new FormData(form);

        var sd = formData.get("datum")?.toString()
        var sdr = formData.get("datumRojstva")?.toString()
        var date = this.FormatDate(sd as any);
        var dateRoj = this.FormatDate(sdr as any);

        var data: OvernightSendData = {
            day: new Date(this.checkIfNull(date)),
            guest: this.checkIfNull(formData.get("polnoIme")),
            sex: this.checkIfNull(formData.get("spol")),
            idTyp: this.checkIfNull(formData.get("vrstaOsebnegaDokumenta")),
            birthdate: new Date(this.checkIfNull(dateRoj)),
            idNum: this.checkIfNull(formData.get("stevilkaDokumenta")),
            email: this.checkIfNull(formData.get("dodatniMail"))
        }

        this.loader.show();
        _apiClient.add(data)
            .then((_result: any) => {
                this.notyf.success(translations.SaveSuccessful);
                this.loader.hide();
                document.location.hash = "#/prenocitve";
            })
            .catch((ex: any) => {
                if (ex.statusCode == 400) {
                    var errorMessage = ex.message;
                    if (errorMessage) {
                        this.notyf.error(translations[errorMessage]);
                    }
                    else {
                        this.notyf.error(translations.UnexpectedError);
                    }
                }
                else {
                    console.log(ex);
                    this.notyf.error(translations.UnexpectedError);
                }
                this.loader.hide();
            });
    }

    private checkIfNull(x: any) {
        if (x === undefined || x === null || x === "") {
            return "";
        } else {
            return x;
        }
    }
}
