import { injectable } from "inversify";
import * as Mustache from "mustache";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { DamagesApiClient } from "../ts/clients/damage-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "../views/okvare-dezurna-knjiga-view.html";

@injectable()
export class OkvareDezurnaKnjigaView {
    private _apiClient: DamagesApiClient;
    private _translationService: TranslationService;
    private itemsPerPage = 25;

    public constructor(damagesApiClient: DamagesApiClient, translationService: TranslationService) {
        this._apiClient = damagesApiClient;
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            //TODO: prebrati podatke
           // var list = await this._apiClient.list("369A6F2A-2CB7-40CD-B0518A0DB1391173", "dezurnaKnjiga", "XML", "NONE", undefined, undefined, this.itemsPerPage);

            //create table

            this._renderData();
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: $("#main").find(".okvare-dezurna-knjiga-table-placeholder"),
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    var items = await this._apiClient.getDezurnaKnjigaList(currentPage, this.itemsPerPage);
                    console.debug("ITEMS", items);
                    return items;
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData() {
        
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly


        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
        
    }

    private dataTableModel(): TableWidgetTableMap {
        var translations = this._translationService.currentTranslations;
        const tableMap = {
            title: translations["DutyBook"],
            classInsert: "okvare-dezurna-knjiga-table-placeholder",
            noDataContent: translations.NoData,
            controls: '<a href="/#/okvare/dezurna-knjiga/dodaj" class="add">' + translations["DezurnaKnjigaDodaj"] + '</a>',
            columns: [
                {
                    name: translations["VandalismDescription"],
                    value: `kratekOpis`
                },
                {
                    name: translations["Location"],
                    value: `location.campus.name`
                },
                {
                    name: translations["House"],
                    value: `location.name`
                },
                {
                    name: translations["Room"],
                    value: "stevilkaSobe"
                },
                {
                    name: translations["Vandal2"],
                    value: "povzrociteljOpis"
                },
                {
                    name: translations["Day"],
                    html: `{{#formators}}{{#dateFormat}}{{datumDogodka}}{{/dateFormat}}{{/formators}}`
                },               
                //{
                //    name: translations["SubLocation"],
                //    html: `{{#formators}}{{#translate}}{{podlokacija}}{{/translate}}{{/formators}}`
                //},
                {
                    name: translations["Status"],
                    value: `status`
                }     
            ]
        };
        return tableMap;
    }
}