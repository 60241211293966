import { injectable, inject } from "inversify";
import { TranslationService } from "../../ts/translation-service";
import * as Mustache from "mustache";
import template from "./reservations.view.html";
import { TempusDominus } from '@eonasdan/tempus-dominus';
import { UserSearchViewModel } from "../../ts/models/sport-subscription-viewmodel";
import { ClassRoomReservationApiClient } from "../../ts/clients/classroom-api-client";
import addReservationForm from "./addReservationForm.html";
import selectRremoveUser from "../widget-select-remove-user.html"
import { classroomReservationsModel } from "../../ts/models/reservations/classroomReservationsModel";
import { Notyf } from "notyf";
import { CurrentUserAccessor } from "../../ts/utilities/current-user-accessor";
import { ModalConfirm } from "../modal-confirm";
import { CurrentUserInfo } from "../../ts/models/current-user-info";

type ReservationKeys = 'conferenceRoom' | `desk${number}`;
enum ClassroomPermissions {
    ClassroomReservationView = 2147483648,
    ClassroomDeskReservation = 4294967296,
    ClassroomConferenceReservation = 8589934592
}

@injectable()
export class ReservationsView {
    private _selectedUsers: Array<UserSearchViewModel>;
    private readonly _apiClient: ClassRoomReservationApiClient;
    private currentlySelectedDesk: string | null = null;
    public currentSelectedDeskTitle: string | null = null;
    public conferenceRoom: HTMLElement | null = document.getElementById('conferenceRoom');
    public reservations: Record<ReservationKeys, boolean> = this.initializeReservations();
    public selectedItemId: string | null = null;
    public estimatedTimeFrom: TempusDominus | undefined;
    public estimatedTimeTo: TempusDominus | undefined;
    public existingReservations: Array<classroomReservationsModel>;
    private user: CurrentUserInfo | null = null;

    constructor(
        @inject(TranslationService) private translationService: TranslationService,
        classRoomaApi: ClassRoomReservationApiClient,
        public notyf: Notyf,
        public currentUserAccessor: CurrentUserAccessor
    ) {
        this._selectedUsers = [];
        this._apiClient = classRoomaApi;
        this.existingReservations = [];
    }

    public async load(): Promise<void> {
        try {
            this.user = await this.currentUserAccessor.getUser();
            await this._renderData();

            this._renderUserSelectField();
            this.initRoom();
        } catch (e) {
            $('#main').text(this.translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async _renderData(): Promise<void> {
        const translations = this.translationService.currentTranslations;
         const hasPermission = this.hasPermission(ClassroomPermissions.ClassroomReservationView) ||  this.hasPermission(ClassroomPermissions.ClassroomConferenceReservation) || this.hasPermission(ClassroomPermissions.ClassroomDeskReservation);
        const viewModel = {
            translations: translations,
            selectedItemId: this.selectedItemId,
            deskTitle: this.currentSelectedDeskTitle,
            reservationDetaisEnabled: hasPermission,
        };
        const html = Mustache.render(template, viewModel);
        $('#main').html(html);
        this.updateVisibility();
        this._renderUserSelectField();
        this.initRoom();
    }

   

    private async _rerenderReservation(id?: number) {
        const translations = this.translationService.currentTranslations;

        const hasPermission =  this.selectedItemId === "conferenceRoom"  ? this.hasPermission( ClassroomPermissions.ClassroomConferenceReservation)  : this.hasPermission(ClassroomPermissions.ClassroomDeskReservation);
        var hasFormRole = false;
        var emptyFormDescripton = '';

        const deskReservePermission = this.hasPermission( ClassroomPermissions.ClassroomDeskReservation);
        const conferenceRoomPermission = this.hasPermission( ClassroomPermissions.ClassroomConferenceReservation);


        if ( (deskReservePermission || conferenceRoomPermission) && hasPermission) {
            hasFormRole = true;
        }


        if(!hasFormRole)
        {

            if(this.selectedItemId === "conferenceRoom" &&  (!deskReservePermission && !conferenceRoomPermission))
            {
                emptyFormDescripton = translations.ViewPermissionConferenceDesciption;
            }
            else if(this.selectedItemId !== "conferenceRoom" && (!deskReservePermission && !conferenceRoomPermission)) {
                emptyFormDescripton = translations.ViewPermissionDeskDesciption;

            } else if(this.selectedItemId !== "conferenceRoom" && !deskReservePermission) {
                emptyFormDescripton = translations.AdministrationPermissionDeskDesciption;

            }else if(this.selectedItemId === "conferenceRoom" && !conferenceRoomPermission) {
                emptyFormDescripton = translations.RecptionPermissionConferenceDesciption;

            }



        }

        emptyFormDescripton = emptyFormDescripton.replace(/-/g, '<br>-');


        let viewModel: any = {
            translations: translations,
            selectedItemId: this.selectedItemId,
            deskTitle: this.currentSelectedDeskTitle,
            showForm: this.selectedItemId != null,
            isReserved: false,
            emptyFormDescripton: emptyFormDescripton,
            hasFormRole: hasFormRole,
            isConferenceRoom: this.selectedItemId === 'conferenceRoom',
            isLoading: true,
            haseClassroomPermission: deskReservePermission,
            hasConferancePermission: conferenceRoomPermission
    
        };
    
        // Render initial loading state
        let html = Mustache.render(addReservationForm, viewModel);
        $('#reservation-info').html(html);
    
        if (id) {
            try {
                const data = await this._apiClient.getById(id);
                this._selectedUsers = data.classRoomUsers;
                data.estimatedTimeFrom = data.estimatedTimeFrom ?? '/';
                data.estimatedTimeTo = data.estimatedTimeTo ?? '/';
                viewModel = {
                    ...viewModel,
                    isReserved: true,
                    data: data,
                    users: data.classRoomUsers,
                    isLoading: false
                };
            } catch (error) {
                console.error("Error fetching reservation data:", error);
                viewModel.isLoading = false;
            }
        } else {
            this._selectedUsers = [];
            viewModel.isLoading = false;
        }
    
        html = Mustache.render(addReservationForm, viewModel);
        $('#reservation-info').html(html);


    
        this._renderUserWidget(this._selectedUsers, viewModel.isReserved );
        this._renderUserSelectField();
        this.initializeTimePickers();
    
        if (id) {
            this._intDeleteReservation();
        }
        this._setupEventListeners();
    }

     private _renderUserWidget(users: Array<UserSearchViewModel>, isReserved: boolean) {
        var translations = this.translationService.currentTranslations;

    

        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "users": users,
            translations: translations,
            "title": translations.AddUser,
            isReserved: isReserved,
        } as any;
        // Construct a template
        const htmlTemplate = selectRremoveUser;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#users-select-widget').html(html);

        $(".delete-user-btn").each((_, element) => {
            element.addEventListener("click", (ev: Event) => this._onDeleteUser(ev));
        });
    }
    
    private _setupEventListeners() {
        $("#cancelRerervation").off('click').on('click', () => {
            this.clearSelection();
            this._rerenderReservation();
        });
    
        $("#reservationForm").off('submit').on('submit', async (e) => {
            e.preventDefault();
            await this._saveReservation();
        });
    }
  
    public async _initUserSearchField(): Promise<void> {
        require('select2');
        const ajaxOptions = await this._apiClient.userSearchOptions((settings) => {
            if (settings.url) {
                settings.url += "&search={query}";
            }
        });
        $(".user-search-field").select2({
            ajax: {
                url: ajaxOptions.url,
                method: ajaxOptions.method,
                headers: ajaxOptions.headers,
                data: (params: any) => {
                    return { search: params.term, page: params.page || 1 };
                },
                processResults: (data: any) => {
                    return {
                        results: data.map((item: any) => ({
                            id: item.id,
                            text: `${item.firstName} ${item.lastName} / ${item.crmId} `,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            crmId: item.crmId,
                        }))
                    };
                },
                cache: true
            },
            placeholder: "Janez Marinko / 999995665",
            minimumInputLength: 1,
            allowClear: false
        });
    }

    private initializeReservations(): Record<ReservationKeys, boolean> {
        const reservations: Partial<Record<ReservationKeys, boolean>> = { conferenceRoom: false };
        for (let i = 1; i <= 24; i++) {
            reservations[`desk${i}` as ReservationKeys] = false;
        }
        return reservations as Record<ReservationKeys, boolean>;
    }

    private initializeTimePickers(): void {
        const timePickerConfig = {
            display: {
                viewMode: 'clock' as const,
                components: {
                    decades: false, year: false, month: false, date: false,
                    hours: true, minutes: true, seconds: false
                }
            },
            localization: { format: 'HH:mm' }
        };
        const startElement = document.getElementById('EstimatedTimeFrom');
        const endElement = document.getElementById('EstimatedTimeTo');
        if (!startElement || !endElement) return;
        this.estimatedTimeFrom = new TempusDominus(startElement, timePickerConfig);
        this.estimatedTimeTo = new TempusDominus(endElement, timePickerConfig);
    }

    public async initRoom() {
        if (this.user) {
            this.existingReservations = await this._apiClient.get();
            
            const updateDeskColors = () => {
                $("#conferenceRoom, .desk").each((_, element) => {
                    const elementId = element.id;
                    const isReserved = this.existingReservations.some(r => r.classroomReservationTypeId === elementId);
                    const isSelected = elementId === this.currentlySelectedDesk;

                    // Set background colors
                    if (isSelected) {
                        $(element).css('background-color', isReserved ? 'rgba(255, 111, 0, 1)' : 'rgba(131, 184, 70, 1)');
                    } else if (isReserved) {
                        $(element).css('background-color', 'rgba(255, 111, 0, 0.5)');
                    } else {
                        $(element).css('background-color', '');
                    }
                    
                    // Check permissions and disable clicking
                    
                    // Check permissions - for coloring unavailable clicking items gray
                    // const viewPermission = this.hasPermission(userPermissions, ClassroomPermissions.ClassroomReservationView);
                  
                  

                    // $(element).css('pointer-events', hasPermission ? 'auto' : 'none');
                    // $(element).css('cursor', hasPermission ? 'pointer' : 'not-allowed');
                });
            };
            
            updateDeskColors();
            
            $("#conferenceRoom, .desk").off('click').on('click', async (e) => {
                const targetId = e.currentTarget.id;
                // const hasPermission = targetId === "conferenceRoom"
                //     ? this.hasPermission(userPermissions, ClassroomPermissions.ClassroomConferenceReservation)
                //     : this.hasPermission(userPermissions, ClassroomPermissions.ClassroomDeskReservation);
    
                // if (!hasPermission) {
                //     e.preventDefault();
                //     return;
                // }
                
                this.selectedItemId = targetId;
                this.currentlySelectedDesk = targetId;
                
                if (targetId === "conferenceRoom") {
                    this.currentSelectedDeskTitle = this.translationService.currentTranslations.ConferenceRoom;
                } else {
                    this.currentSelectedDeskTitle = `${this.translationService.currentTranslations.Desk} ${e.currentTarget.textContent}`;
                }
        
                updateDeskColors();
                this.updateVisibility();
        
                const item = this.existingReservations.find(x => x.classroomReservationTypeId === targetId);
                if (item) {
                    await this._rerenderReservation( item.id);
                } else {
                    await this._rerenderReservation();
                }
            });
        }
    }
    
    private _intDeleteReservation(): void {
        const translations = this.translationService.currentTranslations;
        $("#deleteReservation").off('click').on('click', async (e) => {
            e.preventDefault();
            const target = e.currentTarget as HTMLElement;
            const reservationId = $(target).data('id');
    
            if (!reservationId) return;
    
            try {
                const confirmed = await ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.ConfirmDeleteContent,
                    confirmType: 'brisanje'
                });
    
                if (confirmed) {
                    const data = await this._apiClient.deleteReservation(reservationId);
                    if (data) {
                        this.notyf.success(translations.EventSuccessfullySaved);
                        this.clearSelection();
                        this.load();
                    } else if (data && data.message) {
                        this.notyf.error(data.message);
                    } else {
                        this.notyf.error(translations.SaveError);
                    }
                }
            } catch (error: any) {
                console.error('Error deleting reservation:', error);
                if (error.statusCode === 400) {
                    $('#main').text(translations[error.message]);
                } else {
                    this.notyf.error(translations.SaveError);
                }
            }
        });
    }
    
    private updateVisibility(): void {
        const reservationInfo = document.querySelector('.reservation-info-wrapper');
        if (reservationInfo instanceof HTMLElement) {
            const formElements = reservationInfo.querySelectorAll('.form-content');
            const infoMessage = reservationInfo.querySelector('.info-message');
            formElements.forEach(element => {
                if (element instanceof HTMLElement) {
                    element.style.display = this.selectedItemId === null ? 'none' : 'block';
                }
            });
            if (infoMessage instanceof HTMLElement) {
                infoMessage.style.display = this.selectedItemId === null ? 'block' : 'none';
            }
        }
    }

    private hasPermission(permissionToCheck: any) {
        return (BigInt(this.user!.permissions) & BigInt(permissionToCheck)) !== 0n;
    }
    
    private clearSelection(): void {
        if (this.currentlySelectedDesk) {
            const element = document.getElementById(this.currentlySelectedDesk);
            const isReserved = this.existingReservations.some(r => r.classroomReservationTypeId === this.currentlySelectedDesk);

            if (element instanceof HTMLElement && !isReserved) {
                element.style.backgroundColor = '';
            }
            else {
               element!.style.backgroundColor = 'rgba(255, 111, 0, 0.5)';

            }
            this.reservations[this.currentlySelectedDesk as ReservationKeys] = false;
            this.currentlySelectedDesk = null;
            this.selectedItemId = null;
            this.currentSelectedDeskTitle = null;
            this.updateVisibility();
            this._renderUserSelectField();
            this.initializeTimePickers();
        }
    }

    private _renderUserSelectField(): void {
        this._initUserSearchField();
        this._onUserSearchSelected();
    }

    public _onUserSearchSelected(): void {
        $('.user-search-field').on('select2:select', (e) => {
            const data = (e.params.data as unknown) as UserSearchViewModel;
            if (!this._selectedUsers.some(user => user.id === data.id)) {
                const userViewModel: UserSearchViewModel = {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    crmId: data.crmId,
                };
                this._selectedUsers.push(userViewModel);
                this._renderUserWidget(this._selectedUsers, false)
                this._renderUserSelectField();
            }
        });
    }

    private _onDeleteUser(ev: Event): void {
        const target = ev.currentTarget as HTMLElement;
        const idString = target.getAttribute("data-id");
        if (!idString) return;
        const id = parseInt(idString);
        const index = this._selectedUsers.findIndex(el => el.id === id);
        if (index !== -1) {
            this._selectedUsers.splice(index, 1);
            this._renderUserSelectField();
            this._renderUserWidget(this._selectedUsers, false)
        }
    }

    private async _saveReservation() {
        const { currentTranslations: translations } = this.translationService;
        const dateTimeFrom = this.estimatedTimeFrom?.dates.picked[0];
        const dateTimeTo = this.estimatedTimeTo?.dates.picked[0];

        const formData = {
            ClassroomReservationTypeId: this.selectedItemId,
            Type: this.selectedItemId === "conferenceRoom" ? 1 : 0,
            estimatedTimeFrom: dateTimeFrom?.toISOString(),
            estimatedTimeTo: dateTimeTo?.toISOString(),
            description: $('#description').val(),
            classRoomUsers: this._selectedUsers.map(x => x.id),
            isPrivate: $('#isPrivate').prop('checked')
        };

        const userCount = formData.classRoomUsers?.length ?? 0;
        let errorMessage: string | null = null;

        // Validation rules
        if (formData.Type === 0) {
            if (userCount === 0) {
                errorMessage = "Prosim izberite vsaj 1 uporabnika";
            } else if (userCount > 1) {
                errorMessage = "Prosim izberite samo enega uporabnika";
            }
        } else if (formData.Type === 1) {
            if (formData.isPrivate) {
                if (userCount !== 1) {
                    errorMessage = "Prosim izberite samo enega uporabnika";
                }
            } else {
                if (userCount !== 3) {
                    errorMessage = userCount < 3
                        ? "Prosim izberite vsaj 3 uporabnike"
                        : "Prosim izberite samo 3 uporabnike";
                }
            }
        }

        if (errorMessage) {
            this.notyf.error(errorMessage);
            return;
        }

        try {
            const response = await this._apiClient.saveReservation(JSON.stringify(formData));

            if (response) {
                this.notyf.success(translations.EventSuccessfullySaved);
                this.clearSelection();
                this.load();
            } else if (response?.message) {
                this.notyf.error(response.message);
            } else {
                this.notyf.error(translations.SaveError);
            }
        } catch (error: any) {
            console.error('Error submitting reservation:', error);
            this.notyf.error(error.statusCode === 400
                ? translations[error.message]
                : translations.SaveError);
        }
    }
}
