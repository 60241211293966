export class UrlHelper {

    public static getPageFromUrl() {
        var url = decodeURIComponent(window.location.hash);
        if (url && url.includes('?')) {
            var queryString = url.split('?')[1];
            if (queryString) {
                var querySplit = queryString.split("&");
                for (var i = 0; i < querySplit.length; i++) {
                    var param = querySplit[i].split("=");
                    if (param && param.length > 1) {
                        return parseInt(param[1]);
                    }
                }
            }
        }
        return 1;
    }

}