import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { classroomReservationModel, classroomReservationsModel, parseReservation } from "../models/reservations/classroomReservationsModel";

@injectable()
export class ClassRoomReservationApiClient extends ApiClientBase {
    public name = "ClassRoomReservationApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }
    
    public async userSearchOptions(prepareRequest: (request: JQueryAjaxSettings) => void): Promise<JQuery.AjaxSettings> {
        return await this._createAjaxOptionsWithToken('/classroomReservation/search-user', 'GET', "", false, "json", undefined, undefined, prepareRequest);
    }

    public async get(): Promise<Array<classroomReservationsModel>> {
        const currentDateUTC = new Date().toISOString();

        var query ={
            date: currentDateUTC
        }
        return await this._callApi('/classroomReservation', 'GET', query) as Array<classroomReservationsModel>;
    }

    public async saveReservation(formData: any) {
        return await this._callApi('/classroomReservation', 'POST', formData) as any;
   
    }

    public async deleteReservation(id?: Number) {
        return await this._callApi('/classroomReservation/'+id, 'POST') as any;
   
    }
    
    public async getById(id:number): Promise<classroomReservationModel> {
        var data = await this._callApi('/classroomReservation/'+id, 'GET') as classroomReservationModel;
        return  parseReservation(data);
    }
}