import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { UserApiClient } from "../ts/clients/users-api-client";
import { TranslationService } from "../ts/translation-service";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { ChangePasswordModal } from "./change-password-modal";
import template from "./header-view.html";
import { SetEmailModal } from "./set-email-modal";
import stageEnvBanner from "./stageEnvBanner.html";


@injectable()
export class HeaderView {
    private rootElement: HTMLElement;
    private titleElement: JQuery<HTMLElement> | null = null;
    private title: string = "";
    private isLoaded: boolean = false;

    constructor(rootElement: HTMLElement,
        private currentUserAccessor: CurrentUserAccessor,
        private userApiClient: UserApiClient,
        private authenticator: Authenticator,
        private translationService: TranslationService,
        private setEmailModal: SetEmailModal,
        private changePasswordModal: ChangePasswordModal,
        private notyf: Notyf) {
        this.rootElement = rootElement;
    }

    public setTitle(title: string) {
        if (!this.isLoaded) {
            this.title = title;
            return;
        }
        this.titleElement!.html(title);
    }

    public set visible(isVisible: boolean) {
        $(this.rootElement).toggle(isVisible);
    }
        
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            //display stage env banner and add padding to header only in stage env
            if (window.location.origin.includes('student-stage.sd-lj.si')) {
            // if (window.location.origin.includes('localhost:8080')) {
                $('#fullPage').css('padding-top', '30px');
                this.renderInfoAboutStageEnv();
            }
            // Try to get data
            //TODO: prebrati podatke
            // Render new content
            await this._renderData();
            this.isLoaded = true;

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            //$('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private renderInfoAboutStageEnv() {
        const translations = this.translationService.currentTranslations;
        let viewModel = {
            text: translations.TestEnv,
        };
        
        const html = Mustache.render(stageEnvBanner, viewModel);
        $('#env-content').html(html);
    }
    
    private async _renderData(): Promise<void> {
        var currentUser = await this.currentUserAccessor.getUser();
        var viewModel;
        if (currentUser != null) {
            viewModel = {
                user: {
                    title: currentUser.firstName + " " + currentUser.lastName,
                    vloga: currentUser.vlogaTitle,
                    kratica: currentUser.inicialke,
                    authenticated: currentUser.isAuthenticated
                }
            };
        }
        else {
            viewModel = {
                user: {
                    title: "",
                    vloga: "",
                    kratica: "",
                    authenticated: false 
                }
            };
        }        
        
        (viewModel as any).translations = this.translationService.currentTranslations;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        this.rootElement.innerHTML = html;
        this.titleElement = $(this.rootElement).find(".title h1");
        this.titleElement.html(this.title);

        $(this.rootElement).find("[data-action=logout]").on("click", (ev) => {
            ev.preventDefault();
            this.authenticator.startLogout();
        })
        if (!currentUser.email) {
            this.setEmailModal.show();
        }
        else {
            this.userApiClient.checkEmailConfirmed().then((data) => {
                if (data) {
                    if (data.confirmed) {
                        return;
                    }
                    else {
                        $(".header-mail-alert").removeAttr("style");
                        if (data.uiMessage) {
                            $(".header-mail-alert").find(".active-campaign-text").html(data.uiMessage);
                        }

                        if ($("#header-view").css('display') == 'none') {

                            $("#header-view").removeAttr("style");
                            var height = $(".header-mail-alert").height();
                            $("header").attr("style", "margin-top:" + height + "px");
                            $("#header-view").attr("style", "display:none");
                        }
                        else {
                            var height = $(".header-mail-alert").height();
                            $("header").attr("style", "margin-top:" + height + "px");
                        }
                                                
                        $(".header-resend-email").on("click", (ev) => {
                            ev.preventDefault();
                            var translations = this.translationService.currentTranslations;
                            this.userApiClient.resendVerification()
                                .then(() => {
                                    this.notyf.success(translations.ResendSuccess);
                                })
                                .catch(() => {
                                    this.notyf.error(translations.ResendError);
                                });
                        });
                    }
                }
            })
        }

        if (currentUser.resetPassword) {
            this.changePasswordModal.show();
        }
    }
}