import * as DateRangePicker from 'daterangepicker';
import * as moment from 'moment';




export class DatePickerWidget {


    public load(maxYear: number | null = null): void {
        $('head').append('<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css">');
        this.insertDatePicker(maxYear);        
    }

    public insertDatePicker(maxYear: number | null = null): void {
        require("moment/min/locales.min");
        require('moment/locale/en-gb.js');
        require('moment/locale/sl.js');

        //document.documentElement.setAttribute("lang", 'sl'); //testiranje

        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';

        //za language iz browserja
        //
        //const userLocale =
        //    navigator.languages && navigator.languages.length
        //        ? navigator.languages[0]
        //        : navigator.language;
        //console.log(userLocale)

        if (maxYear == null) {
            maxYear = 1;
        }

        moment.locale(userLocale);       
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        DateRangePicker.name;
        $('input.date-picker').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true, //show leto, mesec dropdown
            locale: {
                format: localeFormat
            },
            autoApply: true,
            minYear: 1901,
            maxYear: parseInt(moment().format('YYYY'), 10) + maxYear//dodano zarad registriranja preno?evanj
        });



    }
}

//DatePicker use
//
//call .load() on load
//
//