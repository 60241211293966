import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { TranslationService } from "../ts/translation-service";
import template from "./izjave-uredi-view.html";
import Quill from "quill";
import * as moment from "moment";
import * as DateRangePicker from 'daterangepicker';
import { StatementApiClient } from "../ts/clients/statements-api-client";
import { FullscreenLoader } from "./fullscreen-loader";
import { CustomFieldType, Statement } from "../ts/models/statement";
import "select2";
import { OptionData } from "select2";


@injectable()
export class IzjaveUrediView {
    private _apiClient: StatementApiClient;
    private _translationService: TranslationService;
    private _fromPicker: DateRangePicker = null!;
    private _toPicker: DateRangePicker = null!;
    private _statement: Statement | null;
    private _locations: any | null;
    private _messageEditorQuillElement: Quill | null;
    private _messageEnEditorQuillElement: Quill | null;
    private _messageEditorElement: HTMLElement | null;
    private _messageEnEditorElement: HTMLElement | null;
    private _instructionsEditorQuillElement: Quill | null;
    private _instructionsEnEditorQuillElement: Quill | null;
    private _instructionsEditorElement: HTMLElement | null;
    private _instructionsEnEditorElement: HTMLElement | null;

    public constructor(apiClient: StatementApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = apiClient; 
        this._translationService = translationService;
        this._messageEditorElement = null;
        this._messageEnEditorElement = null;
        this._messageEditorQuillElement = null;
        this._messageEnEditorQuillElement = null;
        this._instructionsEditorQuillElement = null;
        this._instructionsEnEditorQuillElement = null;
        this._instructionsEditorElement = null;
        this._instructionsEnEditorElement = null;
        this._statement = null;
    }
    /*
     * Wait for data then render it
     */
    public async load(id: number): Promise<void> {

        try {
            this._statement = await this._apiClient.getItem(id);
            this._locations = await this._apiClient.getLocations();
            console.log(this._statement);
            
            this._renderData(); 

            if (this._statement.isActive) {
                $("#edit-event-overlay").addClass("show-overlay").removeClass("hide-overlay");
            }

            $("#saveType").on("change", () => {
                if ($("#saveType").val()) {
                    $("#edit-event-overlay").addClass("hide-overlay").removeClass("show-overlay");                   
                }
                else {
                    $("#edit-event-overlay").addClass("show-overlay").removeClass("hide-overlay");
                }
            });

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDelete": "../img/icon-delete.svg",
            "translations": this._translationService.currentTranslations,
            "formators": this.getFormators(),
            "data": this._statement,
            "allLocations": this._locations.allLocations
        } as any;
        // Construct a template
        const htmlTemplate = template;
        
        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);       
        
        this._initForm();

        this._initQuillEditor(this._statement?.content ?? "", false);
        this._initEnQuillEditor(this._statement?.contentEn ?? "", false);
        this._initInstructionsQuillEditor(this._statement?.instructions ?? "", false);
        this._initInstructionsEnQuillEditor(this._statement?.instructionsEn ?? "", false);
    }

    private toggleFields(): void {
        if ($("#addInputField").prop("checked") == true) {
            $(".inputFieldContainer").show();
            if ($("#inputFieldType").val() == "1") {
                $(".inputFieldDwopDownContainer").show();
            }
            else {
                $(".inputFieldDwopDownContainer").hide();
            }
        }
        else {
            $(".inputFieldContainer").hide();
            $(".inputFieldDwopDownContainer").hide();
        }
    }

    private isFormValid(): boolean {
        var valid = true;
        if (!$("#name").val() ||
            !$("#checkBoxLabel").val() ||
            !$("#validFrom").val() ||
            !this._messageEditorQuillElement?.root.innerHTML ||
            !this._messageEnEditorQuillElement?.root.innerHTML ||
            !$('#saveType').val()) {
            valid = false;
        }

        if ($("#addInputField").prop("checked") == true) {
            if (!$("#labelSl").val() ||
                !$("#inputFieldType").val()) {
                valid = false;
            }
            var v = $("#inputFieldType").val();

            if (v == "1") {
                
                if (!$("#inputFieldDropDownOptions").val()) {
                    $("#inputFieldDropDownOptions").addClass("is-invalid");
                    var cl = $("#inputFieldDropDownOptions").siblings(".text-danger")[0];
                    $(cl).removeClass("field-validation-valid");
                    $(cl).addClass("invalid-feedback");
                    $(cl).addClass("field-validation-error");
                    $(cl).html("<span id='inputFieldDropDownOptions-error' class=''>To polje je obvezno.</span>");
                    valid = false;
                }
                else {
                    $("#inputFieldDropDownOptions").removeClass("is-invalid");
                }
            }
        }

        var locations = $("[name=locations]").select2('data');
        if (!locations || locations.length < 1) {
            valid = false;
        }

        return valid;

        
    }

    //inicializira obrazec
    private _initForm(): void {
        var self = this;
        var translations = this._translationService.currentTranslations;
        var formElement = $("#statements-edit-form");

        var locationInput = $("#statements-edit-form").find("[name=locations]");
        locationInput.select2();

        if (this._statement && this._statement.validFrom) {
            $("#validFrom").val(moment(this._statement.validFrom).format('LLL'));
        }
        if (this._statement && this._statement.validTo) {
            $("#validTo").val(moment(this._statement.validTo).format('LLL'));
        }

        if (this._statement?.locations && this._statement?.locations.length > 0) {
            var item = [];
            for (var i = 0; i < this._statement?.locations.length; i++) {
                item.push(this._statement?.locations[i].toString());
            }
            locationInput.val(item);
            locationInput.trigger('change');
        }

        if (this._statement?.customFields && this._statement?.customFields.length > 0) {
            $("#addInputField").prop("checked", true);
            var customField = this._statement?.customFields[0];
            $("#inputFieldType").val(customField.type.toString());
            $("#requiredInputField").prop("checked", customField.requiredField);
            $("#labelSl").val(customField.labelSl);
            $("#labelEn").val(customField.labelEn);
            $("#descriptionSl").val(customField.description);
            $("#descriptionEn").val(customField.descriptionEn);
            if (customField.type == CustomFieldType.DropDown) {               
                $("#inputFieldDropDownOptions").val(customField.dropDownOptionsSl);
                $("#inputFielEnDropDownOptions").val(customField.dropDownOptionsEn);
            }
        }

        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';       

        $.validator.unobtrusive.parse(formElement);

        formElement.on("submit", async (ev) => {
            ev.preventDefault();

            if (!this.isFormValid()) {
                return;
            }

            this.loader.show();

            var formData = new FormData();
            formData.append("id", $("#id").val() as string);
            formData.append("name", $("#name").val() as string);
            formData.append("nameEn", $("#nameEn").val() as string);
            formData.append("validFrom", (this._fromPicker.startDate.isValid() ? this._fromPicker.startDate.format() : undefined) as string);
            formData.append("validTo", (this._toPicker.startDate.isValid() ? this._toPicker.startDate.format() : undefined) as string);
            //formData.append("typ", $("#typ").val() as string);
            formData.append("crmId", $("#crmId").val() as string);
            formData.append("checkBoxLabel", $("#checkBoxLabel").val() as string);
            formData.append("checkBoxLabelEn", $("#checkBoxLabelEn").val() as string);
            //formData.append("graceDaysEmail", $("#graceDaysEmail").val() as string);
            //formData.append("graceDaysBlock", $("#graceDaysBlock").val() as string);
            this._addArrayToForm(formData, "locations", this._parseSelect2DataAsInt($("[name=locations]").select2('data')));

            if ($('#blocksInternet').prop("checked") == true) {
                formData.append("blocksInternet", "1");
            }
            else {
                formData.append("blocksInternet", "0");
            }

            if ($('#saveType') && $('#saveType').val() == "2") {
                formData.append("createNewVersion", "true");
            }
            else {
                formData.append("createNewVersion", "false");
            }

            if ($('#addInputField').prop("checked") == true) {
                var customFields = [];
                customFields.push({
                    labelSl: $("#labelSl").val() as string,
                    labelEn: $("#labelEn").val() as string,
                    type: $("#inputFieldType").val() as string,
                    dropDownOptionsSl: $("#inputFieldDropDownOptions").val() as string,
                    dropDownOptionsEn: $("#inputFielEnDropDownOptions").val() as string,
                    requiredField: $('#requiredInputField').prop("checked"),
                    description: $("#descriptionSl").val() as string,
                    descriptionEn: $("#descriptionEn").val() as string
                });
                //this._addArrayToForm(formData, "customFields", customFields);
                //formData.append("customFields", JSON.stringify(customFields));
                formData.append('customFieldsJson', JSON.stringify(customFields));
            }
           
            formData.append("content", this._messageEditorQuillElement?.root.innerHTML as string);
            formData.append("contentEn", this._messageEnEditorQuillElement?.root.innerHTML as string);
            formData.append("instructions", this._instructionsEditorQuillElement?.root.innerHTML as string);
            formData.append("instructionsEn", this._instructionsEnEditorQuillElement?.root.innerHTML as string);

            await this._apiClient.updateItem(formData).then(
                function (data: any) {
                    self.loader.hide();
                    if (data && data.success) {
                        self.notyf.success(translations.StatementSaved);
                        window.location.href = "/#/izjave/seznam";
                    }
                    else if (data && data.message) {
                        self.notyf.error(data.message);
                    }
                    else {
                        self.notyf.error(translations.SaveError);
                    }                    
                },
                function (reason: any) {
                    self.loader.hide();
                    console.debug(reason);
                    self.notyf.error(translations.SaveError);
                }
            ).catch((ex) => {
                self.loader.hide();
                if (ex.statusCode == 400) {
                    $('#main').text(translations[ex.message]);
                }
            });
        });

        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("LLL");
        this._fromPicker = new DateRangePicker($('#main').find("#validFrom")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years'),
            locale: {
                format: localeFormat
            },
        });
        this._toPicker = new DateRangePicker($('#main').find("#validTo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years'),
            locale: {
                format: localeFormat
            },
        });

        $("#allLocations").on("change", () => {
            if ($("#allLocations").prop("checked") == true) {

                var allItems = [];
                for (var i = 0; i < this._locations.allLocations.length; i++) {
                    var group = this._locations.allLocations[i];
                    for (var j = 0; j < group.locations.length; j++) {
                        allItems.push(group.locations[j].id.toString());
                    }
                }
                locationInput.val(allItems);
                locationInput.trigger('change');
            }
            else {
                locationInput.val([]);
                locationInput.trigger('change');
            }
        });

        this.toggleFields();
        $("#addInputField").on("change", this.toggleFields);
        $("#inputFieldType").on("change", this.toggleFields);

    }    

    private _addArrayToForm(formData: FormData, fieldName: string, array: Array<any> | null): void {
        if (array == null) {
            return;
        }
        for (var i = 0; i < array.length; i++) {
            //formData.append(fieldName, array[i].toString());
            formData.append(fieldName, JSON.stringify(array[i]));
        }
    }

    private _parseSelect2DataAsInt(selected: OptionData[]): Array<number> {
        return selected.filter((val) => {
            return val.selected;
        }).map((val) => {
            return parseInt(val.id, 10);
        });
    }

    //inicializira RTE
    public _initQuillEditor(body: string, readonly: boolean) {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._messageEditorElement = mainElement.find(".editor-container-sl")[0];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._messageEditorQuillElement = new Quill(this._messageEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",
            readOnly: readonly
        });

        this._messageEditorQuillElement.root.innerHTML = body;
    } 

    public _initEnQuillEditor(body: string, readonly: boolean) {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._messageEnEditorElement = mainElement.find(".editor-container-en")[0];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._messageEnEditorQuillElement = new Quill(this._messageEnEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",
            readOnly: readonly
        });

        this._messageEnEditorQuillElement.root.innerHTML = body;
    }   

    public _initInstructionsQuillEditor(body: string, readonly: boolean) {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._instructionsEditorElement = mainElement.find(".instructions-editor-container-sl")[0];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._instructionsEditorQuillElement = new Quill(this._instructionsEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",
            readOnly: readonly
        });

        this._instructionsEditorQuillElement.root.innerHTML = body;
    }

    public _initInstructionsEnQuillEditor(body: string, readonly: boolean) {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._instructionsEnEditorElement = mainElement.find(".instructions-editor-container-en")[0];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._instructionsEnEditorQuillElement = new Quill(this._instructionsEnEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",
            readOnly: readonly
        });

        this._instructionsEnEditorQuillElement.root.innerHTML = body;
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }
        };
    }
}