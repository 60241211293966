import { injectable } from "inversify";
import * as Mustache from "mustache";
import { DamagesApiClient } from "../ts/clients/damage-api-client";
//import { TransferDataDamages } from "../ts/models/transfer-data-damages";
import { DatePickerWidget } from "../../../skupno/src/ts/widgets/date-picker-widget";
//import * as DateRangePicker from 'daterangepicker';
import template from "./okvare-dezurna-knjiga-dodaj-view.html";
import { TransferDataDamages } from "../ts/models/transfer-data-damages";
import { TranslationService } from "../ts/translation-service";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";


@injectable()
export class OkvareDezurnaKnjigaDodajView {
    private _apiClient: DamagesApiClient;
    private _datePicker: DatePickerWidget;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _loader: FullscreenLoader;

    private _formElement: JQuery<HTMLElement> | null;
    //private _btnSubmitElement: JQuery<HTMLElement> | null;
    //private _datumDogodkaElement: HTMLElement | null;
    //private _datumDogodkaDatePicker: DateRangePicker | null;

    public constructor(damagesApiClient: DamagesApiClient, translationService: TranslationService, _notyf: Notyf, loader: FullscreenLoader) {
        console.debug({ damagesApiClient, translationService, _notyf });
        this._apiClient = damagesApiClient;
        this._notyf = _notyf;
        this._loader = loader;
        this._datePicker = new DatePickerWidget;
        this._translationService = translationService;
        this._formElement = null;
        //this._btnSubmitElement = null;
        //this._datumDogodkaElement = null;
        //this._datumDogodkaDatePicker = null;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            var options = await this._apiClient.getDezurnaKnjigaOptions();
            // Render new content
            this._renderData(options);
            this._datePicker.load();
            this._initForm();
        } catch (e) {
            console.debug(e);
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }
    
    private _initForm(): void {
        require('jquery-validation-unobtrusive');
        jQuery.validator.unobtrusive.parse($('#main').find(".send-form-damage"));
        this._formElement = $(("#send-form-damage"));     
        //this._btnSubmitElement = $(this._formElement).find("#btnSubmitDamage");
        //if (this._btnSubmitElement == null) { return; }
        //this._btnSubmitElement.on("click", (ev: Event) => this._onBtnSubmitClick(ev));
        //this._datumDogodkaElement = document.getElementById("datumDogodka") as HTMLFormElement;
        //this._datumDogodkaDatePicker = new DateRangePicker(this._datumDogodkaElement);
        this._formElement.on("submit", (ev: Event) => this._onFormSubmit(ev));
    }

    private FormatDate(dateString: string): Date {

        var date = dateString.toString().replace(" ", "").split(".")
        const day: number = Number(date[0]) + 1;
        const month = Number(date[1]) - 1;
        const year = Number(date[2]);

        return new Date(year, month, day);

    }

    private checkIfNull(x: any) {
        if (x === undefined || x === null || x === "") {
            return "";
        } else {
            return x;
        }
    }
    
    private async _onFormSubmit(ev: Event) {
        var th = this;
        console.debug(ev);
        ev.preventDefault();
        if (this._formElement == null) { return; }       
        if (!this._formElement?.valid()) {
            return;
        }

        var formData = new FormData(this._formElement[0] as HTMLFormElement);
        var sd = formData.get("datum")?.toString()
        var date = this.FormatDate(sd as any);

        let data: TransferDataDamages = {
            prijaviteljOpis: formData.get("prijaviteljOpis") as string,
            datumDogodka: new Date(this.checkIfNull(date)),
            kratekOpis: formData.get("kratekOpis") as string,
            lokacijaSifra: formData.get("lokacijaSifra") as string,
            opis: formData.get("opis") as string,
            podlokacija: formData.get("podlokacija") as string,
            povzrociteljOpis: formData.get("povzrociteljOpis") as string,
            povzrociteljTip: formData.get("povzrociteljTip") as string,
            stevilkaSobe: formData.get("stevilkaSobe") as string,
            
        };
        this._loader.show();
        await this._apiClient.postDataDezurnaKnjiga(data).then(() => {
            console.debug(data);
            th._notyf.success(th._translationService.currentTranslations.SaveSuccessful);
            document.location.hash = "#/okvare/dezurna-knjiga";
        }).catch((ex) => {
            if (ex.statusCode == 400) {
                this._notyf.error(th._translationService.currentTranslations.SaveError + "\n" + ex.message);
            }
            else {
                this._notyf.error(th._translationService.currentTranslations.SaveError);
            }
        }).finally(() => {
            this._loader.hide();
        });
       
    }
    
    
    private _renderData(options:any): void {
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "podlokacijaSifraOptions": this.mustacheFormatData(options.sifranti.find((o: { nazivSifranta: string; }) => o.nazivSifranta==="SubLocation").vrednosti),
            "povzrociteljTipOptions": this.mustacheFormatData(options.sifranti.find((o: { nazivSifranta: string; }) => o.nazivSifranta === "VandalType").vrednosti),
            "campuses": options.campuses,
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }
    
    
    private mustacheFormatData(data: any): any {
        var mustacheFormattedData = { "values": [] } as any;

        for (var prop in data) {
            if (data.hasOwnProperty(prop)) {
                mustacheFormattedData["values"].push({
                    'key': this._translationService.currentTranslations[prop] ?? prop,
                    'value': data[prop]
                });
            }
        }
        return mustacheFormattedData;
    }
    
}
