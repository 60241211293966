import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { SportActivityLogModel } from "../models/sport-activity-log-model";
import { ActivityLogListInitResponse } from "../models/activity-log-list-init-response";

@injectable()
export class SportActivityApiClient extends ApiClientBase {
    public name = "SportActivityApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }
    public async getListInit(page?: number, limit?: number, searchTerm?: string, sportEventId?: number, sportPackageId?: number, sportSubscriptionId?: number, searchType?: number): Promise<ActivityLogListInitResponse> {
        const data = {
            "page": page,
            "limit": limit,
            "search": searchTerm,
            "sportEventId": sportEventId,
            "sportPackageId": sportPackageId,
            "sportSubscriptionId": sportSubscriptionId,
            "searchType": searchType,
        };
        return await this._callApi('/SportActivityLog/list-init', 'GET', data) as ActivityLogListInitResponse;
    }
    public async getList(page?: number, limit?: number, searchTerm?: string, sportEventId?: number, sportPackageId?: number, sportSubscriptionId?: number, searchType?: number, month?: number): Promise<ListResponse<SportActivityLogModel>> {
        const data = {
            "page": page,
            "limit": limit,
            "search": searchTerm,
            "sportEventId": sportEventId,
            "sportPackageId": sportPackageId,
            "sportSubscriptionId": sportSubscriptionId,
            "month": month,
            "searchType": searchType,
        };
        return await this._callApi('/SportActivityLog/list', 'GET', data) as ListResponse<SportActivityLogModel>;
    }
}
