import { injectable } from "inversify";
import * as Mustache from "mustache";
import Quill from "quill";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import template from "./sport-dodaj-narocnino-view.html";
import priponkeTemplate from "./widget-priponke.html";
import izbirnikUporabnikovTemplate from "./widget-izbirnik-uporabnikov.html";
import { TranslationService } from "../ts/translation-service";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";

@injectable()
export class SportDodajNarocninoView {
    private _apiClient: SportEventApiClient;
    private _messageEditorQuillElement: Quill | null;
    private _messageEditorElement: HTMLElement | null;
    private _uploadFilesElement: HTMLInputElement | null;
    private _fileList: Array<any>;
    private _fileViewList: Array<any>;
    private _selectedManagers: Array<any>;
    private _priceList: Array<any>;
    private _translationService: TranslationService;

    public constructor(sportEventApiClient: SportEventApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = sportEventApiClient; 
        this._messageEditorElement = null;
        this._messageEditorQuillElement = null;
        this._uploadFilesElement = null;
        this._fileList = new Array<File>();
        this._fileViewList = new Array<any>();
        this._selectedManagers = new Array<any>();
        this._priceList = new Array<any>();
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            //var list = await this._apiClient.getSubscriptions();
            // Render new content
            var options = await this._apiClient.getOptions();
            this._priceList = options.priceList;
            this._renderData();
            this._renderAttachments();
            this._renderUserSelectField();
            this._initForm();
            this._initQuillEditor();

        } catch (e) {
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDelete": "../img/icon-delete.svg",
            "priceList": this._priceList,
            "translations": this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }

    //#region Form

    //inicializira obrazec
    private _initForm(): void {
        var self = this;
        var translations = this._translationService.currentTranslations;
        var formElement = $("#sport-narocnina-add-form");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", async (ev) => {
            ev.preventDefault();
            if (!formElement.valid()) {
                this.notyf.error(translations.CheckAllFields);
                return;
            }
            this.loader.show();
            var formData = new FormData();
            formData.append("title", $("[name=title]").val() as string);
            formData.append("body", this._messageEditorQuillElement?.root.innerHTML as string);

            //Price
            var priceId = $("[name=price]").val();
            var priceElement = this._priceList.find((el) => el.id == priceId);
            if (priceElement) {
                formData.append("price", priceElement.price);
                formData.append("priceCode", priceElement.id);
            }

            formData.append("published", $('#published').is(":checked") ? "1" : "0");
            for (var i = 0; i < this._fileList.length; i++) {
                formData.append("attachments", this._fileList[i]);
            }
            formData.append("managersJson", JSON.stringify(this._selectedManagers));
            formData.append("managers", JSON.stringify(this._selectedManagers));
            formData.append("deletedDocumentsJson", "[]");
            formData.append("deletedDocuments", "[]");
            formData.append("uploadedDocuments", "[]");

            //var translations = this._translationService.currentTranslations;

            this._apiClient.createSubscription(formData).then(
                function (_data: any) {
                    self.loader.hide();
                    window.location.href = "/#/sport/narocnina";
                    self.notyf.success(translations["SubscriptionSuccessfullySaved"]);
                },
                function (reason: any) {
                    self.loader.hide();
                    console.debug(reason);
                    self.notyf.error(translations["SaveError"]);
                }
            ).catch((ex) => {
                if (ex.statusCode == 400) {
                    console.log(ex);
                    self.loader.hide();
                    $('#main').text(translations[ex.message]);
                }
            });
        });
    }

    //inicializira RTE
    public _initQuillEditor() {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._messageEditorElement = mainElement.find(".editor-container")[0];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._messageEditorQuillElement = new Quill(this._messageEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow"
        });
    }   

    //#endregion Form

    //#region Attachments

    //render attachments
    private _renderAttachments(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "files": this._fileViewList,
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = priponkeTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#attachments-widget').html(html);

        this._initUploadField();
        this._initDeleteAttachment();
    }

    //inicializira file upload polje
    public _initUploadField() {
        this._uploadFilesElement = document.getElementById("attachments") as HTMLInputElement;
        this._uploadFilesElement.addEventListener("change", (ev: Event) => this._onUploadChange(ev));
    }

    //inicializira gumb za brisanje priponk
    public _initDeleteAttachment() {
        var self = this;
        $(".delete-attachment-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeleteAttachment(ev));
            //$(this).on("click", (ev: Event) => self._onDeleteAttachment(ev));
        });
    }

    //shrani pripete dokumente v seznam za kasnej�e po�iljanje na stre�nik
    public _onUploadChange(ev: Event) {
        var th = this;
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            $(list).each(function (_index, element) {

                th._fileList.push(element);
            })
        }

        //Datoteke prekopiramo v view model, zaradi indexa
        this._fileViewList = [];
        for (let i in this._fileList) {
            this._fileViewList.push({ 'index': i, 'name': this._fileList[i].name });
        }

        this._renderAttachments();
    }

    //event za brisanje priponk
    public _onDeleteAttachment(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var indexString = target.getAttribute("data-index");
        if (!indexString) {
            return;
        }
        var index = parseInt(indexString);
        if (index || index == 0) {
            this._fileList.splice(index, 1);
        }
        this._fileViewList = [];
        for (let idx in this._fileList) {
            this._fileViewList.push({ 'index': idx, 'name': this._fileList[idx].name });
        }

        this._renderAttachments();
    }
    
    //#endregion Attachments

    //#region UserSearchField

    //render user search field
    private _renderUserSelectField(): void {
        var translations = this._translationService.currentTranslations;
        // Build a view model from the API data
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "users": this._selectedManagers,
            "title": translations.ManagersList,
            "subtitle": translations.SearchRecipient,
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = izbirnikUporabnikovTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#users-select-widget').html(html);

        this._initUserSearchField();
        this._onUserSearchSelected();
        this._initDeleteUser();
    }

    //Inicializira user search field
    public async _initUserSearchField() {
        require('select2');
        $(".user-search-field").select2({
            ajax: (await this._apiClient.userSearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.user-search-field').data("select2").dropdown.$search.val()
                }, false);
            })) as any,
            placeholder: "Janez Marinko / 999995665 / Mestni log - Dom 1 / 99",
            minimumInputLength: 1,
            allowClear: false
        });   
    }

    //inicialiira on user selected event
    public _onUserSearchSelected() {
        var self = this;
        $('.user-search-field').on('select2:select', function (e) {
            var data = e.params.data as any;

            if (self._selectedManagers.find(a => a.id == data.id)) {
                return;
            }

            console.log(data);
            self._selectedManagers.push({
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                crmId: data.crmId,
                kampus: data.kampus,
                location: data.location,
                room: data.room,
            });

            self._renderUserSelectField();
        });
    }

    //inicializira gumb za brisanje uporabnikov
    public _initDeleteUser() {
        var self = this;
        $(".delete-user-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeleteUser(ev));
        });
    }

    //event za brisanje uporabnikov
    public _onDeleteUser(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var idString = target.getAttribute("data-id");
        if (!idString) {
            return;
        }
        var id = parseInt(idString);
        var index = this._selectedManagers.findIndex((el) => el.id == id);
        if (index || index == 0) {
            this._selectedManagers.splice(index, 1);
        }

        this._renderUserSelectField();
    }

    //#endregion UserSearchField 
}