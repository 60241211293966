import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { CouponsApiClient } from "../clients/coupons-api-client";
import { KuponiView } from "../../views/kuponi-view";
import { FullscreenLoader } from "../../views/fullscreen-loader";

@injectable()
export class KuponiPage extends PageBase {
    public name = "KuponiPage";
    private _apiClient: CouponsApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _translationService: TranslationService;
    private _kuponiView: KuponiView;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;
    private _loader: FullscreenLoader;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("CouponsApiClient") apiClient: CouponsApiClient,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._apiClient = apiClient;
        this._router = router;
        this._notyf = notyf;
        this._loader = loader;
        this._translationService = translationService;
        this._kuponiView = new KuponiView(this._apiClient, this._translationService, this._notyf, this._loader);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        var urlDecoded = decodeURIComponent(url.pathname);
        if (!url.pathname.endsWith("/kuponi") && !urlDecoded.includes("/kuponi?page")) {
            return true;
        }

        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            mainLayout.pageTitle = this._translationService.currentTranslations.Coupons;
            mainLayout.htmlTitle = this._translationService.currentTranslations.Coupons;
            mainLayout.headerUserViewShow();
            mainLayout.profileSidebarViewHide();
            await this._kuponiView.load();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}