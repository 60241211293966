import { injectable } from "inversify";
import * as Mustache from "mustache";
import { NotificationApiClient } from "../ts/clients/notifications-api-client";
import template from "./obvestila-uredi-view.html";
import Quill from "quill";
import { TranslationService } from "../ts/translation-service";
import { NotificationEditInitializationModel } from "../ts/models/notification-add-initialization-model";
import "select2";
import { Notyf } from "notyf";
import { OptionData } from "select2";
import izbirnikUporabnikovTemplate from "./widget-izbirnik-uporabnikov.html";
import priponkeTemplate from "./widget-priponke.html";
import { NotificationEditRequest } from "../ts/models/notification-create-request";
import "jquery-validation-unobtrusive";
import { UserSearchViewModel } from "../ts/models/sport-subscription-viewmodel";

@injectable()
export class ObvestilaUrediView {
    private _apiClient: NotificationApiClient;
    private _translationService: TranslationService;
    private _translations: any;

    private _messageEditorElement: HTMLElement | null;
    private _uploadFilesElement: HTMLInputElement | null;
    //private _fileList: any;
    private _selectedUsers: Array<UserSearchViewModel>;
    //private _fileViewList: Array<any>;

    //Seznam na novo naloženih datotek
    private _filesToUpload: Array<any>;
    //Seznam datotek, ki so že naložene na strežniku
    private _uploadedFiles: Array<any>;
    //Seznam za brisanje datotek, ki so že naložene na strežniku
    private _filesToDelete: Array<any>;
    //Seznam datotek za prikaz
    private _filesToDisplay: Array<any>;

    public constructor(notificationApiClient: NotificationApiClient, translationService: TranslationService, private notyf: Notyf)
    {
        this._apiClient = notificationApiClient;
        this._translationService = translationService;

        this._messageEditorElement = null;
        this._uploadFilesElement = null;
        //this._fileList = new Array<File>();
        this._selectedUsers = new Array<UserSearchViewModel>();
        //this._fileViewList = new Array<any>();

        this._filesToUpload = new Array<File>();
        this._uploadedFiles = new Array<any>();
        this._filesToDelete = new Array<any>();
        this._filesToDisplay = new Array<any>();

    }
    /*
     * Wait for data then render it
     */
    public async load(id: number): Promise<void> {

        try {
            var initModel = await this._apiClient.editFormInitialization(id);
            console.log(id);
            this._uploadedFiles = initModel.uploadedAttachments;
            // Render new content
            this._renderData(initModel);
            this._initQuillEditor(initModel.body);
            this._renderAttachments();
            this._initFormSubmit();
            this._initConfidetialityLevels();
            this._setFormData(initModel);
            this._initRecipientCounter();
            this._initUserSelector();

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _setFormData(data: NotificationEditInitializationModel): void {
        if (data.filters) {
            var form = $("#notification-edit-form");
            var locationInput = form.find("[name=location]");
            var roleInput = form.find("[name=role]");
            var emailStatusInput = form.find("[name=emailStatus]");
            var userCategoryInput = form.find("[name=userCategory]");
            var notificationRoleInput = form.find("[name=notificationRole]");
            locationInput.select2();
            roleInput.select2();
            emailStatusInput.select2();
            userCategoryInput.select2();
            notificationRoleInput.select2();

            if (data.id) {
                form.find("[name=id]").val(data.id);
            }
            if (data.subject) {
                form.find("[name=subject]").val(data.subject);
            }
            if (data.body) {
                form.find("[name=body]").val(data.subject);
            }
            if (data.filters.selectedNotificationTypes && data.filters.selectedNotificationTypes.length > 0) {
                form.find("[name=notificationType]").val(data.filters.selectedNotificationTypes[0]);
            }
            if (data.filters.selectedConfidentialityLevel) {
                form.find("[name=coinfidentialityLevel]").val(data.filters.selectedConfidentialityLevel);
                if (data.filters.selectedConfidentialityLevel == 3) {
                    var notificationRole = form.find("#notificationRoleWrap");
                    notificationRole.show();
                }
                else {
                    var notificationRole = form.find("#notificationRoleWrap");
                    notificationRole.hide();
                }
            }
            if (data.filters.posljiTudiNaNepotrjene) {
                form.find("[name=posljiTudiNaNepotrjene]").prop("checked", true);
            }
            if (data.filters.selectedLocations && data.filters.selectedLocations.length > 0) {
                var item = [];
                for (var i = 0; i < data.filters.selectedLocations.length; i++) {
                    item.push(data.filters.selectedLocations[i].toString());
                }
                locationInput.val(item);
                locationInput.trigger('change');
            }
            if (data.filters.selectedRoles && data.filters.selectedRoles.length > 0) {
                var item = [];
                for (var i = 0; i < data.filters.selectedRoles.length; i++) {
                    item.push(data.filters.selectedRoles[i].toString());
                }
                roleInput.val(item);
                roleInput.trigger('change');
            }
            if (data.filters.selectedEmailStatuses && data.filters.selectedEmailStatuses.length > 0) {
                var item = [];
                for (var i = 0; i < data.filters.selectedEmailStatuses.length; i++) {
                    item.push(data.filters.selectedEmailStatuses[i].toString());
                }
                emailStatusInput.val(item);
                emailStatusInput.trigger('change');
            }
            if (data.filters.selectedUserCategories && data.filters.selectedUserCategories.length > 0) {
                var item = [];
                for (var i = 0; i < data.filters.selectedUserCategories.length; i++) {
                    item.push(data.filters.selectedUserCategories[i].toString());
                }
                userCategoryInput.val(item);
                userCategoryInput.trigger('change');
            }
            if (data.filters.selectedNotificationRoles && data.filters.selectedNotificationRoles.length > 0) {
                var item = [];
                for (var i = 0; i < data.filters.selectedNotificationRoles.length; i++) {
                    item.push(data.filters.selectedNotificationRoles[i].toString());
                }
                notificationRoleInput.val(item);
                notificationRoleInput.trigger('change');
            }
            if (data.filters.users) {
                this._selectedUsers = data.filters.users;
            }
        }
    }

    private _initFormSubmit(): void {
        var translations = this._translationService.currentTranslations;
        var formElement = $("#notification-edit-form");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", (ev) => {
            ev.preventDefault();
            if (!formElement.valid()) {
                this.notyf.error(translations.CheckAllFields);
                return;
            }
            var formData = this._createFormData();
            this._apiClient.edit(formData)
                .then(() => {
                    this.notyf.success(translations.SaveSuccessful);
                    document.location.hash = "#/obvestila/objavljena-obvestila";
                })
                .catch((ex) => {
                    if (ex.statusCode == 400) {
                        this.notyf.error(translations.SaveError + "\n" + ex.message);
                    }
                    else {
                        this.notyf.error(translations.SaveError);
                    }
                });
        });

        formElement.find("#notificationAddFormBody").on("focus", () => {
            var quill: Quill = $(this._messageEditorElement!).data("quill");
            quill.focus();
        })
    }

    private _initConfidetialityLevels() {
        var formElement = $("#notification-edit-form");
        var coinfidentialityLevelSelectElement = formElement.find("[name=coinfidentialityLevel]");
        var notificationRoleSelectElement = formElement.find("[name=notificationRole]");
        var notificationRoleWrapElement = formElement.find("#notificationRoleWrap");

        if (notificationRoleWrapElement) {
            notificationRoleWrapElement.hide();
        }

        coinfidentialityLevelSelectElement.on("change", (ev: Event) => {
            console.debug("ev", ev);
            console.debug("coinfidentialityLevelSelectElement", coinfidentialityLevelSelectElement.val());
            console.debug("notificationRoleSelectElement", notificationRoleSelectElement.val());
            console.debug("notificationRoleWrapElement", notificationRoleWrapElement);
            
            if (coinfidentialityLevelSelectElement && coinfidentialityLevelSelectElement.val() && coinfidentialityLevelSelectElement.val() == 3) {
                notificationRoleWrapElement.show();                
            }
            else {
                notificationRoleWrapElement.hide();
            }
        });

    }

    private  _initUserSelector() {
        var form = $("#notification-edit-form");
        var translations = this._translationService.currentTranslations;

        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "users": this._selectedUsers,
            translations: translations,
            "title": translations.AddNewRecipient,
            "subtitle": translations.SearchRecipient,
            "upload": true,
            "uploadIcon": "../img/icon-upload.svg",
            "uploadText": translations.UploadXlsFile,
        } as any;
        // Construct a template
        const htmlTemplate = izbirnikUporabnikovTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        form.find('#users-select-widget').html(html);

        this._initUserSearchField();
        this._onUserSearchSelected();
        this._initDeleteUser();
        this._initXlsFileUpload();
    }

    
    public async _initUserSearchField() {
        require('select2');
        $(".user-search-field").select2({
            ajax: (await this._apiClient.userSearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.user-search-field').data("select2").dropdown.$search.val()
                }, false);
            })) as any,
            placeholder: "Janez Novak / 999995665",
            minimumInputLength: 1,
            allowClear: false
        });
    }

    public _onUserSearchSelected() {
        var self = this;
        $('.user-search-field').on('select2:select', function (e) {
            var data = e.params.data as any;

            if (self._selectedUsers.find(a => a.id == data.id)) {
                return;
            }

            self._selectedUsers.push({
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                crmId: data.crmId,
                location: data.location,
                kampus: data.kampus,
                room: data.room
            } as any);

            self._initUserSelector();
        });
    }

    public _initXlsFileUpload() {
        var th = this;
        var translations = this._translationService.currentTranslations;

        $("#recipients-xls").on('change', function (ev) {
            var data = new FormData();
            const target = ev.target as HTMLInputElement;
            let list: FileList | null = target.files;
            if (!list) {
                return;
            }

            let file = list[0];
            data.append('file', file);

            th._apiClient.uploadXlsFile(data)
                .then((result) => {
                    if (result && result.length > 0) {
                        result.forEach((el: any) => {
                            if (th._selectedUsers.find(a => a.id == el.id)) {
                                return;
                            }
                            th._selectedUsers.push(el);
                        });
                    }
                    th.notyf.success("Uvoz prejemnikov uspešen");
                    th._initUserSelector();
                })
                .catch(() => {
                    th.notyf.error(translations.SaveError);
                });
        });
    }

    //inicializira gumb za brisanje uporabnikov
    public _initDeleteUser() {
        var self = this;
        $(".delete-user-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeleteUser(ev));
        });
    }

    public _onDeleteUser(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var idString = target.getAttribute("data-id");
        if (!idString) {
            return;
        }
        var id = parseInt(idString);
        var index = this._selectedUsers.findIndex((el) => el.id == id);
        if (index || index == 0) {
            this._selectedUsers.splice(index, 1);
        }

        this._initUserSelector();
    }


    private _initRecipientCounter() {
        var form = $("#notification-edit-form");
        var refreshButton = form.find("[data-action=refresh-recipient-count]");
        var recipientCountElement = form.find("#recipient-count");
        var translations = this._translationService.currentTranslations;
        recipientCountElement.html("0");

        var filters = this._readValuesFromInputs();

        this._apiClient.recipientCount(filters)
            .then((recipientCount) => {
                recipientCountElement.html(recipientCount.toString());
            })
            .catch(() => {
                this.notyf.error(translations.LoadingError);
            });

        refreshButton.on("click", (ev) => {
            ev.preventDefault();

            var filters = this._readValuesFromInputs();

            this._apiClient.recipientCount(filters)
                .then((recipientCount) => {
                    recipientCountElement.html(recipientCount.toString());
                })
                .catch(() => {
                    this.notyf.error(translations.LoadingError);
                });
        });

    }

    private _createFormData(): FormData {
        var inputsData = this._readValuesFromInputs();
        var formData = new FormData();
        formData.set("id", inputsData.id);
        formData.set("subject", inputsData.subject);
        formData.set("body", inputsData.body);
        formData.set("posljiTudiNaNepotrjene", inputsData.posljiTudiNaNepotrjene ? "true" : "false");
        this._addArrayToForm(formData, "selectedUsers", inputsData.selectedUsers);
        this._addArrayToForm(formData, "selectedRoles", inputsData.selectedRoles);
        this._addArrayToForm(formData, "selectedNotificationRoles", inputsData.selectedNotificationRoles);
        this._addArrayToForm(formData, "selectedLocations", inputsData.selectedLocations);
        this._addArrayToForm(formData, "selectedNotificationTypes", inputsData.selectedNotificationTypes);
        //this._addArrayToForm(formData, "selectedConfidentialityLevel", inputsData.selectedConfidentialityLevel);
        formData.set("selectedConfidentialityLevel", inputsData.selectedConfidentialityLevel?.toString() as string);
        this._addArrayToForm(formData, "selectedEmailStatuses", inputsData.selectedEmailStatuses);
        this._addArrayToForm(formData, "selectedUserCategories", inputsData.selectedUserCategories);
        if (this._filesToUpload != null && this._filesToUpload.length > 0) {
            for (var i = 0; i < this._filesToUpload.length; i++) {
                formData.append("attachments", this._filesToUpload[i]);
            }
        }       
        return formData;
    }

    private _addArrayToForm(formData: FormData, fieldName: string, array: Array<any> | null): void {
        if (array == null) {
            return;
        }
        for (var i = 0; i < array.length; i++) {
            formData.append(fieldName, array[i].toString());
        }
    }
    private _readValuesFromInputs(): NotificationEditRequest {
        var form = $("#notification-edit-form");
        return {
            id: form.find("[name=id]").val() as string,
            subject: form.find("[name=subject]").val() as string,
            body: form.find(".editor-container .ql-editor").html(),
            posljiTudiNaNepotrjene:  form.find("[name=posljiTudiNaNepotrjene]").is(":checked"),
            selectedUsers: this._selectedUsers.flatMap((val) => val.id),
            selectedRoles: this._parseSelect2DataAsInt(form.find("[name=role]").select2('data')),
            selectedNotificationRoles: this._parseSelect2DataAsInt(form.find("[name=notificationRole]").select2('data')),
            selectedLocations: this._parseSelect2DataAsInt(form.find("[name=location]").select2('data')),
            selectedNotificationTypes: [parseInt(form.find("[name=notificationType]").val() as string, 10)],
            selectedEmailStatuses: this._parseSelect2DataAsInt(form.find("[name=emailStatus]").select2('data')),
            selectedUserCategories: this._parseSelect2DataAsString(form.find("[name=userCategory]").select2('data')),
            selectedConfidentialityLevel: parseInt(form.find("[name=coinfidentialityLevel]").val() as string, 10)
        };
    }

    private _parseSelect2DataAsInt(selected: OptionData[]): Array<number> {
        return selected.filter((val) => {
            return val.selected;
        }).map((val) => {
            return parseInt(val.id, 10);
        });
    }
    private _parseSelect2DataAsString(selected: OptionData[]): Array<string> {
        return selected.filter((val) => {
            return val.selected;
        }).map((val) => {
            return val.id;
        });
    }
    //private _parseSelect2DataAsBoolean(selected: OptionData[]): Array<boolean> {
    //    return selected.filter((val) => {
    //        return val.selected;
    //    }).map((val) => {
    //        return val.id == "true";
    //    });
    //}

    //inicializira RTE
    public _initQuillEditor(body: string) {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], 
            ['link']

        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._messageEditorElement = mainElement.find(".editor-container")[0];
        var quill = new Quill(this._messageEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",
            
        });
        var toolbar = quill.getModule('toolbar');
        toolbar.addHandler('link', (_value: any) => {
           // const range = quill.getSelection(true);
            var href = prompt('Enter the URL');
            //quill.insertText(range.index, text, 'user');
            //quill.setSelection((range.index, text.length);
            (quill as any).theme.tooltip.edit('link', href);
            (quill as any).theme.tooltip.save();

            //quill.setSelection(range.index + text.length);
        });

        quill.on('text-change', (_delta, _oldDelta, _source) => {
            var html = $(this._messageEditorElement!).find(".ql-editor").text();
            mainElement.find("#notificationAddFormBody").val(html).trigger("change");
        });
        $(this._messageEditorElement).data("quill", quill);

        quill.root.innerHTML = body;

    }

    private _renderData(data: NotificationEditInitializationModel): void {
        var th = this;
        console.debug("translations", this._translations);
        data.allStopnjeZaupnosti.forEach(function (value, _index) {
            value.prevod = th._translationService.currentTranslations[value.text];
        });

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDelete": "../img/icon-delete.svg",
            "data": data,
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        var form = $("#notification-edit-form");
       // var notificationTypeInput = form.find("[name=notificationType]");
        var locationInput = form.find("[name=location]");
        var roleInput = form.find("[name=role]");
        var emailStatusInput = form.find("[name=emailStatus]");
        var userCategoryInput = form.find("[name=userCategory]");
        var notificationRoleInput = form.find("[name=notificationRole]");

        locationInput.select2();
        roleInput.select2();
        emailStatusInput.select2();
        userCategoryInput.select2();
        notificationRoleInput.select2();       
    }

    //#region Attachments

    //render attachments
    private _renderAttachments(): void {
        this._setFilesToDisplay();
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "files": this._filesToDisplay,
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = priponkeTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#attachments-widget').html(html);

        this._initUploadField();
        this._initDeleteAttachment();
    }

    //inicializira file upload polje
    public _initUploadField() {
        this._uploadFilesElement = document.getElementById("attachments") as HTMLInputElement;
        this._uploadFilesElement.addEventListener("change", (ev: Event) => this._onUploadChange(ev));
    }

    //inicializira gumb za brisanje priponk
    public _initDeleteAttachment() {
        var self = this;
        $(".delete-attachment-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeleteAttachment(ev));
        });
    }

    //shrani pripete dokumente v seznam za kasnej?e po?iljanje na stre?nik
    public _onUploadChange(ev: Event) {
        var th = this;
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            $(list).each(function (_index, element) {
                th._filesToUpload.push(element);
            })
        }

        this._renderAttachments();
    }

    //event za brisanje priponk
    public _onDeleteAttachment(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var idString = target.getAttribute("data-id");
        var indexString = target.getAttribute("data-index");

        if (idString) {
            //Brisanje že naloženih datotek
            var id = parseInt(idString);
            if (id) {
                var index = this._uploadedFiles.findIndex((el) => el.id == id);
                if (index || index == 0) {
                    //Dodamo datoteko v seznam za brisanje
                    this._filesToDelete.push({ 'id': this._uploadedFiles[index].id, 'name': this._uploadedFiles[index].name });
                    //Izbrišemo datoteko iz seznama
                    this._uploadedFiles.splice(index, 1);
                }
            }
        }
        else if (indexString) {
            //Brisanje novih datotek
            var index = parseInt(indexString);
            if (index || index == 0) {
                this._filesToUpload.splice(index, 1);
            }
        }

        this._renderAttachments();
    }


    public _setFilesToDisplay() {
        this._filesToDisplay = [];
        for (let i in this._uploadedFiles) {
            this._filesToDisplay.push({ 'id': this._uploadedFiles[i].id, 'index': null, 'name': this._uploadedFiles[i].name });
        }
        for (let i in this._filesToUpload) {
            this._filesToDisplay.push({ 'id': null, 'index': i, 'name': this._filesToUpload[i].name });
        }
    }

    //#endregion Attachments
}