import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { TableWidget } from "../../../skupno/src/ts/widgets/table-widget";
import { NoticeApiClient } from "../ts/clients/notice-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./opomini-view.html";

@injectable()
export class OpominiView {
    private _apiClient: NoticeApiClient;
    private _translationService: TranslationService;

    public constructor(noticeApiClient: NoticeApiClient, translationService: TranslationService, private notyf: Notyf) {
        this._apiClient = noticeApiClient;
        this._translationService = translationService; 
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            //TODO: prebrati podatke
            // Render new content
            var translations: any = await this._translationService.currentTranslations;
            this._renderData();
            var rootElement = $("#main").find(".opomini-table-placeholder");
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: rootElement,
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    return await this._apiClient.getList(currentPage);
                },
                onRender: () => {
                    rootElement.find("[data-action=download-opomin]").on("click", (ev) => {
                        ev.preventDefault();
                        var documentId = parseInt($(ev.currentTarget).attr("data-documentid") as string, 10);
                        var documentName = $(ev.currentTarget).attr("download") as string;
                        var downloadingNotyf = this.notyf.open({
                            type: "info",
                            message: translations.Downloading,
                            duration: 50000
                        });

                        this._apiClient.documentDownload(documentId)
                        .then((fileContent) => {
                            var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                            var url = URL.createObjectURL(blob);

                            var fileName: string = fileContent.fileName;

                            if (!fileName) {
                                fileName = documentName;
                            }

                            var fileLink = document.createElement('a');
                            fileLink.href = url;
                            fileLink.download = fileName;
                            fileLink.click();
                            
                        })
                            .catch(() => {
                                this.notyf.error(translations.ErrorWhileDownloadingFile);
                            }).finally(() => {
                                this.notyf.dismiss(downloadingNotyf);
                            })
                    });
                }
               
            });
         
            await tableWidget.refresh(1, this._translationService.currentTranslations)
                .then(() => {  
                    // ne naredi nic
                })
                .catch((ex) => {
                    if (ex.statusCode == 400) {
                        console.log(ex);
                        $('#main').text(translations[ex.message]);
                    }
                    else {
                        $('#main').text(translations["LoadingError"]);
                    }
                });
        } catch (e) {
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            "imageUrlCalendarSmall": "../img/icon-calendar-small.svg",
            "imageUrlLocationSmall": "../img/icon-location-small.svg",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

    }

    private dataTableModel(translations: any): any {
        const tableMap = {
            title: translations["Notices"] ?? "Zadeva",
            classInsert: "opomini-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["subject1"] ?? "Zadeva",
                    value: "zadeva"
                },
                {
                    name: translations["From"] ?? "Od",
                    value: "posiljatelj"
                },
                {
                    name: translations["Day"] ?? "Dan",
                    value: "datum"
                }
                ,
                {
                    name: "<i class=\"bi bi-download\" style=\"-webkit-text-stroke-width: 1px;\"></i>",
                    html: ` <a href="#" data-action="download-opomin" data-documentid="{{documentId}}" download="{{documentName}}" title="` + translations.DownloadDocument +`">
                                <img src="../img/icon-document.svg" class="img-fluid td-icon" />
                                <span>{{documentType}}</span>
                            </a>`
                }

            ]
        };
        return tableMap;
    }
}