import { injectable } from "inversify";
import * as Mustache from "mustache";
import { HelpClient } from "../ts/clients/help-client";
import { HelpCategory } from "../ts/models/help-category";
import { TranslationService } from "../ts/translation-service";
import template from "./internet-moj-dostop-view.html";
import helpSlidesTemplate from "./internet-help-slides.html";
import { Notyf } from "notyf";
import { PlatformDetector } from "../ts/utilities/platform-detector";
import { HelpApiClient } from "../ts/clients/help-api-client";
import { HelpContact } from "../ts/models/help-contacts";
import { UserApiClient } from "../ts/clients/users-api-client";
import * as ApexCharts from 'apexcharts';
import { CurrentUserInfo } from "../ts/models/current-user-info";
import { UserDetailsTraffic } from "../ts/models/user-details-traffic-response";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";

@injectable()
export class InternetMojDostopView {
    slides: Array<HelpCategory> | null = null;
    constructor(private helpClient: HelpClient,
        private helpApiClient: HelpApiClient,
        private translationService: TranslationService,
        private userApiClient: UserApiClient,
        private currentUserAccessor: CurrentUserAccessor,
        private notyf: Notyf
    ) {

    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            var contacts = await this.helpApiClient.contacts();
            if (contacts && contacts.length > 0) {
                for (var i = 0; i < contacts.length; i++) {
                    if (contacts[i].userSignature && (contacts[i].userSignature?.body == "<p><p>" || contacts[i].userSignature?.body == "" || contacts[i].userSignature?.body == "<p><br></p>")) {
                        contacts[i].userSignature = null;
                    }
                }
            }
            
            var userTraffic: UserDetailsTraffic = await this.userApiClient.currentUserTraffic();
            this.slides = await this.helpClient.getSlides(this.translationService.currentLanguage);
            var currentUserInfo = await this.currentUserAccessor.getUser();
            this._renderData(this.slides, contacts, currentUserInfo, userTraffic);
            this.loadUserPromet(userTraffic);

        } catch (e) {

            // Clear previous content on error
            this.notyf.error(this.translationService.currentTranslations.LoadingError);
            $('#main').text(this.translationService.currentTranslations.LoadingError);
            throw e;
        }
    }

    private _renderData(data: Array<HelpCategory>, contacts: Array<HelpContact>, currentUser: CurrentUserInfo, userTraffic: UserDetailsTraffic): void {
        var translations = this.translationService.currentTranslations;
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlEdit": "../img/icon-edit.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlIconInfo": "../img/icon-info.svg",
            contacts: contacts,
            hasContacts: contacts.length > 0,
            helpCategories: data,            
            translations: this.translationService.currentTranslations,
            platform: {
                os: PlatformDetector.getOS()
            },
            user: currentUser,
            userTraffic: userTraffic
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        $("[data-action=open-help]").on("click", (ev) => {
            ev.preventDefault();
            const category = $(ev.currentTarget).attr("data-category") as string;
            const subCategory = $(ev.currentTarget).attr("data-subcategory") as string;
            var viewModel = this.createHelpModalViewModel(category, subCategory);
            if (!viewModel) {
                this.notyf.error(this.translationService.currentTranslations.CouldNotFoundHelpFile);
                return;
            }
            
            const html = Mustache.render(helpSlidesTemplate, viewModel);
            $("#intranet-help-modal").html(html)
                .find(".modal").modal("show");
        });
        $('#main').tooltip({ selector: '[data-toggle=tooltip]' });

        $("#sendConfirmEmail").on("click", (ev) => {
            ev.preventDefault();
            this.userApiClient.resendVerification()
                .then(() => {
                    this.notyf.success(translations.ResendSuccess);
                })
                .catch(() => {
                    this.notyf.error(translations.ResendError);
                });
        }) 
    }

    private createHelpModalViewModel(categoryName: string, subCategoryName: string): any {
        if (this.slides == null) {
            return null;
        }
        for (var categoryIndex = 0; categoryIndex < this.slides.length; categoryIndex++) {
            var category = this.slides[categoryIndex];
            if (category.name != categoryName) {
                continue;
            }
            for (var subCategoryIndex = 0; subCategoryIndex < category.subCategories.length; subCategoryIndex++) {
                var subCategory = category.subCategories[subCategoryIndex];
                if (subCategory.subCategoryName == subCategoryName) {
                    return {
                        category: category,
                        subCategory: subCategory,
                        translations: this.translationService.currentTranslations                        
                    }
                }
            }
        }

        return null;
    }

    private async loadUserPromet(userTraffic: UserDetailsTraffic): Promise<void> {
        var translations = this.translationService.currentTranslations;
        var chartOptions = {
            chart: {
                width: "100%",
                height: 380,
                type: 'bar',
                toolbar: {
                    tools: {
                        download: false
                    }
                }
            },
            series: [
                {
                    name: translations.Down,
                    data: userTraffic.graf.downloaded
                },
                {
                    name: translations.Up,
                    data: userTraffic.graf.uploaded,

                }
            ],

            xaxis: {
                categories: userTraffic.graf.days
            },

            title: {
                alight: 'left',
                text: translations.UserTrafficForDate + " " + userTraffic.firstDate + " - " + userTraffic.lastDate,

                floating: true,
                offsetX: -10,
                offsetY: 10,
                style: {
                    fontFamily: 'Red Hat Display',
                    fontWeight: '400',
                    fontSize: '15px',
                    colour: 'rgb(33, 37, 41)',

                }
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: ((val: any) => { return val.toFixed(2) + " GB" })
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right',
                offsetY: 10,
                labels: {
                    useSeriesColors: false
                }
            },

            fill: {
                colors: ['#83B846', '#1B4E82']
            },

            grid: {
                show: true,
                borderColor: "#e8e4e4",
                strokeDashArray: 0,
                position: 'back',
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                xaxis: {
                    lines: {
                        show: true
                    }
                },
            },

            dataLabels: {
                enabled: false
            }
        };


        var chart = new ApexCharts($("#internet-promet-graf")[0], chartOptions);
        chart.render();

        var markers = $("#internet-promet-graf").find(".apexcharts-legend-series");
        markers.filter(":nth(0)").addClass("custom-marker-down");
        markers.filter(":nth(1)").addClass("custom-marker-up");
        markers.find(".apexcharts-legend-marker").css("background", "");
    }
    
}