import { inject, injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import bodyTemplate from "./kupon-modal.html";
import "jquery-validation-unobtrusive";
import { CouponsApiClient } from "../ts/clients/coupons-api-client";
import { FullscreenLoader } from "./fullscreen-loader";

@injectable()
export class KuponModal {

    constructor(@inject("TranslationService") private translationService: TranslationService,
        @inject("CouponsApiClient") private apiClient: CouponsApiClient,
        @inject("FullscreenLoader") private loader: FullscreenLoader) {
    }

    private async ensureModal(): Promise<JQuery<HTMLElement>> {
        this.loader.show();
        var modalElement = $("body #kuponModal");
        var modalElementExist = modalElement.length > 0;
        if (!modalElementExist) {

            var data = await this.apiClient.getActiveCampaignDate();
            var validDate = "";
            if (data && data.valid) {
                validDate = data.valid;
            }

            var fileContent = await this.apiClient.getCoupon();
            var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
            var objectURL = URL.createObjectURL(blob);

            var modalHtml = Mustache.render(bodyTemplate, {
                translations: this.translationService.currentTranslations,
                couponUrl: objectURL,
                validDate: validDate
            });

            modalElement = $(modalHtml);
            $("body").append(modalElement);

            $(".kupon-download-btn").on('click', async (ev) => {
                ev.preventDefault();
                await this.apiClient.getCoupon()
                    .then((fileContent) => {
                        var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                        var objectURL = URL.createObjectURL(blob);

                        var fileLink = document.createElement('a');
                        fileLink.href = objectURL;
                        fileLink.download = "kupon.jpg";
                        fileLink.click();
                    })
                    .catch((ex) => {
                        $('#main').text(this.translationService.currentTranslations[ex.message]);
                    });
            });
        }

        modalElement.modal("show");     
        this.loader.hide();
        return modalElement;
    }     

    public show() {
        this.ensureModal();
    }

    public hide() {
        var modalElement = $("body #email-modal");
        if (modalElement.length == 0) {
            return false;
        }
        modalElement.modal("hide");
    }
}