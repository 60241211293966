export class PlatformDetector {
    protected static os_array: { [Key: string]: string } = {
        'windows nt 10.0': 'Windows 10/11',
        'windows nt 6.3': 'Windows 8.1',
        'windows nt 6.2': 'Windows 8',
        'windows nt 6.1': 'Windows 7',
        'windows nt 6.0': 'Windows Vista',
        'windows nt 5.2': 'Windows Server 2003/XP x64',
        'windows nt 5.1': 'Windows XP',
        'windows xp': 'Windows XP',
        'windows nt 5.0': 'Windows 2000',
        'windows me': 'Windows ME',
        'win98': 'Windows 98',
        'win95': 'Windows 95',
        'win16': 'Windows 3.11',
        'macintosh|mac os x': 'Mac OS X',
        'mac_powerpc': 'Mac OS 9',
        'linux': 'Linux',
        'ubuntu': 'Ubuntu',
        'phone': 'iPhone',
        'pod': 'iPod',
        'pad': 'iPad',
        'android': 'Android',
        'blackberry': 'BlackBerry',
        'webos': 'Mobile'
    };
    protected static browser_array: { [Key: string]: string } = {
        'msie': 'Internet Explorer',
        'firefox': 'Firefox',
        'safari': 'Safari',
        'chrome': 'Chrome',
        'opera': 'Opera',
        'netscape': 'Netscape',
        'maxthon': 'Maxthon',
        'konqueror': 'Konqueror',
        'mobile': 'Handheld Browser'
    };

    public static getOS(): string | null {
        var user_agent = navigator.userAgent.toLowerCase();
        for (var regex in PlatformDetector.os_array) {
            if (user_agent.indexOf(regex) >= 0) {
                return PlatformDetector.os_array[regex];
            }
        }
        return null;
    };
    public static getBrowser(): string | null {
        var user_agent = navigator.userAgent.toLowerCase();

        for (var regex in PlatformDetector.browser_array) {
            if (user_agent.indexOf(regex) >= 0) {
                return PlatformDetector.browser_array[regex];
            }
        }

        return null;
    };
}
