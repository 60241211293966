import * as moment from "moment";
import { UserSearchViewModel } from "../sport-subscription-viewmodel";

export  interface classroomReservationsModel {
    classroomReservationTypeId: string;
    type: number,
    id: number
    classRoomUsers:Array<UserSearchViewModel>
}

export  interface classroomReservationModel {
    classroomReservationTypeId: string;
    type: number,
    id: number
    description: string,
    isPrivate: boolean,
    estimatedTimeFrom?: string,
    estimatedTimeTo?: string,
    classRoomUsers:Array<UserSearchViewModel>
}

export function parseReservation(reservation: classroomReservationModel): classroomReservationModel {
    return {
        ...reservation,
        estimatedTimeFrom: reservation.estimatedTimeFrom ?  moment.utc(reservation.estimatedTimeFrom).local().format("HH:mm"): undefined,
        estimatedTimeTo: reservation.estimatedTimeTo ? moment.utc(reservation.estimatedTimeTo).local().format("HH:mm"): undefined,
    };
}
