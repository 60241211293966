import { parse } from "content-disposition-header";
import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { DownloadedFile } from "../models/downloaded-file";
//import { FitnessCard } from "../models/fitness-card";
import { FitnessCardResponse } from "../models/fitness-card-details-response";
import { FitnessCardModel } from "../models/fitness-card-model";
import { ListResponse } from "../models/list-response";
import { SportEventResponse } from "../models/sport-event-response";
import { SportEventViewModel } from "../models/sport-event-viewmodel";
import { EventModel, SportListViewModel } from "../models/sport-list-viewmodel";
import { SportOptionsResponse } from "../models/sport-options-response";
import { SportOrderAttend } from "../models/sport-order-attend";
import { SportPackageAddModel } from "../models/sport-package-add-model";
import { SportPackageResponse } from "../models/sport-package-response";
import { SportPackageViewModel } from "../models/sport-package-viewmodel";
import { UserSearchViewModel, SportSubscriptionViewModel } from "../models/sport-subscription-viewmodel";

@injectable()
export class SportEventApiClient extends ApiClientBase {
    public name = "SportEventApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }
    //dummy
    public getList(x?: any, y?: any): Promise<any> {
        if (x === y) x = y;
        return "dummy" as any;
    }

    public async isSportAdmin(): Promise<boolean> {
        return await this._callApi('/sport/isSportAdmin', 'GET') as boolean;
    }

    //returns all subscriptions
    public async getSubscriptions(): Promise<Array<SportSubscriptionViewModel>> {
        return await this._callApi('/sport/subscription', 'GET') as Array<SportSubscriptionViewModel>;
    }

    //returns subscriptions from logged user
    public async getSubscriptionsWhereUser(): Promise<Array<SportSubscriptionViewModel>> {
        return await this._callApi('/sport/subscription/subscribed', 'GET') as Array<SportSubscriptionViewModel>;
    }

    //returns subscription by id
    public async getSubscription(id: number): Promise<SportSubscriptionViewModel> {
        return await this._callApi('/sport/subscription/' + id, 'GET') as SportSubscriptionViewModel;
    }

    //create subscription
    public async createSubscription(data: FormData): Promise<any> {
        return await this._callApi('/sport/subscription/add', 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    //update subscription
    public async updateSubscription(data: any): Promise<any> {
        return await this._callApi('/sport/subscription/put', 'PUT', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    //delete subscription
    public async deleteSubscription(id: number): Promise<any> {
        return await this._callApi('/sport/subscription/delete/' + id, 'DELETE', null, true, "") as any;
    }

    public async userSearchOptions(prepareRequest: (request: JQueryAjaxSettings) => void): Promise<JQuery.AjaxSettings> {
        return await this._createAjaxOptionsWithToken('/sport/search-user', 'GET', "", false, "json", undefined, undefined, prepareRequest);
    }

    public async getOptions(): Promise<SportOptionsResponse> {
        return await this._callApi('/sport/options', 'GET') as SportOptionsResponse;
    }

    //return subscribers for subscription
    public async getSubscriptionSubscribers(id: number, search: string): Promise<Array<UserSearchViewModel>> {
        var url = '/sport/subscription/subscribers/' + id;
        if (search) {
            url += "?search=" + encodeURIComponent(search);
        }
        return await this._callApi(url, 'GET') as Array<UserSearchViewModel>;
    }

    //return subscribers for package
    public async getPackageSubscribers(id: number, search: string): Promise<Array<UserSearchViewModel>> {
        var url = '/sport/package/subscribers/' + id;
        if (search) {
            url += "?search=" + encodeURIComponent(search);
        }
        return await this._callApi(url, 'GET') as Array<UserSearchViewModel>;
    }

    //delete subscription
    public async deleteSubscriber(id: number, userId: number): Promise<any> {
        return await this._callApi('/sport/subscription/deleteSubscriber/' + id + "/" + userId, 'DELETE', null, true, "") as any;
    }

    //returns packages and events
    public async getSportList(query: string): Promise<SportListViewModel> {
        var url = "/sport/list";
        if (query) {
            url += "?query=" + query;
        }
        return await this._callApi(url, 'GET') as SportListViewModel;
    }

    //returns package by id
    public async getPackage(id: number): Promise<SportPackageViewModel> {
        return await this._callApi('/sport/package/' + id, 'GET') as SportPackageViewModel;
    }

    //Add package
    public async addPackage(data: SportPackageAddModel): Promise<any> {
        return await this._callApi('/sport/package', 'POST', JSON.stringify(data), true, "") as any;
    }

    //delete package
    public async deletePackage(id: number): Promise<any> {
        return await this._callApi('/sport/package/' + id, 'DELETE', null, true, "") as any;
    }

    //Edit package
    public async editPackage(data: SportPackageAddModel): Promise<any> {
        return await this._callApi('/sport/package', 'PUT', JSON.stringify(data), true, "") as any;
    }

    //package toggle publish
    public async packageTogglePublish(id: number): Promise<any> {
        await this._callApi(`/sport/package/${id}/togglepublish`, 'PUT').catch(err => console.log(err));
    }

    //returns packages of given month
    public async getPackagesThisMonth(month: number): Promise<Array<SportPackageResponse>> {
        const data: JSON = <JSON><unknown>{
            "month": month
        };
        return await this._callApi('/sport/packages/list', 'GET', data) as Array<SportPackageResponse>;
    }

    //returns event by id
    public async getEvent(id: number): Promise<SportEventViewModel> {
        return await this._callApi('/sport/event/' + id, 'GET') as SportEventViewModel;
    }

    //if month is given returns all events for month / if week is true, return users events for this week / if week is true month is irrelevant
    public async getEvents(month?: number, week?: boolean): Promise<Array<SportEventResponse>> {
        const data: JSON = <JSON><unknown>{
            "month": month,
            "week": week
        };
        return await this._callApi('/sport/events/list', 'GET', data) as Array<SportEventResponse>;
    }

    //return subscribers for event
    public async getEventSubscribers(id: number, search: string): Promise<Array<UserSearchViewModel>> {
        var url = '/sport/event/subscribers/' + id;
        if (search) {
            url += "?search=" + encodeURIComponent(search);
        }
        return await this._callApi(url, 'GET') as Array<UserSearchViewModel>;
    }

    //returns subscribed events for current week
    public async getMyEventsThisWeek(): Promise<Array<EventModel>> {
        return await this._callApi('/sport/events/myEventsThisWeek', 'GET') as Array<EventModel>;
    }

    //create event
    public async createEvent(data: FormData): Promise<any> {
        return await this._callApi('/sport/event/add', 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    //delete event
    public async deleteEvent(id: number): Promise<any> {
        return await this._callApi('/sport/event/delete/' + id, 'DELETE', null, true, "") as any;
    }

    //update event
    public async updateEvent(data: any): Promise<any> {
        return await this._callApi('/sport/event/update', 'PUT', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    //add event repetitions
    public async eventAddRepetitions(data: any): Promise<any> {
        return await this._callApi('/sport/event/addRepetitions', 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    //event toggle publish
    public async eventTogglePublish(id: number): Promise<any> {
        await this._callApi(`/sport/event/${id}/togglepublish`, 'PUT').catch(err => console.log(err));
    }

    //add an order with event of given ID
    public async eventAddOrder(id:number): Promise<any> {
        await this._callApi(`/sport/event/${id}/order/add`, 'POST').catch(err => console.log(err));
    }

    //set an event attend
    public async eventSetAttend(data: Array<SportOrderAttend>): Promise<any> {
        return await this._callApi('/sport/event/set-attend', 'POST', JSON.stringify(data), true, "") as any;
    }

    //delete an order with event of given ID
    public async eventDeleteOrder(id: number): Promise<any> {
        await this._callApi(`/sport/event/${id}/order/delete`, 'DELETE').catch(err => console.log(err));
    }

    //add an order with package of given ID
    public async packetAddOrder(id: number): Promise<any> {
        await this._callApi(`/sport/package/${id}/order/add`, 'POST').catch(err=>console.log(err));
    }

    //delete an order with package of given ID
    public async packetRemoveOrder(id: number): Promise<any> {
        await this._callApi(`/sport/package/${id}/order/cancel`, 'DELETE').catch(err => console.log(err));
    }

    //delete subscription
    public async deletePackageSubscriber(id: number, userId: number): Promise<any> {
        return await this._callApi('/sport/package/deleteSubscriber/' + id + "/" + userId, 'DELETE', null, true, "") as any;
    }

    //subscribe to subscription with id
    public async subscriptionSubscribe(id: number): Promise<any> {
        return await this._callApi(`/sport/subscription/${id}/subscribe`, 'POST', null, true) as any;
    }

    //unsubscribe from subscription with id
    public async subscriptionUnsubscribe(id: number): Promise<any> {
        return await this._callApi(`/sport/subscription/${id}/subscribe/delete`, 'DELETE', null, true) as any;
    }

    public async fitnessCard(): Promise<FitnessCardResponse> {
        return await this._callApi(`/sport/card/current`, 'GET') as FitnessCardResponse;
    }

    public async getFitnessCard(id: number): Promise<FitnessCardModel> {
        return await this._callApi(`/sport/card/${id}`, 'GET') as FitnessCardModel;
    }

    public async fitnessCardsIds(): Promise<any> {
        return await this._callApi(`/sport/card/allIds`, 'GET') as any;
    }

    public async fitnessCardsCurrentPageIds(page?: number, limit?: number, searchTerm?: string): Promise<any> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit,
            "search": searchTerm
        };
        return await this._callApi(`/sport/card/currentPageIds`, 'GET', data) as any;
    }

    public async getFitnessCardsAdminLst(page?: number, limit?: number, searchTerm?: string): Promise<ListResponse<any>> {
        const data = {
            "page": page,
            "limit": limit,
            "search": searchTerm
        };
        return await this._callApi('/sport/card/admin', 'GET', data) as ListResponse<any>;
    }
    public async fitnessCardReject(id: number): Promise<void> {
        await this._callApi(`/sport/card/admin/${id}/reject`, 'POST', null, true, "");
    }
    public async fitnessCardAccept(id: number): Promise<void> {
        await this._callApi(`/sport/card/admin/${id}/accept`, 'POST', null, true, "");
    }
    public async fitnessCardCharge(id: number): Promise<void> {
        await this._callApi(`/sport/card/admin/${id}/charge`, 'POST', null, true, "");
    }
    public async fitnessCardDetails(id: number): Promise<FitnessCardResponse> {
        return await this._callApi(`/sport/card/admin/${id}`, 'GET') as FitnessCardResponse;
    }
    public async fitnessCardCreateLog(id: number, message: string): Promise<void> {
        await this._callApi(`/sport/card/admin/${id}/log`, 'POST', JSON.stringify( message ), true, "");
    }
    public async UpdateImageFitnessCard(data: FormData): Promise<any> {
        return await this._callApi('/sport/card', 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    //notification
    public async create(request: FormData): Promise<void> {
        return await this._callApi('/notification/add/create', 'POST', request, true, "", null, null, (settings) => {
            settings.contentType = false;
            settings.processData = false;
        });
    }

    //download fitness cards
    public async fitnessCardDownload(ids: Array<number>): Promise<DownloadedFile> {
        var file: DownloadedFile = null as any;
        var data: JSON = <JSON><unknown>{
            "idsString": ids.join(',')
        };
        await this._callApi("/sport/card/download", 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });
        return file;
    }

    public async getCardsForExport(): Promise<any> {
        return await this._callApi(`/sport/card/getCardsForExport`, 'GET') as any;
    }

    public async getFitnessImage(id: number, subscriptionId: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/sport/getFitnessImage/' + id + "/" + subscriptionId, 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }

    public async getUserFitnessImage(id: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/sport/getUserFitnessImage/' + id, 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }

    //download
    public async subscriptionDownload(vlogaId: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/sport/' + vlogaId + "/subscription/download", 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }

    public async paketiDownload(vlogaId: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/sport/' + vlogaId + "/paket/download", 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }

    public async dogodkiDownload(vlogaId: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/sport/' + vlogaId + "/dogodek/download", 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }

    //download
    public async attachmentDownload(id: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/sport/attachment/download/' + id, 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }
}