import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { ObvestilaDodajNovoView } from "../../views/obvestila-dodaj-novo-view";
import { NotificationApiClient } from "../clients/notifications-api-client";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";

@injectable()
export class ObvestilaDodajNovoPage extends PageBase {
    public name = "ObvestilaDodajNovoPage";
    private _apiClient: NotificationApiClient;
    private _translationService: TranslationService;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _obvestilaDodajNovoView: ObvestilaDodajNovoView;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("NotificationApiClient") apiClient: NotificationApiClient,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") notyf: Notyf
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._apiClient = apiClient;
        this._translationService = translationService;
        this._router = router;
        this._notyf = notyf;
        this._obvestilaDodajNovoView = new ObvestilaDodajNovoView(this._apiClient, this._translationService, this._notyf);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/obvestila/dodaj")) {
            return true;
        }

        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = translations.Notifications + " - <span class=\"purple\">" + translations.AddNotification + "</span>";
            mainLayout.htmlTitle = translations.Notifications + " - " + translations.AddNotification;
            mainLayout.headerUserViewShow();
            mainLayout.profileSidebarViewHide();
            await this._obvestilaDodajNovoView.load();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}