import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { UserApiClient } from "../ts/clients/users-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./ponastavitev-gesla-view.html";
import obrazecTemplate from "./ponastavitev-gesla-obrazec-view.html";
import "jquery-validation-unobtrusive";
import { FullscreenLoader } from "./fullscreen-loader";

@injectable()
export class PonastavitevGeslaView {
    private _apiClient: UserApiClient;
    private _translationService: TranslationService;

    public constructor(userApiClient: UserApiClient, translationService: TranslationService, private _notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = userApiClient;
        this._translationService = translationService;
    }

    public async load(hash: string | null): Promise<void> {
        $(document.body).removeClass("body-content").addClass("body-login");

        try {
            // Try to get data
            if (hash) {
                //naloži obrazec za vpis novega gesla
                await this._inizializeChangeForm(hash);
            }
            else {

                //naloži prikaz z sporočilom o preverbi
                await this._inizializeStartForm();
            }

        } catch (e) {

            
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async _inizializeStartForm(): Promise<void> {

        var translations = this._translationService.currentTranslations;

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogo": "../img/studentski-dom-ljubljana.png",
            translations: translations
        } as any;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(template, viewModel);
        $('#main').html(html);
        var formElement = $("#main").find("#password-reset-start-form");
        var successElement = $("#main").find("#reset-password-start-success");
        successElement.hide();

        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", (ev)=>{
            ev.preventDefault();
            if (!formElement.valid()) {
                this._notyf.error(translations.CheckAllFields);
                return;
            }
            this.loader.show();
            var username = formElement.find("[name=username]").val() as string;
            this._apiClient.passwordReset(username)
                .then(() => {
                    formElement.hide();
                    successElement.show();
                    this.loader.hide();
                })
                .catch((data) => {
                    if (data.statusCode == 400) {
                        var errorMessage = data.message;
                        if (errorMessage) {
                            this._notyf.error(errorMessage);
                        }
                        else {
                            this._notyf.error(translations.SomethingIsWrongWithYourUsernameOrEmail);
                        }
                    }
                    else {
                        console.log(data);
                        this._notyf.error(translations.UnexpectedError);
                    }
                    this.loader.hide();

                });
        });
    }

    private async _inizializeChangeForm(hash: string): Promise<void> {

        var isHashValid: boolean = true;
        try {
            await this._apiClient.passwordResetCheck(hash);
            isHashValid = true;
        }
        catch (exc) {
            isHashValid = false;
        }
        var translations = this._translationService.currentTranslations;

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogo": "../img/studentski-dom-ljubljana.png",
            translations: translations,
            isHashValid
        } as any;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(obrazecTemplate, viewModel);
        $('#main').html(html);
        var formElement = $("#main").find("#reset-password-change-form");
        var successElement = $("#main").find("#reset-password-change-success");
        var invalidHashElement = $("#main").find("#reset-password-change-invalid");
        successElement.hide();
        if (isHashValid) {
            formElement.show();
            invalidHashElement.hide();
        }
        else {
            formElement.hide();
            invalidHashElement.show();
        }

        if (isHashValid) {

            $.validator.unobtrusive.parse(formElement);
            formElement.validate();
            formElement.on("submit", (ev) => {
                ev.preventDefault();
               
                if (!formElement.valid()) {
                    this._notyf.error(translations.CheckAllFields);
                    return;
                }
                this.loader.show();
                var newPassword = formElement.find("[name=new-password]").val() as string;
                var repeatPassword = formElement.find("[name=repeat-password]").val() as string;
                if (newPassword != repeatPassword) {
                    this._notyf.error(translations.NewPasswordAndNewRepeatedPasswordAreNotTheSame);
                    return;

                }
                this._apiClient.passwordResetChange(hash, newPassword)
                    .then(() => {
                        formElement.hide();
                        successElement.show();
                        this.loader.hide();
                    })
                    .catch((data) => {
                        if (data.statusCode == 400) {
                            var errorMessage = data.message;
                            if (errorMessage) {
                                this._notyf.error(errorMessage);
                            }
                            else {
                                this._notyf.error(translations.UnexpectedError);
                            }
                        }
                        else {
                            console.log(data);
                            this._notyf.error(translations.UnexpectedError);
                        }
                        this.loader.hide();

                    });
            });
        }


    }
}