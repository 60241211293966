import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import template from "./selitve-urejanje-view.html";
import { TranslationService } from "../ts/translation-service";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";
import { SelitveApiClient } from "../ts/clients/selitve-api-client";
import * as DateRangePicker from 'daterangepicker';
import "select2";
import { OptionData } from "select2";
import { RelocateDataResponse } from "../ts/models/relocate-data-response";
import Quill from "quill";

@injectable()
export class SelitveUrejanjeView {
    private _apiClient: SelitveApiClient;
    private _translationService: TranslationService;
    private _fromPicker: DateRangePicker = null!;
    private _toPicker: DateRangePicker = null!;
    private _visibleLocations: any | null;
    private _availableLocations: any | null;
    private _relocateData: RelocateDataResponse | null;
    private _instructionsEditorQuillElement: Quill | null;
    private _instructionsEnEditorQuillElement: Quill | null;
    private _instructionsEditorElement: HTMLElement | null;
    private _instructionsEnEditorElement: HTMLElement | null;

    public constructor(apiClient: SelitveApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = apiClient;
        this._translationService = translationService;
        this._relocateData = null;
        this._instructionsEditorQuillElement = null;
        this._instructionsEnEditorQuillElement = null;
        this._instructionsEditorElement = null;
        this._instructionsEnEditorElement = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {

            this._visibleLocations = await this._apiClient.getLocations();
            this._availableLocations = await this._apiClient.getLocations();
            this._relocateData = await this._apiClient.getRelocateData();

            // Render new content
            this._renderData();    
            this._setData();

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageCircleAccept": "../img/icon-circle-accept.svg",
            "imageCircleDecline": "../img/icon-circle-decline.svg",
            "formators": this.getFormators(),
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            translations: this._translationService.currentTranslations,
            "allVisibleLocations": this._visibleLocations.allLocations,
            "allAvailableLocations": this._availableLocations.allLocations,
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        this._initForm();

        var instructionsQillBody = this._relocateData == undefined ? "" : this._relocateData.instructions;
        var instructionsEnQillBody = this._relocateData == undefined ? "" : this._relocateData.instructionsEn;
        this._initInstructionsQuillEditor(instructionsQillBody, false);
        this._initInstructionsEnQuillEditor(instructionsEnQillBody, false);
    }

    private _setData(): void {
        var visibleLocations = [];
        if (this._relocateData && this._relocateData.visibleLocations) {
            for (var i = 0; i < this._relocateData.visibleLocations.length; i++) {
                visibleLocations.push(this._relocateData.visibleLocations[i].toString());
            }
        }
        var visibleLocationInput = $("#relocate-edit-form").find("[name=visibleLocations]");
        visibleLocationInput.val(visibleLocations);
        visibleLocationInput.trigger('change');

        var availableLocations = [];
        if (this._relocateData && this._relocateData.availableLocations) {
            for (var i = 0; i < this._relocateData.availableLocations.length; i++) {
                availableLocations.push(this._relocateData.availableLocations[i].toString());
            }
        }
        var availableLocationInput = $("#relocate-edit-form").find("[name=availableLocations]");
        availableLocationInput.val(availableLocations);
        availableLocationInput.trigger('change');
    }

    private isFormValid(): boolean {
        var valid = true;

        if (!$("#validFrom").val() ||
            !$("#validTo").val() ||
            !this._instructionsEditorQuillElement?.root.innerHTML) {
            valid = false;
        }

        var visibleLocations = $("[name=visibleLocations]").select2('data');
        if (!visibleLocations || visibleLocations.length < 1) {
            valid = false;
        }

        var availableLocations = $("[name=availableLocations]").select2('data');
        if (!availableLocations || availableLocations.length < 1) {
            valid = false;
        }

        return valid;
    }

    private _addArrayToForm(formData: FormData, fieldName: string, array: Array<any> | null): void {
        if (array == null) {
            return;
        }
        for (var i = 0; i < array.length; i++) {
            //formData.append(fieldName, array[i].toString());
            formData.append(fieldName, JSON.stringify(array[i]));
        }
    }

    private _parseSelect2DataAsInt(selected: OptionData[]): Array<number> {
        return selected.filter((val) => {
            return val.selected;
        }).map((val) => {
            return parseInt(val.id, 10);
        });
    }

    public _initInstructionsQuillEditor(body: string, readonly: boolean) {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._instructionsEditorElement = mainElement.find(".instructions-editor-container-sl")[0];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._instructionsEditorQuillElement = new Quill(this._instructionsEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",
            readOnly: readonly
        });

        this._instructionsEditorQuillElement.root.innerHTML = body;
    }

    public _initInstructionsEnQuillEditor(body: string, readonly: boolean) {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._instructionsEnEditorElement = mainElement.find(".instructions-editor-container-en")[0];
        /*console.debug("placeholder", this._messageEditorElement);*/
        this._instructionsEnEditorQuillElement = new Quill(this._instructionsEnEditorElement, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",
            readOnly: readonly
        });

        this._instructionsEnEditorQuillElement.root.innerHTML = body;
    }

    //inicializira obrazec
    private _initForm(): void {
        var self = this;
        var translations = this._translationService.currentTranslations;
        var formElement = $("#relocate-edit-form");

        var visibleLocationInput = $("#relocate-edit-form").find("[name=visibleLocations]");
        visibleLocationInput.select2();

        var availableLocationInput = $("#relocate-edit-form").find("[name=availableLocations]");
        availableLocationInput.select2();

        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';

        $.validator.unobtrusive.parse(formElement);

        formElement.on("submit", async (ev) => {
            ev.preventDefault();

            if (!this.isFormValid()) {
                return;
            }

            this.loader.show();

            var formData = new FormData();
            formData.append("validFrom", (this._fromPicker.startDate.isValid() ? this._fromPicker.startDate.format() : undefined) as string);
            formData.append("validTo", (this._toPicker.startDate.isValid() ? this._toPicker.startDate.format() : undefined) as string);
            this._addArrayToForm(formData, "visibleLocations", this._parseSelect2DataAsInt($("[name=visibleLocations]").select2('data')));
            this._addArrayToForm(formData, "availableLocations", this._parseSelect2DataAsInt($("[name=availableLocations]").select2('data')));
            formData.append("instructions", this._instructionsEditorQuillElement?.root.innerHTML as string);
            formData.append("instructionsEn", this._instructionsEnEditorQuillElement?.root.innerHTML as string);

            await this._apiClient.editRelocate(formData).then(
                function (data: any) {
                    self.loader.hide();
                    if (data && data.success) {
                        self.notyf.success(translations.RelocateSaved);
                        window.location.href = "/#/selitve";
                    }
                    else if (data && data.message) {
                        self.notyf.error(data.message);
                    }
                    else {
                        self.notyf.error(translations.SaveError);
                    }
                },
                function (reason: any) {
                    self.loader.hide();
                    console.debug(reason);
                    self.notyf.error(translations.SaveError);
                }
            ).catch((ex) => {
                self.loader.hide();
                if (ex.statusCode == 400) {
                    $('#main').text(translations[ex.message]);
                }
            });
        });

        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("LLL");
        this._fromPicker = new DateRangePicker($('#main').find("#validFrom")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years'),
            startDate: new Date(this._relocateData == null ? new Date() : this._relocateData.validFrom),
            locale: {
                format: localeFormat
            },
        });
        this._toPicker = new DateRangePicker($('#main').find("#validTo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years'),
            startDate: new Date(this._relocateData == null ? new Date() : this._relocateData.validTo),
            locale: {
                format: localeFormat
            },
        });

        $("#allVisibleLocations").on("change", () => {
            if ($("#allVisibleLocations").prop("checked") == true) {
                var allItems = [];
                for (var i = 0; i < this._visibleLocations.allLocations.length; i++) {
                    var group = this._visibleLocations.allLocations[i];
                    for (var j = 0; j < group.locations.length; j++) {
                        allItems.push(group.locations[j].id.toString());
                    }
                }
                visibleLocationInput.val(allItems);
                visibleLocationInput.trigger('change');
            }
            else {
                visibleLocationInput.val([]);
                visibleLocationInput.trigger('change');
            }
        });

        $("#allAvailableLocations").on("change", () => {
            if ($("#allAvailableLocations").prop("checked") == true) {
                var allItems = [];
                for (var i = 0; i < this._availableLocations.allLocations.length; i++) {
                    var group = this._availableLocations.allLocations[i];
                    for (var j = 0; j < group.locations.length; j++) {
                        allItems.push(group.locations[j].id.toString());
                    }
                }
                availableLocationInput.val(allItems);
                availableLocationInput.trigger('change');
            }
            else {
                availableLocationInput.val([]);
                availableLocationInput.trigger('change');
            }
        });
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
            dateTimeFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat + ' HH:mm');
                };
            },
            timeRange: function () {
                return function (timestamp: any, render: any) { //3. April (19:30 - 20:15)
                    var timeString: string[] = render(timestamp).split(" ");
                    var time: Date[] = [];
                    time[0] = new Date(timeString[0]);
                    time[1] = new Date(timeString[1]);
                    if (time[0].getDate() == time[1].getDate() && time[0].getMonth() == time[1].getMonth()) {
                        return moment((time[0])).format('DD. MMM (HH:mm') + " - " + moment((time[1])).format('HH:mm)')
                    } else {
                        return moment((time[0])).format('DD. MMM (HH:mm)') + " - " + moment((time[1])).format('DD. MMM (HH:mm)')
                    }
                };
            }
        };
    }
}