import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./sport-narocnina-view.html";
import detailsModalTemplate from "./sport-narocnina-details-modal-view.html";
import { SportSubscriptionViewModel } from "../ts/models/sport-subscription-viewmodel";
import { Notyf } from "notyf";
import { ModalConfirm } from "./modal-confirm";

@injectable()
export class SportNarocninaView {
    private _apiClient: SportEventApiClient;
    private _translationService: TranslationService;

    private mySubscriptions: Array<SportSubscriptionViewModel>;
    private allSubscriptions: Array<SportSubscriptionViewModel>;
    private isAdmin: boolean;

    public constructor(sportEventApiClient: SportEventApiClient, translationService: TranslationService, private notyf: Notyf) {
        this._apiClient = sportEventApiClient; 
        this._translationService = translationService;
        this.mySubscriptions = new Array <SportSubscriptionViewModel>;
        this.allSubscriptions = new Array<SportSubscriptionViewModel>;
        this.isAdmin = false;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            // Try to get data
            //TODO: prebrati podatke
            this.mySubscriptions = await this._apiClient.getSubscriptionsWhereUser();
            this.allSubscriptions = await this._apiClient.getSubscriptions();
            this.isAdmin = await this._apiClient.isSportAdmin();
            // Render new content
            this._renderData();
            this.initActionButtons();
            this.initModalButton();
            this.initSubscribeButton();
            this.initUnsubscribeButton();
        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
            dateTimeFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat + ' hh:mm');
                };
            },
            timeRange: function () {
                return function (timestamp: any, render: any) { //3. April (19:30 - 20:15)
                    var timeString: string[] = render(timestamp).split(" ");
                    var time: Date[] = [];
                    time[0] = new Date(timeString[0]);
                    time[1] = new Date(timeString[1]);
                    if (time[0].getDate() == time[1].getDate() && time[0].getMonth() == time[1].getMonth()) {
                        return moment((time[0])).format('DD. MMM (hh:mm') + " - " + moment((time[1])).format('hh:mm')
                    } else {
                        return moment((time[0])).format('DD. MMM (hh:mm)') + " - " + moment((time[1])).format('DD. MMM (hh:mm)')
                    }
                };
            }
        };
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDownload": "../img/icon-download.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            "imageUrlCalendarSmall": "../img/icon-calendar-small.svg",
            "imageUrlLocationSmall": "../img/icon-location-small.svg",
            "subscriptions": this.mySubscriptions,
            "allSubscriptions": this.allSubscriptions,
            "formators": formators,
            "admin": this.isAdmin,
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }

    private _renderDetailsModal(subscription: any): void {

        // Build a view model from the API data
        const viewModel = {
            data: subscription,
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = detailsModalTemplate;
        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#subscription-details-modal').html(html);
    }

    private initActionButtons() {
        var rootElement = $("#main").find(".subscriptions-root-element");
        var self = this;
        var translations = this._translationService.currentTranslations;
        rootElement.find(".popover-edit-subscriptions").each((_index,element) => {
            var id = element.getAttribute("data-id");
            $(element).popover({
                container: rootElement[0],
                //content: '<ul><li><a href="/#/sport/narocnina/' + id + '" class="edit">' + translations.Edit + '</a></li><li><a href="/#/sport/dnevnik-aktivnosti" class="logs">' + translations.SportActivityLog + '</a></li></ul>',
                content: '<ul><li><a href="/#/sport/narocnina/' + id + '" class="edit">' + translations.Edit + '</a></li><li><a href="/#/sport/dnevnik-aktivnosti" class="logs">' + translations.SportActivityLog + '</a></li><li><a tabindex="0" href="javascript:void(0);" class="delete" data-action="delete" data-id="' + id + '">' + translations.Delete + '</a></li></ul>',
                html: true,
                sanitize: false,
                trigger: "focus",
                delay: {
                    show: 0,
                    hide: 200
                }
            });
        });
        
        rootElement.on("click", "[data-action=delete]", async (ev) => {
            ev.preventDefault();
            
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: translations.ConfirmDeleteContent,
                confirmType: 'brisanje'
            }).then(() => {
                this._apiClient.deleteSubscription(id).then(async () => {
                    await self.load();
                })
                    .catch(() => {
                        this.notyf.error(translations.DeleteError);
                    });
            }).catch(() => {
                console.log("delete canceled:" + id);
            });
        });
    }

    public initModalButton() {
        var self = this;
        $(".subscription-details-btn").on("click", function (ev) {
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);

            var subscription = self.allSubscriptions.find((el) => el.id == id);
            if (!subscription) {
                return;
            }

            self._renderDetailsModal(subscription);
            $("#subscription-details-modal").modal("show");
        });
    }

    public initSubscribeButton() {
        var self = this;
        var translations = this._translationService.currentTranslations;
        $(".subscription-subscribe").on("click", function (ev) {
            ev.preventDefault();
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);
            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: translations.SubscribeConfirm,
                confirmType: 'splosno'
            }).then(() => {
                self._apiClient.subscriptionSubscribe(id).then(
                    async function (data: any) {
                        if (data && data.code == 101) {
                            self.notyf.error(self._translationService.currentTranslations.NoFitnessCard);
                            return;
                        }
                        else if (data && data.success) {
                            console.debug(data);
                            self.notyf.success(self._translationService.currentTranslations.SubscribeSuccess);
                            await self.load();
                        }                      
                    },
                    function (reason: any) {
                        console.debug(reason);
                        self.notyf.error(self._translationService.currentTranslations.Error);
                    });
            }).catch(() => {
            });           
        });
    }

    public initUnsubscribeButton() {
        var self = this;
        var translations = this._translationService.currentTranslations;


        $(".subscription-unsubscribe").on("click", function (ev) {
            ev.preventDefault();
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);

            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: translations.UnsubscribeConfirm,
                confirmType: 'brisanje'
            }).then(() => {
                self._apiClient.subscriptionUnsubscribe(id).then(
                    async function (data: any) {
                        if (data && data.success) {
                            console.debug(data);
                            self.notyf.success(self._translationService.currentTranslations.UnsubscribeSuccess);
                            await self.load();
                        }
                        
                    },
                    function (reason: any) {
                        console.debug(reason);
                        self.notyf.error(self._translationService.currentTranslations.Error);
                    });
            }).catch(() => {
            }); 

            
        });
    }
}