import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { SportEventApiClient } from "../ts/clients/sport-event-api-client";
import template from "./sport-view.html";
import packetsTemplate from "./sport-paketi-mesec.html";
import subscriptionDetailsModalTemplate from "./sport-narocnina-details-modal-view.html";
import eventDetailsModalTemplate from "./sport-dogodek-details-modal-view.html";
import paketUrediModalTemplate from "./sport-paket-uredi-modal-view.html";
import dogodkiPaketaTemplate from "./widget-dogodki-paketa.html";

import { TranslationService } from "../ts/translation-service";
import { Notyf } from "notyf";
import { SportSubscriptionViewModel } from "../ts/models/sport-subscription-viewmodel";
import { AvailableMonthsModel, EventModel, PackageModel } from "../ts/models/sport-list-viewmodel";
import { ModalConfirm } from "./modal-confirm";
import { SportPackageAddModel } from "../ts/models/sport-package-add-model";
import { FullscreenLoader } from "./fullscreen-loader";
//import { DateTimePickerWidget } from "../../../skupno/src/ts/widgets/date-time-picker-widget";

@injectable()
export class SportView {
    private _apiClient: SportEventApiClient;
    private _translationService: TranslationService;
    private _priceList: Array<any>;
    private _packageEvents: Array<EventModel>;
    //private _datePicker: DateTimePickerWidget;

    private _availableMonths: Array<AvailableMonthsModel> | null;
    private _packages: Array<PackageModel> | null;
    private _events: Array<EventModel> | null;
    private mySubscriptions: Array<SportSubscriptionViewModel>;

    private _formElement: HTMLFormElement | null;
    private _btnSubmitElement: JQuery<HTMLElement> | null;
    private selectedQuery: string | null;
    private isAdmin: boolean;

    public constructor(sportEventApiClient: SportEventApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = sportEventApiClient; 
        this._translationService = translationService;
        this._availableMonths = null;
        this.mySubscriptions = new Array<SportSubscriptionViewModel>();
        this._packages = null;
        this._events = null;
        this._priceList = new Array<any>();
        this._packageEvents = new Array<EventModel>();
        //this._datePicker = new DateTimePickerWidget;
        this.isAdmin = false;

        this.selectedQuery = null;
        this._formElement = null;
        this._btnSubmitElement = null;

    }
    /*
     * Wait for data then render it
     */
    public async load(query: string = ""): Promise<void> {

        try {
            this.loader.show();

            this.isAdmin = await this._apiClient.isSportAdmin();
            if (this.isAdmin) {
                var options = await this._apiClient.getOptions() as any;
                if (options) {
                    this._priceList = options.priceList;
                }
            }

            if (query) {
                this.selectedQuery = query;
            }
            else if (!query && this.selectedQuery) {
                query = this.selectedQuery;
            }
            // Try to get data
            //TODO: prebrati podatke
            var sport = await this._apiClient.getSportList(query);
            this._availableMonths = sport.availableMonths;
            this._packages = sport.packages;
            this._events = await this._apiClient.getMyEventsThisWeek();
            for (var i = 0; i < this._events.length; i++) {
                if (this._events[i].attachments && this._events[i].attachments.length > 0) {
                    this._events[i].attachment = this._events[i].attachments[0];
                }
            }

            //var events = await this._apiClient.getEvents(undefined, true);
            this.mySubscriptions = await this._apiClient.getSubscriptionsWhereUser();
            // Render new content
            this._renderData();
            this.refreshPackages();
            this.initActionButtons();
            this.initSubscribtionModalButton();
            this.initUnsubscribeButton();

            $('.form-select.sport').on('change', () => {     
                this.refreshPackages();              
            });

            var self = this;
            $('#month-select').on('change', function () {
                var query = $(this).val() as string;
                self.load(query);
            });

            this.initDownloadLink();
            this.loader.hide();

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        
        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "sportEvents": this._events,
            "formators": this.getFormators(),
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            "availableMonths": this._availableMonths,
            "subscriptions": this.mySubscriptions,
            "admin": this.isAdmin,
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        this.initEventDetailsModalButton();
    }

    public async refreshPackages() {

        //var packets = await this._apiClient.getPackagesThisMonth(month);
        //packets = this.formatEventsInPack(packets);
        this.formatEventsInPack(this._packages);
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "packages": this._packages,
            "formators": this.getFormators(),
            "admin": this.isAdmin,
            translations: this._translationService.currentTranslations
        } as any;

        const htmlTemplate = packetsTemplate;
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#PacketsTableWrap').html(html);

        $(".packet-order-add").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            var self = this;
            var translations = this._translationService.currentTranslations;
            if (id != null) {
                ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.SubscribeConfirm,
                    confirmType: 'splosno'
                }).then(() => {
                    self._apiClient.packetAddOrder(id).then(
                        async function () {
                            self.notyf.success(self._translationService.currentTranslations.SubscribeSuccess);
                            await self.load();
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self._translationService.currentTranslations.Error);
                        });
                }).catch(() => {
                });
            } 
        });

        $(".packet-order-delete").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            var self = this;
            var translations = this._translationService.currentTranslations;
            if (id != null) {
                ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.UnsubscribeConfirm,
                    confirmType: 'brisanje'
                }).then(() => {
                    self._apiClient.packetRemoveOrder(id).then(
                        async function () {
                            self.notyf.success(self._translationService.currentTranslations.UnsubscribeSuccess);
                            await self.load();
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self._translationService.currentTranslations.Error);
                        });
                }).catch(() => {
                }); 
            }            
        });

        $(".event-order-delete").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            var self = this;
            var translations = this._translationService.currentTranslations;
            if (id != null) {
                ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.UnsubscribeConfirm,
                    confirmType: 'brisanje'
                }).then(() => {
                    self._apiClient.eventDeleteOrder(id).then(
                        async function () {
                            self.notyf.success(self._translationService.currentTranslations.UnsubscribeSuccess);
                            await self.load();
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self._translationService.currentTranslations.Error);
                        });
                }).catch(() => {
                });
            }
        });

        $(".event-order-toggle-publish").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            if (id != null) {
                await this._apiClient.eventTogglePublish(+id);
                this.load();
            }
        });
    }

    //private formatEventsInPack(packs:any) {
    //    packs.forEach(function (value: any) {
    //        var events: Array<object> = new Array<object>;
    //        var ev: Array<object> = new Array<object>;            
    //        var drei: number = 0;
    //        var push = true;
    //        for (var i: number = 0; i < value.sportPackage.events.length; i++) {
                
    //            if (drei < 3) {
    //                ev.push(value.sportPackage.events[i]);
    //                drei++;
    //                push = false;
    //            } else {                    
    //                events.push(ev);
    //                drei = 1;
    //                ev = new Array<object>;
    //                ev.push(value.sportPackage.events[i]);
    //                push = true;
    //            }                     
    //        }
    //        if (push == false) {
    //            events.push(ev);
    //            drei = 0;
    //            ev = new Array<object>;
    //        }
    //        value.sportPackage.events = events;
    //    })
    //    return packs;
    //}

    private _renderDetailsModal(subscription: any): void {

        // Build a view model from the API data
        const viewModel = {
            data: subscription,
            translations: this._translationService.currentTranslations,
            "admin": this.isAdmin,
        } as any;
        // Construct a template
        const htmlTemplate = subscriptionDetailsModalTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#subscription-details-modal').html(html);
    }

    public initSubscribtionModalButton() {
        var self = this;
        $(".subscription-details-btn").on("click", function (ev) {
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);

            var subscription = self.mySubscriptions.find((el) => el.id == id);
            if (!subscription) {
                return;
            }

            self._renderDetailsModal(subscription);
            $("#subscription-details-modal").modal("show");
        });
    }

    public initUnsubscribeButton() {
        var self = this;
        var translations = this._translationService.currentTranslations;
        $(".subscription-unsubscribe").on("click", function (ev) {
            ev.preventDefault();
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);

            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: translations.UnsubscribeConfirm,
                confirmType: 'brisanje'
            }).then(() => {
                self._apiClient.subscriptionUnsubscribe(id).then(
                    async function (data: any) {
                        if (data && data.success) {
                            console.debug(data);
                            self.notyf.success(self._translationService.currentTranslations.UnsubscribeSuccess);
                            await self.load();
                        }

                    },
                    function (reason: any) {
                        console.debug(reason);
                        self.notyf.error(self._translationService.currentTranslations.Error);
                    });
            }).catch(() => {
            }); 
        });
    }

    public initActionButtons() {
        var rootElement = $("#main").find(".sport-root-element");
        var self = this;
        var translations = this._translationService.currentTranslations;
        rootElement.find(".popover-event-actions").each((_index, element) => {
            var id = element.getAttribute("data-id");
            $(element).popover({
                container: rootElement[0],
                content: '<ul><li><a tabindex="0" href="#" class="delete" data-action="delete" data-id="' + id + '">' + translations.Delete + '</a></li></ul>',
                html: true,
                sanitize: false,
                trigger: "focus",
                delay: {
                    show: 0,
                    hide: 200
                }
            });
        });
        rootElement.find(".popover-package-actions").each((_index, element) => {
            var id = parseInt(element.getAttribute("data-id") as string);
            var content = '<ul><li><a href="#" class="edit" data-action="edit-package" data-id="' + id + '">' + translations.Edit + '</a></li>';
            var el = self._packages?.find((el) => el.id == id);
            if (el) {
                if (el.published == 1) {
                    content += '<li><a href="#" class="deactivate" data-action="toggle-active-package" data-id="' + id + '">' + "Deaktiviraj"/*translations.Deactivate*/ + '</a></li>';
                }
                else {
                    content += '<li><a href="#" class="activate" data-action="toggle-active-package" data-id="' + id + '">' + "Aktiviraj"/*translations.Activate*/ + '</a></li>';
                }
                if (el.canDelete) {
                    content += '<li><a href="#" data-action="delete-package" class="delete" data-id="' + id + '">' + translations.Delete + '</a></li>'
                }
            }
            content += '</ul>';
            $(element).popover({
                container: rootElement[0],
                content: content,
                html: true,
                sanitize: false,
                trigger: "focus",
                delay: {
                    show: 0,
                    hide: 200
                }
            });
        });

        rootElement.on("click", "[data-action=delete]", async (ev) => {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: translations.ConfirmDeleteContent,
                confirmType: 'brisanje'
            }).then(() => {
                this._apiClient.deleteEvent(id).then(async () => {
                    await self.load();
                })
                    .catch(() => {
                        this.notyf.error(translations.DeleteError);
                    });
            }).catch(() => {
                console.log("delete canceled:" + id);
            });
        });

        rootElement.on("click", "[data-action=delete-package]", async (ev) => {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: translations.ConfirmDeleteContent,
                confirmType: 'brisanje'
            }).then(() => {
                this._apiClient.deletePackage(id).then(async () => {
                    await self.load();
                })
                    .catch(() => {
                        this.notyf.error(translations.DeleteError);
                    });
                console.log("delete confirmed:" + id);
            }).catch(() => {
                console.log("delete canceled:" + id);
            });
        });

        rootElement.on("click", "[data-action=toggle-active-package]", async (ev) => {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: translations.ConfirmDeleteContent,
                confirmType: 'splosno'
            }).then(() => {
                this._apiClient.packageTogglePublish(id).then(async () => {
                    await self.load();
                })
                    .catch(() => {
                        this.notyf.error(translations.DeleteError);
                    });
                console.log("delete confirmed:" + id);
            }).catch(() => {
                console.log("delete canceled:" + id);
            });
        });
        rootElement.on("click", "[data-action=edit-package]", async (ev) => {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            this._apiClient.getPackage(id).then(async () => {
                self._renderPaketUrediModal(id);
                $("#edit-package-modal").modal("show");
            })
                .catch(() => {
                    this.notyf.error(translations.error);
                });
        });
        $(".popover-edit-subscriptions").each(function () {
            var element = this;
            var id = element.getAttribute("data-id");
            $(element).popover({
                content: '<ul><li><a href="/#/sport/narocnina/' + id + '" class="edit">' + translations.Edit + '</a></li><li><a href="/#/sport/dnevnik-aktivnosti" class="logs">' + translations.SportActivityLog + '</a></li></ul>',
                html: true
            });
        });
    }

    public initEventDetailsModalButton() {
        var self = this;
        $(".event-details-btn").on("click", function (ev) {
            const target = ev.currentTarget as HTMLInputElement;
            var dataId = target.getAttribute("data-id");
            if (!dataId) {
                return;
            }
            var id = parseInt(dataId);

            if (!self._events) {
                return;
            }

            var event = self._events.find((el) => el.id == id);
            if (event) {
                self._renderEventDetailsModal(event);
                $("#event-details-modal").modal("show");
            }
        });
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
            dateTimeFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat + ' HH:mm');
                };
            },
            timeRange: function () {
                return function (timestamp: any, render: any) { //3. April (19:30 - 20:15)
                    var timeString: string[] = render(timestamp).split(" ");
                    var time: Date[] = [];
                    time[0] = new Date(timeString[0]);
                    time[1] = new Date(timeString[1]);
                    if (time[0].getDate() == time[1].getDate() && time[0].getMonth() == time[1].getMonth()) {
                        return moment((time[0])).format('DD. MMM (HH:mm') + " - " + moment((time[1])).format('HH:mm)')
                    } else {
                        return moment((time[0])).format('DD. MMM (HH:mm)') + " - " + moment((time[1])).format('DD. MMM (HH:mm)')
                    }
                };
            }
        };
    }

    private _renderEventDetailsModal(event: EventModel): void {
        var tools = JSON.parse(event.tools);
        // Build a view model from the API data
        const viewModel = {
            translations: this._translationService.currentTranslations,
            formators: this.getFormators(),
            event: event,
            tools: tools,
            "admin": this.isAdmin,
        } as any;
        // Construct a template
        const htmlTemplate = eventDetailsModalTemplate;
        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#event-details-modal').html(html);

        this.initDownloadLink();
    }

    private initDownloadLink() {
        var self = this;
        $(".download-attachment").on("click", async function (ev) {
            ev.preventDefault();
            var id = parseInt($(ev.currentTarget).attr("data-id") as string, 10);
            var label = $(ev.currentTarget).attr("data-name") as string;
            //var vlogaName = $(ev.currentTarget).attr("data-vloga-name") as string;
            await self._apiClient.attachmentDownload(id)
                .then((fileContent) => {
                    var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                    var url = URL.createObjectURL(blob);

                    //var fileName: string = fileContent.fileName;

                    //if (!fileName) {
                    //    //fileName = vlogaName.toLowerCase().replace(/\s+/g, '_') + "_" + moment(new Date()).format("yyyyMMDDHHmm");
                    //    fileName = fileName.replace(/(_)_+|(\\.)\\.+|(-)-+/, "$1$2$3") + ".xlsx";
                    //}

                    var fileLink = document.createElement('a');
                    fileLink.href = url;
                    fileLink.download = label;
                    fileLink.click();
                })
                .catch((ex) => {
                    $('#main').text(self._translationService.currentTranslations[ex.message]);
                });
        });
    }

    //Urejanje paketa
    private async _renderPaketUrediModal(id: number): Promise<void> {
        var pckg = await this._apiClient.getPackage(id);
        if (pckg.priceCode) {
            for (var i = 0; i < this._priceList.length; i++) {
                //if (pckg.priceCode.substring(1, pckg.priceCode.length) == this._priceList[i].id) {
                if (pckg.priceCode == this._priceList[i].id) {
                    this._priceList[i].selected = true;
                }
                else {
                    this._priceList[i].selected = false;
                }
            }
        }
        this._packageEvents = pckg.events;
        // Build a view model from the API data
        const viewModel = {
            formators: this.getFormators(),
            translations: this._translationService.currentTranslations,
            priceList: this._priceList,
            package: pckg,
            "admin": this.isAdmin,
        } as any;
        // Construct a template

        const htmlTemplate = paketUrediModalTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#edit-package-modal').html(html);

        this._renderEditPackageEvents();
        //this._datePicker.load();
        this._initEditPackageForm();
        //$('#edit-package-modal').find("#valid").val(pckg.valid.toLocaleDateString());
        //$('#edit-package-modal').find("#valid").data("daterangepicker")?.setStartDate(pckg.valid as Date);
        //$('#edit-package-modal').find("#valid").data("daterangepicker")?.setEndDate(pckg.valid as Date);
    }

    private _renderEditPackageEvents(): void {

        // Build a view model from the API data
        const viewModel = {
            events: this._packageEvents,
            formators: this.getFormators(),
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = dogodkiPaketaTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#edit-package-modal').find('#package-events').html(html);

        var self = this;
        $('#edit-package-modal').find(".delete-package-event-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onEditPackageDeleteEvent(ev));
        });
    }

    public _onEditPackageDeleteEvent(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var id = parseInt(target.getAttribute("data-id") as string);

        if (!this._packageEvents) {
            return;
        }
        var index = this._packageEvents.findIndex((el) => el.id == id);
        if (index || index == 0) {
            this._packageEvents.splice(index, 1);
        }
        this._renderEditPackageEvents();
    }

    private _initEditPackageForm(): void {
        require('jquery-validation-unobtrusive');
        jQuery.validator.unobtrusive.parse($("#edit-package-form"));
        this._formElement = document.getElementById("edit-package-form") as HTMLFormElement;
        this._btnSubmitElement = $(this._formElement).find("#update-package-btn");
        this._btnSubmitElement.on("click", (ev: Event) => this._onUpdatePackageFormSubmit(ev));
    }

    private async _onUpdatePackageFormSubmit(ev: Event) {
        ev.preventDefault();
        var self = this;
        if (!this._formElement?.checkValidity()) {
            this._formElement?.reportValidity();
            return;
        }

        var noEvents = true;
        var selectedEvents = [];
        if (this._packageEvents) {
            for (var i = 0; i < this._packageEvents?.length; i++) {
                selectedEvents.push(this._packageEvents[i].id);
                noEvents = false;
            }
        }

        var priceId = $("#price").val();
        var priceElement = this._priceList.find((el) => el.id == priceId);
        var price;
        var priceCode;
        if (priceElement) {
            price = priceElement.price;
            priceCode = priceElement.id;
        }

        let data: SportPackageAddModel = {
            id: parseInt($("#id").val() as string),
            title: $("#title").val() as string,
            //valid: $("#valid").data("daterangepicker")?.startDate.toDate() as Date,
            price: price,
            priceCode: priceCode,
            EventIds: selectedEvents
        };

        var self = this;
        var translations = this._translationService.currentTranslations;

        if (noEvents) {
            ModalConfirm.confirm({
                cancelText: translations.Cancel,
                confirmText: translations.Confirm,
                content: "Če shranite paket brez dogodkov, se bo paket izbrisal. Želite nadaljevati?",
                confirmType: 'brisanje'
            }).then(async () => {
                await this._apiClient.editPackage(data).then(
                    function (data: any) {
                        console.debug(data);
                        self.load();
                        $("#edit-package-modal").modal("toggle");
                        self.notyf.success("Paket uspešno shranjen");
                    },
                    function (reason: any) {
                        console.debug(reason);
                        self.notyf.error("Pri shranjevanju je prišlo do napake");
                    }
                );
            }).catch(() => {
                return;
            });
        }
        else {
            await this._apiClient.editPackage(data).then(
                function (data: any) {
                    console.debug(data);
                    self.load();
                    $("#edit-package-modal").modal("toggle");
                    self.notyf.success("Paket uspešno shranjen");
                },
                function (reason: any) {
                    console.debug(reason);
                    self.notyf.error("Pri shranjevanju je prišlo do napake");
                }
            );
        }
    }

    private formatEventsInPack(packs: any) {

        packs.forEach(function (pckg: any) {
            var eventGroups: Array<Array<object>> = new Array<Array<object>>;
            var events: Array<object> = new Array<object>;
            var innerGroupIndex: number = 0;
            for (var i: number = 0; i < pckg.events.length; i++) {

                if (innerGroupIndex < 3) {
                    events.push(pckg.events[i]);
                    innerGroupIndex++;
                } else {
                    eventGroups.push(events.slice());
                    events = new Array<object>;
                    events.push(pckg.events[i]);
                    innerGroupIndex = 1;
                }
            }

            if (events) {
                eventGroups.push(events.slice());
            }

            pckg.events = eventGroups;
        });
        return packs;
    }
}