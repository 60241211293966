import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { Raderror } from "../models/raderror";

@injectable()
export class RadErrorApiClient extends ApiClientBase {
    public name = "RadErrorApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getList(page?: number, limit?: number, username?: string, mac?: string, description?: string): Promise<ListResponse<Raderror>> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit,
            "username": username,
            "mac": mac,
            "description": description
        };
        return await this._callApi('/error/list', 'GET', data) as ListResponse<Raderror>;
    }
}