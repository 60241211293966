import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import Quill from "quill";
import { TranslationService } from "../ts/translation-service";
import template from "./kuponi-uredi-view.html";
import { CouponsApiClient } from "../ts/clients/coupons-api-client";
import { CouponCampaign } from "../ts/models/coupon-campaign";
import * as DateRangePicker from 'daterangepicker';
import * as moment from 'moment';

@injectable()
export class KuponiUrediView {
    private _apiClient: CouponsApiClient;
    private _translationService: TranslationService;
    private _file: any | null;

    private _messageEditorQuillElementSl: Quill | null;
    private _messageEditorQuillElementEn: Quill | null;
    private _messageEditorElementSl: HTMLElement | null;
    private _messageEditorElementEn: HTMLElement | null;
    private _uploadFilesElement: HTMLInputElement | null;

    private _validFromPicker: DateRangePicker = null!;
    private _validToPicker: DateRangePicker = null!;
    private _userCreatedFromPicker: DateRangePicker = null!;
    private _userCreatedToPicker: DateRangePicker = null!;
    private _disabled: boolean;
    private _id: number;
    private _item: any;

    public constructor(apiClient: CouponsApiClient, translationService: TranslationService, private notyf: Notyf) {
        this._apiClient = apiClient; 
        this._translationService = translationService;
        this._uploadFilesElement = null;
        this._messageEditorElementEn = null;
        this._messageEditorElementSl = null;
        this._messageEditorQuillElementSl = null;
        this._messageEditorQuillElementEn = null;
        this._file = null;
        this._disabled = false;
        this._id = 0;
        this._item = null;
    }
    /*
     * Wait for data then render it
     */
    public async load(id: number): Promise<void> {

        try {
            this._id = id;
            this._item = await this._apiClient.getCampaign(id);
            this._renderData(this._item);
            if (!this._disabled) {
                this._initUploadField();    
            }
            var preview = window.location.href.indexOf("?preview") != -1;
            if (preview) {
                this.showPreview(id);
            }
        } catch (e) {
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(item: CouponCampaign): void {

        // Build a view model from the API data
        if (item.published || item.archived) {
            this._disabled = true;
        }
        const viewModel = {
            "item": item,
            "disabled": this._disabled,
            "translations": this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';

        $("#validFrom").val(new Date(item.validFrom).toLocaleDateString(userLocale));
        if (item.validTo) {
            $("#validTo").val(new Date(item.validTo).toLocaleDateString(userLocale));
        }
        $("#userCreatedFrom").val(new Date(item.userCreatedFrom).toLocaleDateString(userLocale));
        if (item.userCreatedTo) {
            $("#userCreatedTo").val(new Date(item.userCreatedTo).toLocaleDateString(userLocale));
        }

        this._initForm();
        this._initQuillEditor(item.emailBodySl, item.emailBodyEn, this._disabled);

        $(".download-attachment").on("click", (ev) => {
            ev.preventDefault();
            this.downloadAttachment(this._id);
        })
    }

    //inicializira obrazec
    private _initForm(): void {
        var self = this;
        var translations = this._translationService.currentTranslations;
        var formElement = $("#kuponi-kampanja-edit-form");

        this._validFromPicker = new DateRangePicker($('#main').find("#validFrom")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years')
        });
        this._validToPicker = new DateRangePicker($('#main').find("#validTo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            //startDate: moment().add(1, 'years'),
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years')
        });
        this._userCreatedFromPicker = new DateRangePicker($('#main').find("#userCreatedFrom")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years')
        });
        this._userCreatedToPicker = new DateRangePicker($('#main').find("#userCreatedTo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            //startDate: moment().add(1, 'years'),
            minDate: moment().add(-1, 'years'),
            maxDate: moment().add(5, 'years')
        });

        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        $(".submit-btn").on("click", async (ev) => {
            ev.preventDefault();

            var showPreview = false;
            //var submitType = ev.currentTarget.getAttribute("data-submitType");
            //if (submitType == "saveAndPreview") {
            //    showPreview = true;
            //}
            //else if (submitType == "preview") {
            //    var id = parseInt($("#id").val() as string, 10);
            //    await this.showPreview(id);
            //    return;
            //}

            //var barCode = $("#barCodeData").val();
            //if (!barCode) {
            //    $(".barcode-validation").removeAttr("style");
            //}

            if (!formElement.valid() /*|| !barCode*/) {
                this.notyf.error(translations.CheckAllFields);
                return;
            }

            var formData = new FormData();
            formData.append("id", $("#id").val() as string);
            formData.append("name", $("#name").val() as string);
            formData.append("validFrom", (this._validFromPicker.startDate.isValid() ? this._validFromPicker.startDate.format() : undefined) as string);
            formData.append("validTo", (this._validToPicker.startDate.isValid() ? this._validToPicker.startDate.format() : undefined) as string);
            formData.append("userCreatedFrom", (this._userCreatedFromPicker.startDate.isValid() ? this._userCreatedFromPicker.startDate.format() : undefined) as string);
            formData.append("userCreatedTo", (this._userCreatedToPicker.startDate.isValid() ? this._userCreatedToPicker.startDate.format() : undefined) as string);
            formData.append("emailSubjectSl", $("#emailSubjectSl").val() as string);
            formData.append("emailSubjectEn", $("#emailSubjectEn").val() as string);
            formData.append("uiMessageSl", $("#uiMessageSl").val() as string);
            formData.append("uiMessageEn", $("#uiMessageEn").val() as string);
            formData.append("emailBodySl", this._messageEditorQuillElementSl?.root.innerHTML as string);
            formData.append("emailBodyEn", this._messageEditorQuillElementEn?.root.innerHTML as string);
            //formData.append("barCodeData", $("#barCodeData").val() as string);
            formData.append("barCodeData", "{}");
            if (this._file) {
                formData.append("attachments", this._file);
            }

            await this._apiClient.updateCampaign(formData).then(
                async function (data: any) {
                    console.debug(data);
                    self.notyf.success(translations.CampaignSuccessfullySaved);
                    if (showPreview) {
                        await self.showPreview(data);
                    }
                },
                function (reason: any) {
                    console.debug(reason);
                    self.notyf.error(translations.SaveError);
                }
            );
        });
    }

    public async showPreview(id: number) {
        await this._apiClient.preview(id)
            .then((fileContent) => {
                var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                var objectURL = URL.createObjectURL(blob);
                $(".img-preview").attr("src", objectURL);
                $("#preview-modal").modal("show");
            })
            .catch((ex) => {
                $('#main').text(this._translationService.currentTranslations[ex.message]);
            });
    }

    public async downloadAttachment(id: number) {
        await this._apiClient.downloadAttachment(id)
            .then((fileContent) => {
                var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                var url = URL.createObjectURL(blob);
                var fileLink = document.createElement('a');
                fileLink.href = url;
                fileLink.download = this._item.name + "_predloga";
                fileLink.click();
            })
            .catch((ex) => {
                $('#main').text(this._translationService.currentTranslations[ex.message]);
            });
    }

    //inicializira RTE
    public _initQuillEditor(bodySl: string, bodyEn: string, readonly: boolean = false) {

        var ColorClass = Quill.import('attributors/class/color');
        var SizeStyle = Quill.import('attributors/style/size');
        var Bold = Quill.import('formats/bold');
        Quill.register(ColorClass, true);
        Quill.register(SizeStyle, true);
        Bold.tagName = 'B';   // Quill uses <strong> by default
        Quill.register(Bold, true);
        var toolbarOptions = [
            [{ container: "#toolbar-container" }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let mainElement: JQuery<HTMLElement> = $('#main');
        this._messageEditorElementSl = mainElement.find(".editor-container")[0];
        this._messageEditorQuillElementSl = new Quill(this._messageEditorElementSl, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",
            readOnly: readonly
        });
        this._messageEditorQuillElementSl.root.innerHTML = bodySl;

        this._messageEditorElementEn = mainElement.find(".editor-container")[1];
        this._messageEditorQuillElementEn = new Quill(this._messageEditorElementEn, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: "snow",
            readOnly: readonly
        });
        this._messageEditorQuillElementEn.root.innerHTML = bodyEn;
    }

    //inicializira file upload polje
    public _initUploadField() {
        this._uploadFilesElement = document.getElementById("attachments") as HTMLInputElement;
        this._uploadFilesElement.addEventListener("change", (ev: Event) => this._onUploadChange(ev));
    }

    //shrani pripete dokumente v seznam za kasnejše pošiljanje na strežnik
    public _onUploadChange(ev: Event) {
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            this._file = list[0];
        };
    }
}