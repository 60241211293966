import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { TableWidget } from "../../../skupno/src/ts/widgets/table-widget";
import { CouponsApiClient } from "../ts/clients/coupons-api-client";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./kuponi-view.html";
import { ModalConfirm } from "./modal-confirm";

@injectable()
export class KuponiView {
    private _apiClient: CouponsApiClient;
    private _translationService: TranslationService;

    public constructor(apiClient: CouponsApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = apiClient;
        this._translationService = translationService; 
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        console.log(this.notyf);
        try {
            // Try to get data
            //TODO: prebrati podatke
            // Render new content
            var translations: any = await this._translationService.currentTranslations;
            this._renderData();
            var rootElement = $("#main").find(".kuponi-kampanje-table-placeholder");
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: rootElement,
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    return await this._apiClient.getList(currentPage);
                }               
            });
         
            await tableWidget.refresh(1, this._translationService.currentTranslations)
                .then(() => {  
                    // ne naredi nic
                })
                .catch((ex) => {
                    if (ex.statusCode == 400) {
                        console.log(ex);
                        $('#main').text(translations[ex.message]);
                    }
                    else {
                        $('#main').text(translations["LoadingError"]);
                    }
                });

            this._initImportForm();

        } catch (e) {
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }

        this.initButtons();
    }

    public initButtons() {
        $(".btn-publish").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            var self = this;
            var translations = this._translationService.currentTranslations;
            if (id != null) {
                ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.PublishConfirm,
                    confirmType: 'splosno'
                }).then(() => {
                    self._apiClient.publishCampaign(id).then(
                        async function (data) {
                            if (data && data.code == 101) {
                                self.notyf.error(self._translationService.currentTranslations.CampaignPublishError);
                            }
                            else {
                                self.notyf.success(self._translationService.currentTranslations.PublishSuccess);
                                await self.load();
                            }                           
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self._translationService.currentTranslations.Error);
                        });
                }).catch(() => {
                });
            }
        });

        $(".btn-archieve").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            var self = this;
            var translations = this._translationService.currentTranslations;
            if (id != null) {
                ModalConfirm.confirm({
                    cancelText: translations.Cancel,
                    confirmText: translations.Confirm,
                    content: translations.ArchiveConfirm,
                    confirmType: 'splosno'
                }).then(() => {
                    self._apiClient.archieveCampaign(id).then(
                        async function () {
                            self.notyf.success(self._translationService.currentTranslations.ArchieveSuccess);
                            await self.load();
                        },
                        function (reason: any) {
                            console.debug(reason);
                            self.notyf.error(self._translationService.currentTranslations.Error);
                        });
                }).catch(() => {
                });
            }
        });

        $(".btn-import").on('click', async (e) => {
            var id: any = e.target.getAttribute("value");
            if (id != null) {
                $("#import-coupons-form").find("#id").val(id);
                $("#import-modal").modal("show");
            }
        });
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
            timeRange: function () {
                return function (timestamp: any, render: any) { //3. April (19:30 - 20:15)
                    var timeString: string[] = render(timestamp).split(" ");
                    var time: Date[] = [];
                    time[0] = new Date(timeString[0]);
                    time[1] = new Date(timeString[1]);
                    //return moment((time[0])).format(localeFormat) + " - " + moment((time[1])).format(localeFormat)
                    if (time[0].getDate() == time[1].getDate() && time[0].getMonth() == time[1].getMonth()) {
                        return moment((time[0])).format('DD. MMM (hh:mm') + " - " + moment((time[1])).format('hh:mm)')
                    } else {
                        return moment((time[0])).format('DD. MMM (hh:mm)') + " - " + moment((time[1])).format('DD. MMM (hh:mm)')
                    }
                };
            }
        };
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            translations: this._translationService.currentTranslations,
            formators: this.getFormators()
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

    }

    private dataTableModel(translations: any): any {
        const tableMap = {
            title: translations["Campaigns"] ?? "Kampanje",
            classInsert: "kuponi-kampanje-table-placeholder",
            controls: '<a href="/#/kuponi/dodaj" class="add">' + translations["AddCampaign"] + '</a>',
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["name"] ?? "Naziv",
                    html: "<a href='/#/kuponi/{{id}}'>{{name}}</a>"
                },
                {
                    name: translations["Valid"] ?? "Veljavno od",
                    html: "{{#formators}}{{#dateFormat}}{{validFrom}}{{/dateFormat}}{{/formators}} - {{#formators}}{{#dateFormat}}{{validTo}}{{/dateFormat}}{{/formators}}"
                },
                {
                    name: translations["Updated"] ?? "Posodobljeno",
                    html: "{{#formators}}{{#dateTimeFormat}}{{updated}}{{/dateTimeFormat}}{{/formators}}"
                },
                {
                    name: translations["Coupons"] + " (" + translations["Used"] + "/" + translations["All"] + ")",
                    html: '{{^archived}}<a href="javascript:void(0)" class="btn-import" value="{{ id }}" style="padding-right:10px">' + translations["ImportCoupons"] + '</a>{{/archived}}( {{usedCoupons}} / {{allCoupons}} )'
                },
                {
                    name: "",
                    html: '{{#archived}}{{/archived}}{{^archived}}{{#published}}<a href="javascript:void(0)" class="btn btn-sm btn-outline-secondary btn-archieve" value="{{id}}">' + translations["Archieve"] + '</a>{{/published}}{{^published}}<a href="javascript:void(0)" class="btn btn-sm btn-outline-primary btn-publish" value="{{id}}">' + translations["Publish"] + '</a>{{/published}}{{/archived}}',
                },
                {
                    name: translations["Status"] ?? "Status",
                    html: "{{#archived}}<div class='status osnutek'>" + translations["Archieved"] + "</div>{{/archived}}{{^archived}}{{#published}}<div class='status aktivno'>" + translations["Published"] + "</div>{{/published}}{{^published}}<div class='status neaktivno'>" + translations["InPreparation"] + "</div>{{/published}}{{/archived}}"
                }
            ]
        };
        return tableMap;
    }

    private _initImportForm(): void {
        var self = this;       

        var translations = this._translationService.currentTranslations;
        var formElement = $("#import-coupons-form");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", async (ev) => {
            ev.preventDefault();            

            if (!formElement.valid()) {
                this.notyf.error(translations.InsertCoupons);
                return;
            }

            this.loader.show();

            var id = $("#import-coupons-form").find("#id").val() as string;
            var text = $("#import-coupons-form").find("#importTxt").val() as string;

            await this._apiClient.importCoupons(id, text).then(
                async function () {
                    self.load();
                    self.notyf.success(translations.ImportSuccess);
                    $("#import-coupons-form").find("#importTxt").val('');
                    $("#import-modal").modal("hide");  
                    self.loader.hide();
                },
                function (reason: any) {
                    console.debug(reason);
                    self.notyf.error(translations.ImportError);
                }
            );
        });
    }
   
}