import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { RadErrorApiClient } from "../ts/clients/rad-error-api-client";
import template from "./internet-dnevnik-napak-view.html";
import InputMask from 'inputmask';
import { TranslationService } from "../ts/translation-service";
import { ClipboardUtilities } from "../ts/utilities/clipboard-utilities";
import { Notyf } from "notyf";
import * as moment from "moment";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";

@injectable()
export class InternetDnevnikNapakView {
    private _radErrorApiClient: RadErrorApiClient;
    private _translationService: TranslationService;
    private _tableWidget: TableWidget = null!;
    /*
    private _uporabnikSearchInput: HTMLElement;
    private _macSearchInput: HTMLElement;
    private _opisSearchInput: HtmlElement;
    */
    private itemsPerPage = 30;

    constructor(radErrorApiClient: RadErrorApiClient, translationService: TranslationService, private notyf:Notyf) {
        this._radErrorApiClient = radErrorApiClient;
        this._translationService = translationService;
    }


    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        var translations = this._translationService.currentTranslations;

        try {
            this._renderData();
            var rootElement = $("#main").find(".internet-dnevnik-napak-table-placeholder");
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: rootElement,
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }

                    let mainElement = $('#main');
                    let _uporabnikSearchInputValue = mainElement.find("#uporabnikSearchInput").val() as string || undefined;
                    let _macSearchInputValue = mainElement.find("#macSearchInput").val() as string || undefined;
                    let _opisSearchInputValue = mainElement.find("#opisSearchInput").val() as string || undefined;

                    return await this._radErrorApiClient.getList(currentPage, this.itemsPerPage, _uporabnikSearchInputValue, _macSearchInputValue, _opisSearchInputValue);

                },
                onRender: () => {
                    rootElement.find("[data-bs-toggle=popover]").popover({
                        html: true,
                        trigger: "hover",
                    }).on("click", (ev)=>{
                        ev.preventDefault();
                        var text = $(ev.currentTarget).attr("data-bs-content") as string;
                        ClipboardUtilities.copyText(text).then(() => {
                            this.notyf.success(translations.ClipboardCopySuccess);
                        }).catch(() => {
                            this.notyf.error(translations.ClipboardCopyError);

                        })
                    })
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);
            this._tableWidget = tableWidget;
            await this._initSearch();
        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlSoftwareAndroid": "../img/software-android.svg",
            "imageUrlSoftwareLinux": "../img/software-linux.svg",
            "imageUrlSoftwareOSX": "../img/software-osx.svg",
            "imageUrlSoftwareWindows": "../img/software-windows.svg",
            "imageUrlSoftwareIos": "../img/software-ios.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlEdit": "../img/icon-edit.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlIconInfo": "../img/icon-info.svg",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);        
    }

    private dataTableModel(): TableWidgetTableMap {
        var translations = this._translationService.currentTranslations;
        return {
            title: translations["ErrorLog"],
            classInsert: "internet-dnevnik-napak-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["Time"],
                    //value: "errordate"
                    html: "{{#formators}}{{#dateTimeSecondsFormat}}{{errordate}}{{/dateTimeSecondsFormat}}{{/formators}}"
                },
                {
                    name: translations["Username"],
                    value: "username"
                },
                {
                    name: translations["Nas"],
                    value: "nas"
                },
                {
                    name: translations["Mac"],
                    value: "mac"
                },
                {
                    name: translations["Description"],
                    html: '{{description}} <a href="#" data-bs-toggle="popover" data-bs-content="{{description}}"><i class="bi bi-info-circle-fill text-primary"></i></a>'
                }
            ]
        };
    }
        

    private async _initSearch(): Promise<any> {
        let _btnSearchNapaka = $('#main').find("#btnSearchNapaka");
        _btnSearchNapaka.on("click", (ev: Event) => this.onSearch(ev));
        let _uporabnikSearchInput = $('#main').find("#uporabnikSearchInput");
        let _macSearchInput = $('#main').find("#macSearchInput");
        let _opisSearchInput = $('#main').find("#opisSearchInput");

        let _macSearchElementInputMask = new InputMask({
            mask: "a|9a|9-a|9a|9-a|9a|9-a|9a|9-a|9a|9-a|9a|9"
        });
        _macSearchElementInputMask.mask(_macSearchInput);

        _uporabnikSearchInput.on("keyup", (ev: Event) => this.onKeyPress(ev));
        _macSearchInput.on("keyup", (ev: Event) => this.onKeyPress(ev));
        _opisSearchInput.on("keyup", (ev: Event) => this.onKeyPress(ev));
    }

    private onKeyPress(ev: Event): void {
        let event = ev as KeyboardEvent;
        if (event.key === "Enter") {
            this.onSearch(ev);
        }
    }

    private async onSearch(ev: Event): Promise<any> {
        ev.preventDefault();
        this._tableWidget.refresh(1, this._translationService.currentTranslations);       
    }

    public getFormators() {
        return {
            longDateFormat: function () {
                return function (timestamp: any, render: any) { //sobota, 3. september
                    return moment(render(timestamp).trim()).format("dd. MM. yyyy : HH.mm");
                };
            }
        };
    }
}