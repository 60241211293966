import { injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./login-view.html";
import { TranslationService } from "../ts/translation-service";

@injectable()
export class LoginView {
    private _translationService: TranslationService;

    public constructor(translationService: TranslationService) {
        this._translationService = translationService;
    }

    public async load(): Promise<void> {

        try {
            // Render new content
            this._renderData();

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    public async listeners(): Promise<void> {
       
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlLogo": "../img/studentski-dom-ljubljana.png",
            "imageUrlMenuTopLeft": "../img/menu-image-top-left.svg",
            "imageUrlMenuTopRight": "../img/menu-image-top-right.svg",
            "imageUrlMenuBottomLeft": "../img/menu-image-bottom-left.svg",
            "imageUrlMenuBottomRight": "../img/menu-image-bottom-right.svg",
            "data": "",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;
        console.log(htmlTemplate);
        // Spremeni class na layout body
        $(document.body).removeClass("body-content").addClass("body-login");
        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#fullPage').html(html);
        
    }
}