import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { StatementApiClient } from "../clients/statements-api-client";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { IzjaveUrediView } from "../../views/izjave-uredi-view";
import { FullscreenLoader } from "../../views/fullscreen-loader";

@injectable()
export class IzjaveUrediPage extends PageBase {
    public name = "IzjaveUrediPage";
    private _apiClient: StatementApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _view: IzjaveUrediView;
    private _translationService: TranslationService
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;
    private _loader: FullscreenLoader;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("StatementApiClient") apiClient: StatementApiClient,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") notyf: Notyf,
        @inject("Router") router: Router,
        @inject("FullscreenLoader") loader: FullscreenLoader
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._apiClient = apiClient;
        this._router = router;
        this._translationService = translationService;
        this._notyf = notyf;
        this._loader = loader;
        this._view = new IzjaveUrediView(this._apiClient, translationService, this._notyf, this._loader);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/izjave/uredi/")) {
            return true;
        }

        var id: number | null = null;
        var idString: string = url.pathname.substr("/izjave/uredi/".length);
        if (idString) {
            id = parseInt(idString, 10);
        }
        if (!id) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            var translations = this._translationService.currentTranslations;
            mainLayout.pageTitle = translations.Statements + " - <span class=\"purple\">" + translations.Edit + "</span>";
            mainLayout.htmlTitle = translations.Statements + " - " + translations.Edit;
            mainLayout.headerUserViewShow();
            mainLayout.profileSidebarViewHide();
            await this._view.load(id);
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}