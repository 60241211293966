import { injectable } from "inversify";
import { UserApiClient } from "../ts/clients/users-api-client";
import * as Mustache from "mustache";
import mainView from "./management-uporabniki-pregled-view.html";
import osnovnoView from "./management-uporabniki-pregled-osnovno-view.html";
import vlogeView from "./management-uporabniki-pregled-vloge-view.html";
import grafView from "./management-uporabniki-pregled-graf-view.html";
import { UserDetailsVlogeResponse } from "../ts/models/user-details-vloge-response";
import { Notyf } from "notyf";
import * as ApexCharts from 'apexcharts';
import { TranslationService } from "../ts/translation-service";
import { TableWidget } from "../../../skupno/src/ts/widgets/table-widget";


@injectable()
export class ManagementUporabnikiPregledView {
    private _apiClient: UserApiClient;
    private _notyf: Notyf;
    private _translationService: TranslationService;

    public constructor(userApiClient: UserApiClient, notyf: Notyf, translationService: TranslationService) {
        this._apiClient = userApiClient;
        this._notyf = notyf;
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(userId: number): Promise<void> {
        try {
            $("#main").html(mainView);
            this.loadUserDetails(userId);
            this.loadUserPromet(userId);
            this.loadUserVloge(userId);
                        
        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async loadUserDetails(userId: number): Promise<void> {
        var userDetails = await this._apiClient.userDetails(userId) as any;
        userDetails.translations = this._translationService.currentTranslations;
        const html = Mustache.render(osnovnoView, userDetails);
        $("#user-details-osnovno").html(html);
    }

    private async loadUserVloge(userId: number): Promise<void> {
        var userVloge = await this._apiClient.userDetailsVloge(userId);
        var model = this.razporediVlogePoStolpcih(userVloge);
        (model as any).translations = this._translationService.currentTranslations;

        const html = Mustache.render(vlogeView, model);
        $("#user-details-vloge").html(html);

        $("#uporabniki-vloge-form").on("submit", (ev) => this.onVlogeSubmit(ev, userId));
    }
    private onVlogeSubmit(ev: JQuery.SubmitEvent, userId: number) {
        ev.preventDefault();

        var translations = this._translationService.currentTranslations;

        var izbraneVloge = $("#uporabniki-vloge-form [name=vloga]:checked").map((_index, element) => parseInt($(element).attr("value") as string, 10)).toArray();
        this._apiClient.userDetailsVlogeShrani(userId, izbraneVloge)
            .then(() => {
                this.loadUserVloge(userId);
                this._notyf.success(translations["SaveSuccessful"]);
            })
            .catch(() => {
                this._notyf.error(translations["SaveError"]);
            });
    }

    private async loadUserPromet(userId: number): Promise<void> {
        var userTraffic = await this._apiClient.userDetailsTraffic(userId);

        var translations = this._translationService.currentTranslations;
        const html = Mustache.render(grafView, {
            translations: translations
        });
        $("#user-details-graf").html(html);

        var chartOptions = {
            chart: {
                width: "100%",
                height: 380,
                type: 'bar',
                toolbar: {
                    tools: {
                        download: false
                    }
                }
            },
            series: [
                {
                    name: translations.Down,
                    data: userTraffic.graf.downloaded
                },
                {
                    name: translations.Up,
                    data: userTraffic.graf.uploaded,
                    
                }
            ],

            xaxis: {
                categories: userTraffic.graf.days
            },

            title: {
                alight: 'left',
                text: translations.UserTrafficForDate + " " + userTraffic.firstDate + " - " + userTraffic.lastDate,

                floating: true,
                offsetX: -10,
                offsetY: 10,
                style: {
                    fontFamily: 'Red Hat Display',
                    fontWeight: '400',
                    fontSize: '15px',
                    colour: 'rgb(33, 37, 41)',
                    
                }
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: ((val: any) => { return val.toFixed(2) + " GB" })
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right',
                offsetY: 10, 
                labels: {
                    useSeriesColors: false
                }
            },

            fill: {
                colors: ['#83B846', '#1B4E82' ]
            },

            grid: {
                show: true,
                borderColor: "#e8e4e4",
                strokeDashArray: 0,
                position: 'back',
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                xaxis: {
                    lines: {
                        show: true
                    }
                },
            },

            dataLabels: {
                enabled: false
            }
        };

        //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
        var chart = new ApexCharts($("#user-details-graf").find("#user-details-graf-div")[0], chartOptions);
        chart.render();
        $(".this-week-upload").html(userTraffic.weekProgress.weekDownloadHuman + " / " + userTraffic.weekProgress.weekLimitHuman);
        var markers = $("#user-details-graf").find(".apexcharts-legend-series");
        markers.filter(":nth(0)").addClass("custom-marker-down");
        markers.filter(":nth(1)").addClass("custom-marker-up");
        markers.find(".apexcharts-legend-marker").css("background", "");

        const tableWidget: TableWidget = new TableWidget({
            getData: (currentPage) => this._apiClient.getCurrentUserConnections(userId, currentPage, 20),
            rootElement: $("#dnevnik-prijav-table-placeholder"),
            tableMap: {
                title: translations.LoginLog,
                noDataContent: translations.NoData,
                columns: [
                    {
                        name: translations.Location,
                        value: "location" 
                    },
                    {
                        name: translations.Nas,
                        value: "nasShortName" 
                    },
                    {
                        name: translations.ValidStart,
                        value: "startTime" 
                    },
                    {
                        name: translations.End,
                        value: "endTime"  
                    },
                    {
                        name: translations.Time,
                        value: "sessionTime"
                    },
                    {
                        name: translations.Device,
                        value: "device" 
                    },
                    {
                        name: translations.EndCause,
                        value: "endCause"
                    },
                    {
                        name: translations.Ip,
                        value: "ip"
                    }
                ]
            }
        });
        tableWidget.refresh(1, this._translationService.currentTranslations);
    }

    private razporediVlogePoStolpcih(vloge: UserDetailsVlogeResponse): Array<Array<any>> {
        var izbraneVloge = vloge.selectedRoles;
        var vseVloge = vloge.allRoles;

        var stolpec1Length = Math.ceil(vseVloge.length / 2);
        var stolpec2Length = vseVloge.length - stolpec1Length;
        if (stolpec1Length == 0) {
            return new Array(0);
        }

        var rezultat: Array<Array<any>> = new Array(stolpec2Length > 0 ? 2 : 1);

        var trenutniStolpec = new Array<any>(stolpec1Length);
        rezultat[0] = trenutniStolpec;
        var trenutniStolpecIndex = 0;
        for (var i = 0; i < vseVloge.length; i++, trenutniStolpecIndex++) {
            var vloga = vseVloge[i];
            var vlogaSelected = izbraneVloge.indexOf(vloga.id) >= 0;

            trenutniStolpec[trenutniStolpecIndex] = {
                id: vloga.id,
                name: vloga.name,
                selected: vlogaSelected
            };

            if (i == stolpec1Length - 1) {
                trenutniStolpec = new Array<any>(stolpec2Length);
                rezultat[1] = trenutniStolpec;
                trenutniStolpecIndex = -1;
            } 
        } 
        return rezultat;
    } 

}