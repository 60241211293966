import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { Overnight } from "../models/overnight"
import { OvernightSendData } from "../models/overnight-send-data";

@injectable()
export class OvernightApiClient extends ApiClientBase {
    public name = "OvernightApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getList(page?: number, limit?: number): Promise<ListResponse<Overnight>> {
        const data: JSON = <JSON><unknown>{
            "page": page,
            "limit": limit
        };
        return await this._callApi('/overnight/list', 'GET', data) as ListResponse<Overnight>;
    }

    public async index(): Promise<any> {
        return await this._callApi('/overnight/data', 'GET') as any;
    }

    public async options(): Promise<any> {
        return await this._callApi('/overnight/options', 'GET', ) as any;
    }

    public async add(overnight: OvernightSendData): Promise<Overnight> {
 
        return await this._callApi('/overnight/add', 'POST', JSON.stringify(overnight)) as Overnight;
    }

    public async delete(id: number): Promise<any> {

        return await this._callApi('/overnight/delete/' + id, 'DELETE', null) as any;
    }
}