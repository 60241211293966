import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { Campus } from "../models/campus"
import { TransferDataDamages } from "../models/transfer-data-damages";
import { DownloadedFile } from "../models/downloaded-file";
import { parse } from "content-disposition-header";

@injectable()
export class DamagesApiClient extends ApiClientBase {
    public name = "DamagesApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getList(page?: number, limit?: number): Promise<ListResponse<Campus>> {
        return await this.list("369A6F2A-2CB7-40CD-B0518A0DB1391173", "dezurnaKnjiga", "XML", "NONE", undefined, page, limit) as ListResponse<Campus>;
    }    

    public async getDezurnaKnjigaList(page?: number, limit?: number): Promise<ListResponse<Campus>> {
        return await this.dezurnaKnjigaList("369A6F2A-2CB7-40CD-B0518A0DB1391173", "dezurnaKnjiga", "XML", "NONE", undefined, page, limit) as ListResponse<Campus>;
    }

    public async getskodniZapisnikList(page?: number, limit?: number): Promise<ListResponse<Campus>> {
        return await this.skodniZapisnikList("369A6F2A-2CB7-40CD-B0518A0DB1391173", "documents", "XML", "NONE", undefined, page, limit) as ListResponse<Campus>;
    }

    public async list(securityCode: string, transferId: string, dataType: string, dataContainer: string, filter?: string, page?:number,maxRows?:number): Promise<ListResponse<Campus>> {
        const data: JSON = <JSON><unknown>{
            "securityCode": securityCode,
            "transferId": transferId,
            "dataType": dataType,
            "dataContainer": dataContainer,
            "filter": filter,
            "page": page,
            "maxRows": maxRows
        };
        return await this._callApi('/damage/list', 'GET', data) as ListResponse<Campus>;
    }

    public async dezurnaKnjigaList(securityCode: string, transferId: string, dataType: string, dataContainer: string, filter?: string, page?: number, maxRows?: number): Promise<ListResponse<Campus>> {
        const data: JSON = <JSON><unknown>{
            "securityCode": securityCode,
            "transferId": transferId,
            "dataType": dataType,
            "dataContainer": dataContainer,
            "filter": filter,
            "page": page,
            "maxRows": maxRows
        };
        return await this._callApi('/damage/dezurnaKnjigaList', 'GET', data) as ListResponse<Campus>;
    }

    public async skodniZapisnikList(securityCode: string, transferId: string, dataType: string, dataContainer: string, filter?: string, page?: number, maxRows?: number): Promise<ListResponse<Campus>> {
        const data: JSON = <JSON><unknown>{
            "securityCode": securityCode,
            "transferId": transferId,
            "dataType": dataType,
            "dataContainer": dataContainer,
            "filter": filter,
            "page": page,
            "maxRows": maxRows
        };
        return await this._callApi('/damage/skodniZapisnikList', 'GET', data) as ListResponse<Campus>;
    }

    public async postData(data: TransferDataDamages): Promise<TransferDataDamages> {
        console.debug("postData", data);
        return await this._callApi('/damage/add', 'POST', JSON.stringify(data)) as TransferDataDamages;
    }

    public async postDataDezurnaKnjiga(data: TransferDataDamages): Promise<TransferDataDamages> {
        console.debug("postData", data);
        return await this._callApi('/damage/addDezurnaKnjiga', 'POST', JSON.stringify(data)) as TransferDataDamages;
    }

    public async postDataString(data: string): Promise<TransferDataDamages> {
        console.debug("postDataString", data);
        return await this._callApi('/damage/add', 'POST', data) as TransferDataDamages;
    }

    public async getOptions(): Promise<any> {
        return await this._callApi('/damage/options', 'GET',) as any;
    }

    public async getDezurnaKnjigaOptions(): Promise<any> {
        return await this._callApi('/damage/options', 'GET',) as any;
    }

    public async download(id: number): Promise<DownloadedFile> {
        var file: DownloadedFile = null as any;
        const data: JSON = <JSON><unknown>{
            "securityCode": "369A6F2A-2CB7-40CD-B0518A0DB1391173",
            "transferId": "dezurnaKnjiga",
            "dataType": "XML",
            "dataContainer": "NONE",
            "filter": `[id] = ${id}`,
            "page": 1,
            "maxRows": 10
        };
        await this._callApi('/damage/download', 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = `Damage_${id}`;
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }


    public async documentDownload(documentId: number): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;

        await this._callApi('/damage/record/' + documentId + "/download", 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });

        return file;
    }
}