import { injectable } from "inversify";
import * as Mustache from "mustache";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { LocationApiClient } from "../ts/clients/location-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./management-lokacije-view.html";

@injectable()
export class ManagementLokacijeView {
    private _apiClient: LocationApiClient;
    private _translationService: TranslationService;
    private itemsPerPage = 25;

    public constructor(locationApiClient: LocationApiClient, translationService: TranslationService) {
        this._apiClient = locationApiClient;
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            //TODO: prebrati podatke
            this._renderData();
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: $("#main").find(".lokacije-table-placeholder"),
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    return await this._apiClient.getList(currentPage, this.itemsPerPage);
                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);


        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }
    private dataTableModel(): TableWidgetTableMap {
        var translations = this._translationService.currentTranslations;
        const tableMap: TableWidgetTableMap = {
            title: translations["Locations"] ?? "Locations",
            noDataContent: translations.NoData,
            classInsert: "lokacije-table-placeholder",
            columns: [
                {
                    name: translations["Id"] ?? "ID",
                    value: "id"
                },
                {
                    name: translations["Name"] ?? "Name",
                    value: "name",
                    class: "w-50"
                },
                {
                    name: translations["ResidentsNumber"] ?? "Residents number",
                    value: "residentsNumber"
                },
                {
                    name: translations["Campus"] ?? "Campus",
                    value: "campus"
                }

            ]
        };
        return tableMap;
    }
}