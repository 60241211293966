import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { StatementResponse } from "../models/statement-response";
import { NotificationResponse } from "../models/notification-response";
import { OvernightResponse } from "../models/overnight-response";
import { SportPackageDesktopResponse } from "../models/sport-package-desktop-response";
import { SportEventDesktopResponse } from "../models/sport-event-desktop-response";
import { ListResponse } from "../models/list-response";
import { Campus } from "../models/campus";
import { AdminContactsModel } from "../models/admin-contacts-model";

@injectable()
export class DesktopApiClient extends ApiClientBase {
    public name = "DesktopApiClient";
    public constructor(

        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getStatmentsData(): Promise<StatementResponse> {

        return await this._callApi('/desktop/getStatmentsData', 'GET') as StatementResponse;
    }

    public async getNotificationData(): Promise<NotificationResponse> {

        return await this._callApi('/desktop/getNotificationaData', 'GET') as NotificationResponse;
    }

    public async getSportActivitiesData(): Promise<SportEventDesktopResponse> {

        return await this._callApi('/desktop/getSportActivitiesData', 'GET') as SportEventDesktopResponse;
    }

    public async getSportPackagesData(): Promise<SportPackageDesktopResponse> {

        return await this._callApi('/desktop/getSportPackagesData', 'GET') as SportPackageDesktopResponse;
    }

    public async getOvernightData(): Promise<OvernightResponse> {

        return await this._callApi('/desktop/getOvernightData', 'GET') as OvernightResponse;
    }

    public async getDamageList(page?: number, limit?: number): Promise<any> {
        return await this.list("369A6F2A-2CB7-40CD-B0518A0DB1391173", "XML", "NONE", undefined, page, limit) as ListResponse<Campus>;
        
    }

    public async getAdminContacts(): Promise<AdminContactsModel> {

        return await this._callApi('/desktop/GetLocationAdminContacts', 'GET') as AdminContactsModel;
    }

    public async list(securityCode: string, dataType: string, dataContainer: string, filter?: string, page?: number, maxRows?: number): Promise<ListResponse<Campus>> {
        const data: JSON = <JSON><unknown>{
            "securityCode": securityCode,            
            "dataType": dataType,
            "dataContainer": dataContainer,
            "filter": filter,
            "page": page,
            "maxRows": maxRows
        };
        return await this._callApi('/desktop/getDamageData', 'GET', data) as ListResponse<Campus>;
    }

    public async showCouponPopup(): Promise<any> {
        return await this._callApi('/coupons/showCouponPopup', 'GET') as any;
    }

    //public async envelope(id: number): Promise<Notification> {
    //    return await this._callApi('/notification/envelope/'+id, 'GET') as Notification;
    //}
}