import { injectable } from "inversify";
import * as Mustache from "mustache";
import { UserApiClient } from "../ts/clients/users-api-client";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./potrditev-emaila-view.html";

@injectable()
export class PotrditevEmailaView {
    private _apiClient: UserApiClient;
    private _translationService: TranslationService;

    public constructor(userApiClient: UserApiClient, translationService: TranslationService,
        private loader: FullscreenLoader
    ) {
        this._apiClient = userApiClient;
        this._translationService = translationService;
    }

    public async load(hash: string): Promise<void> {
        var translations = this._translationService.currentTranslations;

        this._apiClient.validate(hash)
            .then(() => {
                this._renderData(true, translations.EmailIsConfirmed);
                this.loader.hide();
            })
            .catch((ex) => {
                if (ex.statusCode == 400) {
                    var errorMessage = ex.message;
                    this._renderData(false, errorMessage ? errorMessage : translations.UnexpectedError);
                }
                else {
                    this._renderData(false, translations.UnexpectedError);
                }
                this.loader.hide();
            });

    }

    private _renderData(success: boolean, message: string): void {
        // Build a view model from the API data
        const viewModel = {
            message: message,
            success: success,
            "imageUrlLogo": "../img/studentski-dom-ljubljana.png",
            translations: this._translationService.currentTranslations
        } as any;
        $(document.body).removeClass("body-content").addClass("body-login");
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }
}