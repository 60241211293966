import * as DateRangePicker from 'daterangepicker';
import * as moment from 'moment';




export class DateTimePickerWidget {


    public load(): void {
        $('head').append('<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css">');
        this.insertDatePicker();        
    }

    public insertDatePicker(): void {
        require("moment/min/locales.min");
        require('moment/locale/en-gb.js');
        require('moment/locale/sl.js');

        //document.documentElement.setAttribute("lang", 'sl'); //testiranje

        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';

        //za language iz browserja
        //
        //const userLocale =
        //    navigator.languages && navigator.languages.length
        //        ? navigator.languages[0]
        //        : navigator.language;
        
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("LLLL");
        DateRangePicker.name;
        $('input.date-picker').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true, //show leto, mesec dropdown
            timePicker: true,
            timePicker24Hour: true,
            locale: {
                format: localeFormat
            },
            autoApply: true,
            minYear: 1901,
            maxYear: parseInt(moment().format('YYYY'), 10) + 1//dodano zarad registriranja preno?evanj
        });
    }
}

//DatePicker use
//
//call .load() on load
//
//