import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import template from "./selitve-zahtevki-view.html";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
import { TranslationService } from "../ts/translation-service";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import { SelitveApiClient } from "../ts/clients/selitve-api-client";

@injectable()
export class SelitveZahtevkiView {
    private _apiClient: SelitveApiClient;
    private _translationService: TranslationService;

    public constructor(apiClient: SelitveApiClient, translationService: TranslationService, private notyf: Notyf, private loader: FullscreenLoader) {
        this._apiClient = apiClient;
        this._translationService = translationService;

    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            //this.loader.show();
            console.log(this._apiClient);
            console.log(this.loader);
            console.log(this.notyf);

            var translations = this._translationService.currentTranslations;

            // Render new content
            this._renderData();

            var rootElement = $("#main").find(".selitve-zahtevki-table-placeholder");
            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(translations),
                rootElement: rootElement,
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    return await this._apiClient.getRelocateRequests(currentPage, 25);
                }
            });
            await tableWidget.refresh(1, this._translationService.currentTranslations);

        } catch (e) {
            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }


    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlDocument": "../img/icon-document.svg",
            "imageCircleAccept": "../img/icon-circle-accept.svg",
            "imageCircleDecline": "../img/icon-circle-decline.svg",
            "formators": this.getFormators(),
            "imageUrlVerticalDots": "../img/icon-vertical-dots.svg",
            translations: this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);
    }

    private dataTableModel(translations: any): TableWidgetTableMap {
        const tableMap = {
            title: translations["Requests"] ?? "Zahtevki",
            classInsert: "selitve-zahtevki-table-placeholder",
            noDataContent: translations.NoRequests,
            columns: [
                {
                    name: translations["Created"] ?? "Ustvarjeno",
                    html: "{{#formators}}{{#dateTimeFormat}}{{created}}{{/dateTimeFormat}}{{/formators}}"
                },
                {
                    name: translations["CrmId"] ?? "Crm ID",
                    html: `{{ crmId }}`
                },
                {
                    name: translations["FullName"] ?? "Ime",
                    html: `{{ userFullName }}`
                },
                //{
                //    name: translations["RelocateId"] ?? "Id selitve",
                //    html: `{{ relocateId }}`
                //},
                {
                    name: translations["Block"] ?? "Blok",
                    html: `{{ blok }}`
                },
                {
                    name: translations["Room"] ?? "Soba",
                    html: `{{ room }}`
                }
                
            ]
        };
        return tableMap;
    }    

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
            dateTimeFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat + ' HH:mm');
                };
            },
            timeRange: function () {
                return function (timestamp: any, render: any) { //3. April (19:30 - 20:15)
                    var timeString: string[] = render(timestamp).split(" ");
                    var time: Date[] = [];
                    time[0] = new Date(timeString[0]);
                    time[1] = new Date(timeString[1]);
                    if (time[0].getDate() == time[1].getDate() && time[0].getMonth() == time[1].getMonth()) {
                        return moment((time[0])).format('DD. MMM (HH:mm') + " - " + moment((time[1])).format('HH:mm)')
                    } else {
                        return moment((time[0])).format('DD. MMM (HH:mm)') + " - " + moment((time[1])).format('DD. MMM (HH:mm)')
                    }
                };
            }
        };
    }
}