import * as Mustache from "mustache";



export class SmallNodeWidget {

    public insertTitleNode(nodeMap: any): string {
        if (nodeMap.tileTitleStyle == "strong") { nodeMap.tileTitle = `<strong>${nodeMap.tileTitle}</strong>` }

        const htmlWrapper =
            `
                    {{#nodeMap}}
                        <div class="col-12 col-xxl-6">
                            <div class="tile-wrap">
                                <div class="tile-header">
                                    <div class="title {{titleColor}} {{mainTitleStyle}}">
                                        {{title}}
                                    </div>
                                    <div class="controls icon-only">
                                        {{#controls}}
                                        <a href="{{controlsUrl}}" class="{{name}}" > {{value}} </a>
                                        {{/controls}}
                                    </div>
                                </div> 
                                <div href="{{goToLink}}" class="tile clickable {{tileColor}}">
                                    <h3 class="{{tileTitleColor}} {{titleWeight}}">{{{tileTitle}}}</h3>
                                        <div class="row">
                                        {{#rows}}
                                            <div class="col-12 col-sm-3">
                                                <div class="label {{nameStyle}}">{{name}}</div>
                                            </div>
                                        <div class="col-12 col-sm-9">                                   
                                            <%#data%>
                                                <div class="data {{titleWeight}} {{valueStyle}}"><%{{value}}%></div>
                                            <%/data%>                         
                                        </div>
                                        {{/rows}}
                                        </div>
                                    </div>
                            </div></div>     
                    {{/nodeMap}}
                    <div hidden class="{{nodeList}}"></div>   
             `;
        const viewModel = {
            "nodeMap": nodeMap,
            "nodeList": nodeMap.class + "-list"
        } as any;
        var htmlRender = Mustache.render(htmlWrapper, viewModel);
        var customTags: any = ['<%', '%>'];
        htmlRender = Mustache.render(htmlRender, { "data": nodeMap.data }, undefined, customTags);
        $(`.${nodeMap.class}`).replaceWith(htmlRender);
        return nodeMap.class + '-list'
    }

    public insertNode(Appendclass: any, nodeTag: any): void {
        const htmlWrapper =
            `
                    {{#NodeTag}}       
                        <div class="col-12 col-xxl-6">
                            <div class="tile-wrap">
                                <div class="tile-header">
                                    <div class="title"></div>                                    
                                </div>
                                <div href="{{goToLink}}" class="tile clickable {{tileColor}}">
                                    <h3 class="{{tileTitleColor}}" style="font-weight:{{titleWeight}}">{{{tileTitle}}}</h3>
                                        <div class="row">
                                        {{#rows}}
                                            <div class="col-12 col-sm-3">
                                                <div class="label {{nameStyle}}">{{name}}</div>
                                            </div>
                                        <div class="col-12 col-sm-9">                                   
                                            <%#data%>
                                                <div class="data {{titleWeight}} {{valueStyle}}"><%{{value}}%></div>
                                            <%/data%>                         
                                        </div>
                                        {{/rows}}
                                        </div>
                                    </div> 
                                </div>
                            </div>
                    <div hidden class="{{nodeList}}"></div>  
                    {{/NodeTag}}
             `;
        const viewModel = {
            "NodeTag": nodeTag,
            "nodeList": Appendclass
        } as any;
        var htmlRender = Mustache.render(htmlWrapper, viewModel);
        var customTags: any = ['<%', '%>'];
        htmlRender = Mustache.render(htmlRender, { "data": nodeTag.data }, undefined, customTags);
        $(`.${Appendclass}`).replaceWith(htmlRender);

    }

    public insertTitleNodeWithText(nodeMap: any): string {
        if (nodeMap.tileTitleStyle == "strong") { nodeMap.tileTitle = `<strong>${nodeMap.tileTitle}</strong>` }

        const htmlWrapper =
            `
                    {{#nodeMap}}
                        <div class="col-12 col-xxl-6">
                            <div class="tile-wrap">
                                <div class="tile-header">
                                    <div class="title {{titleColor}} {{mainTitleStyle}}">
                                        {{title}}
                                    </div>  
                                    <div class="controls icon-only">
                                        {{#controls}}
                                        <a href="{{controlsUrl}}" class="{{name}}" > {{value}} </a>
                                        {{/controls}}
                                    </div>
                                </div> 
                               
                                <div href="{{goToLink}}" class="tile clickable {{tileColor}}">
                                
                                    <h3 class="{{tileTitleColor}} {{titleWeight}}">{{{tileTitle}}}</h3>
                                        <div class="row mb-4">
                                        {{#rows}}
                                            <div>
                                                <div class="label {{nameStyle}}">{{name}}</div>
                                            </div>                                        
                                        {{/rows}}
                                        {{#button}}
                                           <div class="{{buttonDivStyle}}">
                                                <a href="{{href}}" style="width:158px"  class="{{btnStyle}}">{{text}}</a>
                                           </div>
                                        
                                        {{/button}}
                                        </div>
                            
                                    </div>
                        
                            </div>
                            </div>     
                    {{/nodeMap}}
                    <div hidden class="{{nodeList}}"></div>   
             `;
        const viewModel = {
            "nodeMap": nodeMap,
            "nodeList": nodeMap.class + "-list"
        } as any;
        var htmlRender = Mustache.render(htmlWrapper, viewModel);
        var customTags: any = ['<%', '%>'];
        htmlRender = Mustache.render(htmlRender, { "data": nodeMap.data }, undefined, customTags);
        $(`.${nodeMap.class}`).replaceWith(htmlRender);
        return nodeMap.class + '-list'
    }
    
    
    public insertInvoiceNode(nodeMap: any): string {
        if (nodeMap.tileTitleStyle == "strong") { nodeMap.tileTitle = `<strong>${nodeMap.tileTitle}</strong>` }

        const htmlWrapper =
            `
                    {{#nodeMap}}
                        <div class="col-12 col-xxl-6">
                            <div class="tile-wrap">
                                <div class="tile-header">
                                    <div class="title {{titleColor}} {{mainTitleStyle}}">
                                        {{title}}
                                    </div>
                                    <div class="controls icon-only">
                                        {{#controls}}
                                        <a href="{{controlsUrl}}" class="{{name}}" > {{value}} </a>
                                        {{/controls}}
                                    </div>
                                </div> 
                                <div class="tile  {{tileColor}}">
                                    <h3 class="{{tileTitleColor}} {{titleWeight}}">{{{tileTitle}}}</h3>
                                        <div class="row">
                                        {{#rows}}
                                            <div class="col-12 col-sm-3">
                                                <div class="label {{nameStyle}}">{{name}}</div>
                                            </div>
                                        <div class="col-12 col-sm-9">                                   
                                            <%#data%>
                                                 {{#isLink}}
                                                  <a href="#" id="invoice" download="<%{{fileName}}%>" data-documentid="<%{{id}}%>" class="{{name}}"><%{{value}}%></a>
                                                 {{/isLink}}
                                                {{^isLink}}
                                                    <div class="data {{titleWeight}} {{valueStyle}}"><%{{value}}%></div>
                                                {{/isLink}}
                                            <%/data%>                         
                                        </div>
                                        {{/rows}}
                                        </div>
                                    </div>
                            </div></div>     
                    {{/nodeMap}}
                    <div hidden class="{{nodeList}}"></div>   
             `;
        const viewModel = {
            "nodeMap": nodeMap,
            "nodeList": nodeMap.class + "-list"
        } as any;
        var htmlRender = Mustache.render(htmlWrapper, viewModel);
        var customTags: any = ['<%', '%>'];
        htmlRender = Mustache.render(htmlRender, { "data": nodeMap.data }, undefined, customTags);
        $(`.${nodeMap.class}`).replaceWith(htmlRender);
        return nodeMap.class + '-list'
    }


}


