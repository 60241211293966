import { injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./internet-dnevnik-prijav-view.html";
import { AccountApiClient } from "../../src/ts/clients/account-api-client";
import { TableWidget, TableWidgetTableMap } from "../../../skupno/src/ts/widgets/table-widget";
import * as DateRangePicker from 'daterangepicker';
import * as moment from 'moment';
import InputMask from 'inputmask';
import { TranslationService } from "../ts/translation-service";
import { UrlHelper } from "../../../skupno/src/ts/utils/url-helper";
//import * as daterangepicker from "daterangepicker";


@injectable()
export class InternetDnevnikPrijavView {
    private _accoutApiClient: AccountApiClient;
    private _translationService: TranslationService;
    private _tableWidget: TableWidget = null!;

    private _itemsPerPage = 30;

    private _uporabnikSearchElement: JQuery<HTMLElement> = null!;
    private _macSearchElement: JQuery<HTMLElement> = null!;
    private _ipSearchElement: JQuery<HTMLElement> = null!;
    private _casZacetkaSearchElement: JQuery<HTMLElement> = null!;
    private _btnSearchElement: JQuery<HTMLElement> = null!;

    private _casZacetkaDateRangePicker: DateRangePicker = null!;

    public constructor(accoutApiClient: AccountApiClient, translationService: TranslationService) {
        this._accoutApiClient = accoutApiClient;
        this._translationService = translationService;
    }
    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {

        try {
            // Try to get data
            //TODO: prebrati podatke

            this._renderData();
            // Init search
            this._initSearch();

            var tableWidget = new TableWidget({
                tableMap: this.dataTableModel(),
                rootElement: $("#main").find(".internet-dnevnik-prijav-table-placeholder"),
                getData: async (currentPage) => {
                    if (currentPage == 1) {
                        var page = UrlHelper.getPageFromUrl();
                        if (page != 1) {
                            currentPage = page;
                        }
                    }
                    return await this._accoutApiClient.getList(currentPage, this._itemsPerPage,
                        this._uporabnikSearchElement.val() as string || undefined,
                        this._macSearchElement.val() as string || undefined,
                        this._ipSearchElement.val() as string || undefined,
                        this._casZacetkaDateRangePicker.startDate.isValid() ? this._casZacetkaDateRangePicker.startDate.format() : undefined,
                        this._casZacetkaDateRangePicker.endDate.isValid() ? this._casZacetkaDateRangePicker.endDate.format() : undefined
                    )

                }
            });
            tableWidget.refresh(1, this._translationService.currentTranslations);
            this._tableWidget = tableWidget;

        } catch (e) {

            // Clear previous content on error
            $('#main').text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {

        // Build a view model from the API data
        const viewModel = {
            "imageUrlLogout": "../img/icon-logout.svg",
            "imageUrlSoftwareAndroid": "../img/software-android.svg",
            "imageUrlSoftwareLinux": "../img/software-linux.svg",
            "imageUrlSoftwareOSX": "../img/software-osx.svg",
            "imageUrlSoftwareWindows": "../img/software-windows.svg",
            "imageUrlSoftwareIos": "../img/software-ios.svg",
            "imageUrlGreenDot": "../img/icon-green-dot.svg",
            "imageUrlEdit": "../img/icon-edit.svg",
            "imageUrlArrowDown": "../img/icon-arrow-down.svg",
            "imageUrlIconInfo": "../img/icon-info.svg",
            translations: this._translationService.currentTranslations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#main').html(html);

        
        
    }

    private _initSearch(): void {
        //var th = this;
        this._uporabnikSearchElement = $('#main').find("#uporabnikPrijaveSearchInput");
        this._macSearchElement = $('#main').find("#macPrijaveSearchInput");
        this._ipSearchElement = $('#main').find("#ipPrijaveSearchInput");
        this._casZacetkaSearchElement = $('#main').find("#casZacetkaPrijaveSearchInput");
        this._btnSearchElement = $('#main').find("#btnSearchPrijave");

        let _macSearchElementInputMask = new InputMask({
            mask: "a|9a|9.a|9a|9.a|9a|9.a|9a|9.a|9a|9.a|9a|9"
        });
        _macSearchElementInputMask.mask(this._macSearchElement);

        let _ipSearchElementInputMask = new InputMask({
            mask: "9{1,3}.9{1,3}.9{1,3}.9{1,3}"
        });
        _ipSearchElementInputMask.mask(this._ipSearchElement);
        
        this._casZacetkaDateRangePicker = new DateRangePicker(this._casZacetkaSearchElement[0], {
            showDropdowns: false,
            timePicker: true,
            timePicker24Hour: true,
            startDate: moment().add(-2, 'days'),
            endDate: moment().add(1, 'days'),
            maxDate: moment().add(1, 'days')
        });

        this._btnSearchElement.on("click", (ev: Event) => this._onSearch(ev));
        this._uporabnikSearchElement.on("keyup", (ev: Event) => this._onKeyPress(ev));
        this._macSearchElement.on("keyup", (ev: Event) => this._onKeyPress(ev));
        this._ipSearchElement.on("keyup", (ev: Event) => this._onKeyPress(ev));        
    }
    private _onKeyPress(ev: Event) {
        let event: KeyboardEvent = ev as KeyboardEvent;
        if (event.key == "Enter") {
            this._onSearch(ev as Event);
        }
    }

    private async _onSearch(ev: Event) {
        ev.preventDefault();
        await this._tableWidget.refresh(1, this._translationService.currentTranslations);
    }


    private dataTableModel(): TableWidgetTableMap { 
        var translations = this._translationService.currentTranslations;
        return {
            title: translations.LoginLog,
            classInsert: "internet-dnevnik-prijav-table-placeholder",
            noDataContent: translations.NoData,
            columns: [
                {
                    name: translations["Username"] ?? "Username",
                    value: "userName"
                },
                {
                    name: translations.Location,
                    value: "location"
                },
                {
                    name: translations.Nas,
                    value: "nasShortName"
                },
                {
                    name: translations.ValidStart,
                    value: "startTime"
                },
                {
                    name: translations.End,
                    value: "endTime"
                },
                {
                    name: translations.Time,
                    value: "sessionTime"
                },
                {
                    name: translations.Device,
                    value: "device"
                },
                {
                    name: translations.EndCause,
                    value: "endCause"
                },
                {
                    name: translations.Ip,
                    value: "ip"
                },
                {
                    name: translations["Status"] ?? "Status",
                    html: `
{{#status}}
        <div class="status aktivno"></div>
{{/status}}
`
                }
            ]
        }
    }

}